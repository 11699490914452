import JsPDF from "jspdf";
async function drawGrid(doc) {
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const gridSpacing = 20; // Spacing between grid lines in points

  // Draw vertical grid lines and label X-coordinates
  for (let x = 0; x <= pageWidth; x += gridSpacing) {
    doc.setDrawColor(200, 200, 200); // Light gray for grid lines
    if (x === 0) {
      // Highlight Y-axis
      doc.setDrawColor(0, 0, 255); // Blue
      doc.setLineWidth(0.8); // Thicker line for axis
    } else {
      doc.setLineWidth(0.2); // Regular line width
    }
    doc.line(x, 0, x, pageHeight); // Draw vertical line

    // Label X-coordinates
    doc.setFontSize(12);
    if (x > 0) {
      doc.text(String(x), x + 2, 10); // Place numbers near the top
    }
  }

  // Draw horizontal grid lines and label Y-coordinates
  for (let y = 0; y <= pageHeight; y += gridSpacing) {
    doc.setDrawColor(200, 200, 200); // Light gray for grid lines
    if (y === 0) {
      // Highlight X-axis
      doc.setDrawColor(255, 0, 0); // Red
      doc.setLineWidth(0.8); // Thicker line for axis
    } else {
      doc.setLineWidth(0.2); // Regular line width
    }
    doc.line(0, y, pageWidth, y); // Draw horizontal line

    // Label Y-coordinates
    doc.setFontSize(12);
    if (y > 0) {
      doc.text(String(y), 2, y - 2); // Place numbers near the left side
    }
  }
}
function hexToRgb(hex) {
  const bigint = parseInt(hex.slice(1), 16); // Remove "#" and parse the hex string
  return {
    r: (bigint >> 16) & 255, // Extract red
    g: (bigint >> 8) & 255, // Extract green
    b: bigint & 255, // Extract blue
  };
}

function drawOneCertificate(doc, studentData, configPrint, preview) {
  try {
    let imageUrl = studentData.certifImgUrl;
    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();
    doc.addImage(imageUrl, "PNG", 0, 0, width, height);
    if (preview) drawGrid(doc);
    const fontValue = configPrint.font;
    const [fontName, fontStyle] = fontValue.includes("-")
      ? fontValue.split("-")
      : [fontValue, "normal"];

    let fontSize = 23;
    doc.setFont(fontName, fontStyle);
    doc.setFontSize(fontSize);
    let { r, g, b } = hexToRgb(configPrint.fullName.color);

    doc.setTextColor(r, g, b);
    let text = studentData.fullName;
    doc.setFontSize(configPrint.fullName.fontSize);
    let xPositionName = configPrint.fullName.x;

    if (configPrint.xAlign == "center") {
      let fullNameWidth = doc.getTextWidth(text);

      xPositionName = configPrint.fullName.x - fullNameWidth / 2;
    }
    doc.text(text, xPositionName, configPrint.fullName.y, {
      rtl: true,
    });

    doc.setFontSize(configPrint.className.fontSize);
    ({ r, g, b } = hexToRgb(configPrint.className.color));
    doc.setTextColor(r, g, b);
    let xPositionClass = configPrint.className.x;

    if (configPrint.xAlign == "center") {
      let classroomNameWidth = doc.getTextWidth(studentData.classroomName);
      xPositionClass = configPrint.className.x - classroomNameWidth / 2;
    }

    doc.text(
      studentData.classroomName,
      xPositionClass,
      configPrint.className.y,
      {
        rtl: true,
      }
    );
  } catch (error) {
    console.log(error);
  }
}

//used in paramétrage for preview
const drawCertifcate = async (
  studentData,
  configPrint,
  certifImgUrl,
  preview = false
) => {
  try {
    const doc = new JsPDF({
      orientation: configPrint.orientation,
      unit: "mm",
      format: "a4",
    });
    studentData.certifImgUrl = certifImgUrl;
    await drawOneCertificate(doc, studentData, configPrint, preview);
    return doc;
    //end stamp
  } catch (error) {
    console.log(error);
  }
};

const drawAllCertificate = async (students) => {
  try {
    const doc = new JsPDF({
      orientation: students[0].certificate.configPrint.orientation,
      unit: "mm",
      format: "a4",
    });
    students.map((student, i) => {
      if (i !== 0) doc.addPage();
      drawOneCertificate(doc, student, student.certificate.configPrint);
    });
    return doc;
  } catch (error) {
    console.error(error);
  }
};
export { drawCertifcate, drawAllCertificate };
