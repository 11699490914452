<template>
  <div>
    <a-button v-if="selectedTeacher" type="primary" @click="showCloneModal"
      ><a-icon type="copy" />Dupliquer</a-button
    >
    <!-- START ACCESS SCHED DRAWER -->
    <a-modal
      v-model="visibleDuplicateModal"
      @cancel="cloneModel = false"
      title="Dupliquer l'acceés pour l'enseignant"
      :footer="false"
      :visible="visibleDuplicateModal"
      @close="() => (visibleDuplicateModal = false)"
      width="30%"
    >
      <div>
        <div class="">
          <label>{{ this.$t("actualite.trimester_one") }}</label>
        </div>
        <div class="">
          <a-select
            size="large"
            :placeholder="$t('paiement.selectionnerMois')"
            style="width: 100%; height: 100%"
            class="pb-2 p-2"
            @change="
              (val) => {
                this.trimesterSource = val;
              }
            "
            default-value="1"
          >
            <a-select-option
              v-for="trimester in ['1', '2', '3']"
              :key="trimester"
              :value="trimester"
            >
              {{ trimester }}
            </a-select-option>
          </a-select>
        </div>
      </div>

      <div>
        <div class="">
          <label>{{ this.$t("actualite.trimester_two") }}</label>
        </div>
        <div class="">
          <a-select
            size="large"
            :placeholder="$t('paiement.selectionnerMois')"
            style="width: 100%; height: 100%"
            class="pb-2 p-2"
            @change="
              (val) => {
                this.trimesterDestination = val;
              }
            "
            default-value="2"
          >
            <a-select-option
              v-for="trimester in ['1', '2', '3']"
              :key="trimester"
              :value="trimester"
            >
              {{ trimester }}
            </a-select-option>
          </a-select>
        </div>
      </div>

      <div class="form-actions mt-0">
        <a-button
          type="primary"
          :loading="loading"
          class="mr-3"
          @click="cloneModelByTrimester()"
        >
          {{ $t("action.clone") }}
        </a-button>
        <a-button
          :style="{ marginRight: '8px' }"
          @click="() => (visibleDuplicateModal = false)"
        >
          {{ $t("action.annuler") }}
        </a-button>
      </div>
    </a-modal>
    <!-- END ACCESS SCHED DRAWER -->
  </div>
</template>
<script>
import apiClient from "@/services/axios";
export default {
  props: {
    selectedTeacher: {
      required: true,
    },
    selectedTrimester: {
      required: true,
    },
    listSubjects: {
      required: true,
    },
  },
  data() {
    return {
      visibleDuplicateModal: false,
      loadingAddBtn: false,
      form: this.$form.createForm(this),
      filtredClasses: [],
      data: [],
      loading: false,
      trimesterSource: "1",
      trimesterDestination: "2",
    };
  },
  mounted() {},
  methods: {
    async showCloneModal() {
      if (!this.selectedTeacher) {
        this.$message.error(this.$t("emploi.choisirEnseignant"));
        return;
      }
      this.visibleDuplicateModal = true;
    },
    async cloneModelByTrimester() {
      this.loading = true;
      await apiClient
        .put(
          "/teacherAccess/clone/" +
            this.trimesterSource +
            "/" +
            this.trimesterDestination +
            "/" +
            this.selectedTeacher +
            ""
        )
        .then(({ data }) => {
          this.$message.success("Niveau d'acceés ajouté avec succeés");
          this.$emit("reload");
          this.visibleDuplicateModal = false;
        })
        .catch((err) => {
          this.$message.error("Niveau d'acceés non ajouté");

          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style>
[data-kit-theme="default"] .stat-card .ant-card-body {
  padding: 10px !important;
  background: #eee;
  border-radius: 2px;
}
</style>
