<template>
  <div>
    <div style="margin: 15px">
      <h4>{{ $t("bulletin.moyennesModules") }}</h4>
    </div>

    <!--begin::Chart-->
    <apexchart
      class="statistics-widget-3-chart card-rounded-bottom"
      :options="options"
      :series="series"
      :height="300"
      type="line"
    ></apexchart>
    <!--end::Chart-->
  </div>
</template>

<script>
export default {
  props: ["bulletin"],
  data() {
    return {
      options: {},
      series: [],
      labelColor: "#3F4254",
      colors: ["#0095E8", "#67c23a", "#D9214E", "#feb019"],
      lightColors: ["#FFF5F8", "#e8fff3", "#F1FAFF"],
    };
  },

  watch: {
    bulletin: function (value) {
      this.updateChart();
    },
  },

  created() {
    if (this.bulletin) this.updateChart();
  },

  methods: {
    updateChart() {
      const modulesSerie = [];
      const data = [];
      const dataMax = [];
      const dataMin = [];
      if (this.bulletin && this.bulletin.modules) {
        for (const m of this.bulletin.modules) {
          modulesSerie.push(m.name);
          dataMax.push(Number(m.maxMoyenne));
          dataMin.push(Number(m.minMoyenne));
          data.push(m.moyenne);
        }

        this.updateSeries(data, dataMax, dataMin);
        this.updateOptions(modulesSerie);
      }
    },

    updateSeries(data, dataMax, dataMin) {
      this.series = [
        {
          name: this.$t("bulletin.maximaleMoyenne"),
          type: "line",
          data: dataMax,
          color: "#8bc34a",
        },
        {
          name: this.$t("bulletin.moyenne"),
          type: "bar",
          data,
        },
        {
          name: this.$t("bulletin.minimaleMoyenne"),
          type: "line",
          data: dataMin,
          color: "#FF4560",
        },
      ];
    },

    updateOptions(modulesSerie) {
      this.options = {
        chart: {
          height: 350,
          type: "line",
          toolbar: {
            show: false,
          },
        },
        theme: {
          monochrome: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: modulesSerie,
          labels: {
            style: {
              fontSize: "13px",
              // colors: this.colors,
            },
          },
        },
        yaxis: {
          min: 0,
          max: 20,
          labels: {
            formatter: function (val) {
              return val % 1 == 0 ? val.toFixed(0) : val.toFixed(2);
            },
          },
        },
      };
    },
  },
};
</script>
