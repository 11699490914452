<template>
  <div>
    <a-form :form="form" class="card-header p-5" @submit="addActivity">
      <div class="row">
        <div class="col-md-6">
          <a-form-item>
            <a-select
              v-decorator="[
                'activity',
                {
                  rules: [
                    {
                      required: true,
                      message: $t('paiement.activiteRequis'),
                    },
                  ],
                },
              ]"
              show-search
              :placeholder="$t('paiement.listeActivites')"
              style="width: 100%"
              option-filter-prop="children"
              :filter-option="filterOption"
              @change="(v) => selectActivity(v)"
            >
              <a-select-option
                v-for="activtiy in activities"
                :key="activtiy._id"
                :value="activtiy._id"
              >
                {{ activtiy.designation }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
        <div class="col-md-12">
          <a-form-item>
            <a-radio-group
              v-model="selectionMode"
              class="mb-3"
              @change="handleSelectionModeChange"
            >
              <a-radio value="students">{{ $t("accueil.eleves") }}</a-radio>
              <a-radio value="classes">{{ $t("accueil.classes") }}</a-radio>
              <a-radio value="levels">{{ $t("actualite.levels") }}</a-radio>
            </a-radio-group>

            <a-select
              v-if="selectionMode === 'students'"
              v-decorator="[
                'students',
                {
                  rules: [
                    {
                      required: true,
                      message: $t('paiement.eleveRequis'),
                    },
                  ],
                },
              ]"
              show-search
              :allowClear="true"
              mode="multiple"
              :placeholder="$t('menu.listeEleves')"
              style="width: 100%"
              option-filter-prop="children"
              :filter-option="filterOption"
            >
              <a-select-option
                v-for="student in studentList"
                :key="student._id"
                :value="student._id"
              >
                {{ student.firstName }} {{ student.lastName }}
              </a-select-option>
            </a-select>

            <a-select
              v-else-if="selectionMode === 'classes'"
              v-decorator="[
                'classes',
                {
                  rules: [
                    {
                      required: true,
                      message: $t('paiement.classRequis'),
                    },
                  ],
                },
              ]"
              show-search
              :allowClear="true"
              mode="multiple"
              :placeholder="$t('sms.listeClasses')"
              style="width: 100%"
              option-filter-prop="children"
              :filter-option="filterOption"
            >
              <a-select-option
                v-for="(students, classId) in studentsData"
                :key="classId"
                :value="classId"
              >
                {{ getClassName(classId) }}
              </a-select-option>
            </a-select>
            <a-select
              v-if="selectionMode === 'levels'"
              v-decorator="[
                'levels',
                {
                  rules: [
                    { required: true, message: $t('enseignant.niveauxRequis') },
                  ],
                },
              ]"
              show-search
              :allowClear="true"
              mode="multiple"
              :placeholder="$t('actualite.levels')"
              style="width: 100%"
              option-filter-prop="children"
              :filter-option="filterOption"
              @change="handleLevelChange"
            >
              <a-select-option
                v-for="(label, level) in levelSelectData[schoolType]"
                :key="level"
                :value="level"
              >
                {{ label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <a-form-item>
            <a-date-picker
              :allowClear="false"
              :disabled-date="disabledDate"
              @change="
                (start) => {
                  currentFeesRange.start = start;
                  setIncludedFees();
                }
              "
              v-decorator="[
                'start',
                {
                  defaultValue: moment(Date.now()),
                  rules: [
                    {
                      required: true,
                      message: $t('paiement.dateDebutRequis'),
                    },
                  ],
                },
              ]"
              :format="dateFormat"
              style="width: 100%"
            />
          </a-form-item>
        </div>
        <div class="col-md-6">
          <a-form-item>
            <a-date-picker
              @change="
                (end) => {
                  currentFeesRange.end = end;
                  setIncludedFees();
                }
              "
              :disabled-date="disabledEndDate"
              v-decorator="[
                'end',
                {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                },
              ]"
              :format="dateFormat"
              style="width: 100%"
            />
          </a-form-item>
        </div>
      </div>
      <div class="row" v-if="activityInfos._id">
        <a-divider>Selectionner le mode de paimenet </a-divider>

        <div class="col-lg-3">
          <h6 for="">{{ $t("inscription.fraisInscri") + " (dt)" }}</h6>
          <p>{{ activityInfos.inscription }}</p>
        </div>
        <div
          @click="changeModeP('M')"
          :class="[
            'col-lg-4',
            'mr-2',
            'pt-2',
            modePaiement === 'M' ? 'custom-border-active' : 'custom-border',
          ]"
        >
          <h6 for="">{{ $t("inscription.fraisMensuel") + " (dt)" }}</h6>
          <p>{{ activityInfos.monthly }}</p>
        </div>
        <div
          @click="changeModeP('T')"
          :class="[
            'col-lg-4',
            'pt-2',
            modePaiement === 'T' ? 'custom-border-active' : 'custom-border',
          ]"
          v-if="activityInfos.tranches"
        >
          <h6 for="">Frais des tranches (dt)</h6>
          <li v-for="t in activityInfos.tranches" :key="t.trancheNumber">
            {{ "Tranche " + t.trancheNumber + " : " + t.amount }}
          </li>
        </div>
        <a-alert
          width="100%"
          class="mt-2"
          :message="
            'Le mode de paiment selectionné est ' +
            (modePaiement === 'T' ? 'par tranches' : 'mensuelle')
          "
          type="info"
          show-icon
        />
        <a-divider>Les frais à payer</a-divider>
      </div>

      <div class="row" v-if="activityInfos._id">
        <div class="col-lg-9 mb-2">
          <div v-for="(value, key, index) in includedFees" :key="key">
            <div class="row mt-1">
              <div class="col-6">
                <a-form-item
                  :class="index == 0 ? 'mt-0 mb-0' : 'm-0'"
                  :label="index == 0 ? 'Frais' : ''"
                >
                  <a-tag style="width: 100%; font-size: 15px" class="mt-1"
                    >{{ formatFeeName(key) }}
                  </a-tag>
                </a-form-item>
              </div>
              <div class="col-6">
                <a-form-item
                  :class="index == 0 ? 'mt-0 mb-0' : 'm-0'"
                  :label="index == 0 ? `Inclus` : ''"
                >
                  <a-switch
                    :checked="includedFees[key]"
                    :key="key"
                    @change="
                      (e) => {
                        changeIncludedFees(key, e);
                      }
                    "
                  >
                    <a-icon slot="checkedChildren" type="check" />
                    <a-icon slot="unCheckedChildren" type="close" />
                  </a-switch>
                </a-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mt-5">
          <a-button
            type="primary"
            style="width: 100%"
            htmlType="submit"
            :loading="loadingClasse"
            :disabled="loadingClasse"
            icon="tags"
            :ghost="false"
          >
            {{ $t("action.ajouter") }}
          </a-button>
        </div>
      </div>
    </a-form>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import moment from "moment";
import { mapState } from "vuex";
import store from "store";
import { formatFeeName, dateRange } from "@/utilities/settings";

const actvitiyPaymentsFormater = (activityFees, studentId, studentList) => {
  const getClassRoom = (studentId, studentList) => {
    try {
      return studentList.filter((s) => s._id == studentId)[0].classRoomName;
    } catch (error) {
      return " ";
    }
  };
  let status = "impayed";
  if (activityFees.inscription) {
    if (!activityFees.activity.once && activityFees.months) {
      status = "payed";
      for (const [key, value] of Object.entries(activityFees.months)) {
        if (!value) {
          status = "impayed";
          break;
        }
      }
    }
  }
  return {
    status,
    inscription: activityFees.inscription,
    months: activityFees.months,
    _id: activityFees._id,
    fullName:
      activityFees.student.firstName + " " + activityFees.student.lastName,
    activity: activityFees.activity.designation,
    activityDetails: activityFees.activity,
    class: getClassRoom(studentId, studentList),
  };
};

const monthly = (start, end) => {
  let monthObj = Object();
  dateRange(start, end).forEach((date) => (monthObj[date] = false));
  return monthObj;
};
export default {
  computed: {
    ...mapState(["settings", "user"]),
  },

  async created() {
    this.schoolType = this.user.building.find(
      (el) => el.dbName == this.settings.activeBuilding
    ).type;
    const building = this.user.building.find(
      (el) => el.dbName == this.settings.activeBuilding
    );
    if (building) this.reductionType = building.reductionType || "pourcentage";

    this.form = this.$form.createForm(this, { name: "addActivityForm" });
    apiClient
      .post("/activities/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            designation: 1,
            start: 1,
            end: 1,
            inscription: 1,
            monthly: 1,
            once: 1,
            tranches: 1,
          },
        },
      })
      .then((res) => (this.activities = res.data))
      .catch((e) => this.$message.error(this.$t("error.aucActivite")));
    apiClient
      .post("/students/filter", {
        query: {
          status: "active",
        },
        aggregation: [
          {
            $set: {
              classRoom: {
                $convert: {
                  input: `$schoolarYearsHistory.${this.settings.activeSchoolarYear}`,
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $set: {
              classRoomName: "$classRoom",
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoom",
              foreignField: "_id",
              as: "classRoomName",
            },
          },
          {
            $project: {
              _id: 1,
              classRoom: 1,
              firstName: 1,
              lastName: 1,
              classRoomName: { $arrayElemAt: ["$classRoomName.name", 0] },
            },
          },
        ],
      })
      .then((res) => {
        const { data } = res;
        this.studentList = data;
        this.studentsData = _.mapValues(_.groupBy(data, "classRoom"), (clist) =>
          clist.map((elem) => _.omit(elem, "classRoom"))
        );
      })
      .catch((e) => this.$message.error(this.$t("error.aucEleve")));
    await this.getActivitiesPayments();
    await apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
      })
      .then((res) => {
        this.classes = res.data;

        this.classroomData = res.data.reduce((acc, classroom) => {
          acc[classroom._id] = classroom;
          return acc;
        }, {});
      })
      .catch((e) => this.$message.error(this.$t("error.aucClasse")));
    await this.fetchActivityPayments();
  },

  data() {
    return {
      selectionMode: "students",
      reductionType: "dinar",
      currentFeesRange: {},
      includedFees: {},
      loadingClasse: false,
      dateFormat: "DD-MM-YYYY",
      studentsData: [],
      studentList: [],
      classList: [],
      activities: [],
      actvitiyFees: [],
      actvitiyPayments: [],
      activitySelected: {
        start: moment(Date.now()),
        end: null,
      },
      activityInfos: {},
      modePaiement: "M",
      studentsData: [],
      studentList: [],
      schoolType: "",
      levelSelectData: {
        jardinEnfant: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
        },
        ecole: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
          1: this.$t("niveau.niveau1"),
          2: this.$t("niveau.niveau2"),
          3: this.$t("niveau.niveau3"),
          4: this.$t("niveau.niveau4"),
          5: this.$t("niveau.niveau5"),
          6: this.$t("niveau.niveau6"),
        },
        college: {
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
        },
        lycee: {
          10: this.$t("niveau.niveau10"),
          11: this.$t("niveau.niveau11"),
          12: this.$t("niveau.niveau12"),
          13: this.$t("niveau.niveau13"),
        },
        collegeEtLycee: {
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
          10: this.$t("niveau.niveau10"),
          11: this.$t("niveau.niveau11"),
          12: this.$t("niveau.niveau12"),
          13: this.$t("niveau.niveau13"),
        },
        primaireEtCollege: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
          1: this.$t("niveau.niveau1"),
          2: this.$t("niveau.niveau2"),
          3: this.$t("niveau.niveau3"),
          4: this.$t("niveau.niveau4"),
          5: this.$t("niveau.niveau5"),
          6: this.$t("niveau.niveau6"),
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
        },
      },
      classroomData: {},
    };
  },

  methods: {
    formatFeeName,
    changeIncludedFees(key, e) {
      this.includedFees[key] = e;
      this.$forceUpdate(); // Force re-render
    },
    changeModeP(v) {
      this.modePaiement = v;
      this.setIncludedFees();
    },
    moment,
    disabledDate(current) {
      if (!this.activitySelected.end) {
        const schoolarYears = this.settings.schoolarYears;
        const activeSchoolarYear = store.get("app.settings.activeSchoolarYear");
        const schoolarYear = schoolarYears.filter(
          (sc) => sc._id == activeSchoolarYear
        )[0];
        this.activitySelected.end = moment(schoolarYear.end);
      }
      if (!this.activitySelected.start) {
        const schoolarYears = this.settings.schoolarYears;
        const activeSchoolarYear = store.get("app.settings.activeSchoolarYear");
        const schoolarYear = schoolarYears.filter(
          (sc) => sc._id == activeSchoolarYear
        )[0];
        this.activitySelected.start = moment(schoolarYear.start);
      }
      const { start, end } = this.activitySelected;
      return (current && current.isAfter(end)) || current.isBefore(start);
      //return current && current < moment().endOf("day");
    },
    disabledEndDate(current) {
      if (!this.activitySelected.end) {
        const schoolarYears = this.settings.schoolarYears;
        const activeSchoolarYear = store.get("app.settings.activeSchoolarYear");
        const schoolarYear = schoolarYears.filter(
          (sc) => sc._id == activeSchoolarYear
        )[0];
        this.activitySelected.end = moment(schoolarYear.end);
      }
      if (!this.activitySelected.start) {
        const schoolarYears = this.settings.schoolarYears;
        const activeSchoolarYear = store.get("app.settings.activeSchoolarYear");
        const schoolarYear = schoolarYears.filter(
          (sc) => sc._id == activeSchoolarYear
        )[0];
        this.activitySelected.start = moment(schoolarYear.start);
      }
      let { start, end } = this.activitySelected;
      if (this.form.getFieldValue("start"))
        start = this.form.getFieldValue("start");
      return (current && current.isAfter(end)) || current.isBefore(start);
      //return current && current < moment().endOf("day");
    },
    handleSelectionModeChange() {
      this.form.resetFields(["students", "classes", "levels"]);
    },

    getClassName(classId) {
      const student = this.studentList.find((s) => s.classRoom === classId);
      return student ? student.classRoomName : "";
    },
    handleLevelChange(selectedLevels) {
      const studentsInLevels = [];

      selectedLevels.forEach((level) => {
        const levelClasses = this.classes.filter(
          (cls) => cls.level.toString() === level.toString()
        );

        levelClasses.forEach((cls) => {
          const classStudents = this.studentsData[cls._id] || [];
          studentsInLevels.push(...classStudents.map((s) => s._id));
        });
      });

      this.selectedLevelStudents = studentsInLevels;
    },
    selectActivity(val) {
      const activity = [...this.activities].filter((ac) => ac._id == val);
      const { start, end } = activity[0];
      this.activityInfos = activity[0];
      this.activitySelected.start = moment(start);
      this.activitySelected.end = moment(end);
      this.setIncludedFees();
    },
    validateMonths(activityFees, range) {
      let { start, end } = range;
      let result = true;
      if (!end) end = activityFees.activity.end;
      const newMonthly = monthly(start, end);
      const existMonthly = activityFees.months;
      try {
        if (
          Object.keys(existMonthly).forEach((value) => {
            if (Object.keys(newMonthly).includes(value)) result = false;
          })
        )
          result = false;
      } catch {
        result = false;
      }
      return result;
    },

    validateStudentActivity(student, activity, start, end) {
      const existingActivity = this.activityFees.find(
        (activityPayment) =>
          activityPayment.student === student &&
          activityPayment.activityDetails._id === activity &&
          !this.validateMonths(activityPayment, { start, end })
      );
      return !existingActivity;
    },

    async getStudentsWithoutActivity(studentIds, activityId, start, end) {
      const eligibleStudents = [];
      const ineligibleStudents = [];

      for (const studentId of studentIds) {
        const student = this.studentList.find((s) => s._id === studentId);
        const isEligible = this.validateStudentActivity(
          studentId,
          activityId,
          start,
          end
        );

        if (isEligible) {
          eligibleStudents.push(studentId);
        } else {
          ineligibleStudents.push({
            id: studentId,
            name: student
              ? `${student.firstName} ${student.lastName}`
              : studentId,
          });
        }
      }

      return { eligibleStudents, ineligibleStudents };
    },

    async fetchActivityPayments() {
      try {
        const response = await apiClient.get(
          "/payment/etatImpaye/activity/all"
        );
        this.actvitiyPayments = response.data
          .filter((p) => p && p.studentId && p._id)
          .map((p) => ({
            id: p._id,
            studentId: p.studentId,
            classroom: p.classroom,
            student: p.student,

            name: p.name,
          }));
      } catch (error) {
        console.error("Error fetching activity payments:", error);
        this.$message.error(this.$t("error.fetchActivityPayments"));
        this.actvitiyPayments = [];
      }
    },

    validateStudentActivity(studentId, activityId, start, end) {
      if (!studentId || !activityId) {
        console.warn("Invalid student or activity ID:", {
          studentId,
          activityId,
        });
        return false;
      }

      const selectedActivity = this.activities.find(
        (a) => a._id === activityId
      );
      if (!selectedActivity) return true;

      const existingActivity = this.actvitiyPayments.find(
        (payment) =>
          payment.studentId === studentId &&
          payment.name.toLowerCase() ===
            selectedActivity.designation.toLowerCase()
      );

      return !existingActivity;
    },

    async getStudentsWithoutActivity(studentIds, activityId, start, end) {
      const eligibleStudents = [];
      const ineligibleStudents = [];

      if (!Array.isArray(studentIds)) {
        console.error("studentIds is not an array:", studentIds);
        return { eligibleStudents: [], ineligibleStudents: [] };
      }

      for (const studentId of studentIds) {
        if (!studentId) continue;

        const isEligible = this.validateStudentActivity(
          studentId,
          activityId,
          start,
          end
        );
        const existingPayment = this.actvitiyPayments.find(
          (p) => p.studentId === studentId
        );

        if (isEligible) {
          eligibleStudents.push(studentId);
        } else {
          ineligibleStudents.push({
            id: studentId,
            name: existingPayment
              ? existingPayment.student
              : this.studentList.find((s) => s._id === studentId)?.firstName +
                  " " +
                  this.studentList.find((s) => s._id === studentId)?.lastName ||
                studentId,
          });
        }
      }

      return { eligibleStudents, ineligibleStudents };
    },

    addActivity(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          this.loadingClasse = true;

          try {
            let studentsToProcess = [];
            if (this.selectionMode === "students") {
              studentsToProcess = values.students;
            } else if (this.selectionMode === "classes") {
              values.classes.forEach((classId) => {
                const classStudents = this.studentsData[classId] || [];
                studentsToProcess.push(...classStudents.map((s) => s._id));
              });
            } else if (this.selectionMode === "levels") {
              studentsToProcess = this.selectedLevelStudents || [];
            }

            if (studentsToProcess.length === 0) {
              this.$message.warning(this.$t("error.aucEleve"));
              return;
            }

            studentsToProcess = [...new Set(studentsToProcess)];

            const { eligibleStudents, ineligibleStudents } =
              await this.getStudentsWithoutActivity(
                studentsToProcess,
                values.activity,
                values.start,
                values.end || this.activityInfos.end
              );

            if (ineligibleStudents.length > 0) {
              const ineligibleNames = ineligibleStudents
                .map((student) => student.name)
                .join(", ");

              this.$message.warning(
                `${this.$t(
                  "error.eleveDejaInscritPourMois"
                )} : ${ineligibleNames}`
              );

              if (eligibleStudents.length === 0) {
                return;
              }
            }

            const filteredRepReduction = Object.keys(this.includedFees)
              .filter((key) => this.includedFees[key] === true)
              .reduce((obj, key) => {
                obj[key] = false;
                return obj;
              }, {});

            const data = {
              start: values.start,
              end: values.end || this.activityInfos.end || undefined,
              students: eligibleStudents,
              activity: values.activity,
              inscription: values.inscription,
              typeAS: "A",
              mode: this.modePaiement,
              repReduction: Object.keys(filteredRepReduction).reduce(
                (obj, key) => {
                  obj[key] = 0;
                  return obj;
                },
                { inscription: 0 }
              ),
            };

            if (this.modePaiement == "M") {
              data.months = filteredRepReduction;
            } else {
              data.tranches = filteredRepReduction;
            }

            await apiClient.put(
              "/payment/registerStudents",
              values.end ? { ...data, end: values.end } : data
            );

            await this.fetchActivityPayments();

            this.$message.success(this.$t("success.inscriAjout"));
            if (ineligibleStudents.length > 0) {
              this.$message.info(
                `${eligibleStudents.length} ${this.$t("success.eleveInscrit")}`
              );
            }
            this.$emit("updateActivityInscription");
            this.form.resetFields();
          } catch (error) {
            console.error(error);
            this.$message.error(this.$t("error.inscriEchoue"));
          } finally {
            this.loadingClasse = false;
          }
        }
      });
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    async getActivitiesPayments() {
      this.tableLeading = true;
      await apiClient
        .post("/payment/filter", {
          query: {
            status: "active",
            typeAS: "A",
          },
        })
        .then(
          (res) =>
            (this.activityFees = res.data.map((elem) =>
              actvitiyPaymentsFormater(elem, elem.student._id, this.studentList)
            ))
        )
        .catch((e) => {
          console.log(e);
          this.$message.error(this.$t("error.aucInscri"));
        })
        .finally(() => {
          this.tableLeading = false;
        });
    },
    setIncludedFees() {
      const activeSchoolarYear = this.settings.activeSchoolarYear;
      const schoolarYears = this.settings.schoolarYears;
      let start = this.currentFeesRange.start || this.activityInfos.start;
      let end = this.currentFeesRange.end || this.activityInfos.end;
      schoolarYears.forEach((elem) => {
        if (elem._id == activeSchoolarYear) {
          if (!end) end = elem.end;
          if (!start) start = elem.start;
        }
      });

      const range = this.dateRange(
        moment(start).format("YYYY-MM-DD"),
        moment(end).format("YYYY-MM-DD")
      );

      this.includedFees = {};

      if (this.activityInfos.monthly && this.modePaiement == "M") {
        range.forEach((month) => {
          this.includedFees[month] = true;
        });
      }
      if (this.activityInfos.tranches && this.modePaiement == "T") {
        this.activityInfos.tranches.forEach((tranche) => {
          this.includedFees[tranche.trancheNumber] = true;
        });
      }
    },

    dateRange,
  },
};
</script>
<style scoped>
.custom-tag .ant-tag {
  padding: 4px 7px 4px 7px;
  font-size: 13px;
  margin-left: 3px;
}

.custom-tag .ant-tag:hover {
  cursor: pointer;
}
.custom-border {
  border: 1px solid #6c757d;
  border-radius: 0.25rem;
  cursor: pointer;
}
.custom-border-active {
  border: 3px solid #22bb33;
  border-radius: 0.25rem;
  cursor: pointer;
}
</style>
