<template>
  <div>
    <div class="row success">
      <div class="col-xl-8">
        <h5 class="mb-4">
          <span style="margin-right: 30px"> {{ $t("bulletin.notes") }}</span>
          <a-tooltip
            placement="right"
            v-if="selectedClasse && selectedTrimester"
          >
            <template slot="title">
              {{
                isValidatedBulletin()
                  ? $t("bulletin.hideBulletin")
                  : $t("bulletin.validateBulletin")
              }}
            </template>
            <span>
              <a-button
                :type="isValidatedBulletin() ? 'primary' : 'danger'"
                @click="confirmeBulletinValidation()"
              >
                {{
                  isValidatedBulletin()
                    ? $t("bulletin.cancelValidation")
                    : $t("bulletin.validatedClassroom")
                }}
              </a-button>
            </span>
          </a-tooltip>
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <label>{{ $t("bulletin.listeClasses") }}</label>
        <a-select
          show-search
          :value="selectedClasse || undefined"
          :placeholder="$t('emploi.listeClasses')"
          option-filter-prop="children"
          :filter-option="filterOption"
          style="width: 150px; margin-left: 15px"
          @change="
            (val) => {
              if (!updatedChanges && hasMarksChanged()) {
                unsavedChangesAlert(val, 'classe');
              } else {
                handleClassChange('classe', val);
              }
            }
          "
        >
          <a-select-option value="all"> --- </a-select-option>
          <a-select-option
            v-for="classe in classes"
            :key="classe._id"
            :value="classe._id"
          >
            {{ classe.name }}
          </a-select-option>
        </a-select>

        <label style="margin-right: 5px; margin-left: 15px">{{
          this.$t("scolarite.listeModules")
        }}</label>
        <a-select
          :placeholder="$t('scolarite.listeModules')"
          style="width: 180px; margin-left: 15px"
          :loading="moduleLoading"
          :value="selectedModule || undefined"
          show-search
          option-filter-prop="children"
          :filter-option="filterOption"
          @change="
            (val) => {
              if (!updatedChanges && hasMarksChanged()) {
                unsavedChangesAlert(val, 'module');
              } else {
                this.selectedModule = val;

                this.activeModule = this.listModules.find(
                  (module) => module._id == val
                );

                this.selectedModuleName = this.activeModule.name;

                handleModuleChange(val);
              }
            }
          "
        >
          <a-select-option
            v-for="module in listModules"
            :key="module._id"
            :value="module._id"
          >
            {{ module.name }}
          </a-select-option>
        </a-select>

        <label style="margin-right: 5px; margin-left: 15px">{{
          this.$t("emploi.trimestre")
        }}</label>
        <a-select
          :placeholder="$t('paiement.selectionnerMois')"
          style="width: 50px; margin-left: 15px"
          :value="selectedTrimester || '1'"
          @change="
            (val) => {
              if (!updatedChanges && hasMarksChanged()) {
                unsavedChangesAlert(val, 'trimester');
              } else {
                this.selectedTrimester = val;
                this.handleClassChange('classe', this.selectedClasse);
                getTeachersAccess();
                if (this.selectedModule) updateMarks();
              }
            }
          "
        >
          <a-select-option
            v-for="trimester in ['1', '2', '3']"
            :key="trimester"
            :value="trimester"
          >
            {{ trimester }}
          </a-select-option>
        </a-select>

        <a-dropdown style="float: right" v-if="selectedClasse">
          <template #overlay>
            <a-menu
              @click="
                (val) => {
                  if (val.key == '1') printFiche();
                  else if (val.key == '2') printFiche(true);
                  else if (val.key == '3') printFicheExcel();
                  else if (val.key == '4') printFicheExcel(true);
                }
              "
            >
              <a-menu-item key="1">{{
                $t("bulletin.imprimerFichModuleVide")
              }}</a-menu-item>
              <a-menu-item key="2">{{
                $t("bulletin.imprimerFichModule")
              }}</a-menu-item>
              <a-menu-item key="3">{{
                $t("bulletin.imprimerFichModuleVideexcel")
              }}</a-menu-item>
              <a-menu-item key="4">{{
                $t("bulletin.imprimerFichModuleexcel")
              }}</a-menu-item>
            </a-menu>
          </template>
          <a-button
            type="primary"
            :loading="printLoading"
            :disabled="printLoading"
          >
            <a-icon v-if="!printLoading" type="file-pdf" />
            {{ $t("action.imprimer") }}
          </a-button>
        </a-dropdown>

        <br />
        <br />
        <div class="card" v-if="selectedModule && selectedClasse">
          <a-table
            :columns="columns"
            :data-source="activeData"
            :pagination="false"
            :loading="tableLoading"
            :scroll="{ x: 'max-content' }"
          >
            <template
              v-for="subject in subjectList"
              :slot="subject._id"
              slot-scope="text, record"
            >
              <div :key="subject._id">
                <div>
                  <a-alert
                    :message="$t('bulletin.alert')"
                    banner
                    closable
                    v-if="subject.disabled && !subject.divided"
                  />
                  <div
                    :class="
                      getMarkValue(subject._id, record._id, false) < 0 ||
                      getMarkValue(subject._id, record._id, false) > 20
                        ? 'danger'
                        : ''
                    "
                  >
                    <span class="ant-input-wrapper">
                      <a-input-number
                        :value="getMarkValue(subject._id, record._id, false)"
                        style="height: 35px; width: 65px; text-align: center"
                        :disabled="subject.disabled"
                        @change="
                          (val) =>
                            handleMarkChange(
                              val,
                              subject._id,
                              null,
                              record._id,
                              false
                            )
                        "
                      />
                      <span class="ant-input-group-addon">/20</span>
                    </span>
                    <a-popconfirm
                      :title="$t('all.sureToDelete')"
                      @confirm="
                        () => deleteMark(subject._id, record._id, false)
                      "
                    >
                      <a-button
                        tabindex="-1"
                        type="dashed"
                        shape="circle"
                        class="ml-2"
                        icon="delete"
                      />
                    </a-popconfirm>
                  </div>
                </div>
              </div>
            </template>

            <template
              v-for="ss in getAllSubsubjects()"
              :slot="ss._id"
              slot-scope="text, record"
            >
              <div :key="ss._id">
                <a-alert
                  v-if="
                    getSubjectForSubsubject(ss._id).disabled &&
                    isFirstSubsubject(ss._id)
                  "
                  :message="$t('bulletin.alert')"
                  banner
                  closable
                />
                <div
                  :class="
                    getMarkValue(ss._id, record._id, true) < 0 ||
                    getMarkValue(ss._id, record._id, true) > ss.maxMark
                      ? 'danger'
                      : ''
                  "
                >
                  <span class="ant-input-wrapper">
                    <a-input-number
                      :value="getMarkValue(ss._id, record._id, true)"
                      style="height: 35px; width: 65px; text-align: center"
                      :disabled="getSubjectForSubsubject(ss._id).disabled"
                      @change="
                        (val) =>
                          handleMarkChange(
                            val,
                            getSubjectForSubsubject(ss._id)._id,
                            ss._id,
                            record._id,
                            true
                          )
                      "
                    />
                    <span class="ant-input-group-addon">/{{ ss.maxMark }}</span>
                  </span>
                  <a-popconfirm
                    :title="$t('all.sureToDelete')"
                    @confirm="() => deleteMark(ss._id, record._id, true)"
                  >
                    <a-button
                      tabindex="-1"
                      type="dashed"
                      shape="circle"
                      class="ml-2"
                      icon="delete"
                    />
                  </a-popconfirm>
                </div>
              </div>
            </template>

            <template
              v-for="subject in subjectList"
              :slot="`${subject._id}_finalGrade`"
              slot-scope="text, record"
            >
              <div :key="`${subject._id}_final`">
                <span v-if="subject.divided" class="ant-input-wrapper">
                  <a-input-number
                    :value="getTotalSubsubjects(record, subject._id)"
                    :disabled="true"
                    style="
                      height: 35px;
                      width: 65px;
                      text-align: center;
                      background-color: #f0f2f4;
                    "
                  />
                  <span class="ant-input-group-addon">/20</span>
                </span>
              </div>
            </template>
            <template slot="moduleAverage" slot-scope="text, record">
              <div>
                <span class="ant-input-wrapper">
                  <a-input-number
                    :value="calculateModuleAverage(record)"
                    style="
                      height: 35px;
                      width: 65px;
                      text-align: center;
                      background-color: #f0f2f4;
                    "
                    :disabled="true"
                  />
                  <span class="ant-input-group-addon">/20</span>
                </span>
              </div>
            </template>
          </a-table>
        </div>
        <div
          class="row"
          v-if="selectedClasse && selectedModule && activeData.length !== 0"
        >
          <div class="col-xl-7">{{ $t("bulletin.toutMatieres") }}</div>
          <div class="col-xl-3">
            <a-button
              :loading="saveLoading"
              style="
                margin-right: 5px;
                margin-left: 20px;
                margin-bottom: 10px;
                background: #8bc34a !important;
                border-color: #8bc34a !important;
              "
              type="primary"
              @click="saveAll()"
            >
              {{ $t("bulletin.enregistrerTout") }}<a-icon type="check" />
            </a-button>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      @ok="
        () => {
          visibleBulletinDate = false;
        }
      "
      @cancel="
        () => {
          visibleBulletinDate = false;
        }
      "
      v-model="visibleBulletinDate"
      :title="$t('bulletin.date')"
      :closable="false"
      :width="500"
      :height="700"
      :key="'bulletinDate'"
    >
      <div>
        <label style="margin-right: 5px">{{ $t("bulletin.date") }}</label>
        <a-date-picker
          :allowClear="false"
          @change="
            (val) => {
              bulletinDate = val;
            }
          "
          :default-value="moment(new Date())"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
/*eslint-disable */
//ANCHOR class/month filter to this view: DONE

import apiClient from "@/services/axios";
import { mapState } from "vuex";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { notification, Modal } from "ant-design-vue";
import moment from "moment";
import ExcelJS from "exceljs";
export default {
  computed: mapState(["settings", "user"]),
  props: {
    type: {
      type: String,
      required: true,
    },
    subModuleList: {
      type: Array,
      required: true,
    },
  },

  destroyed() {
    window.removeEventListener("beforeunload", this.confirmLeaveReload);
  },
  async created() {
    window.addEventListener("beforeunload", this.confirmLeaveReload);
    this.tableLoading = true;
    this.getTeachersAccess();
    await apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
            level: 1,
            bulletinVerySpec: 1,
          },
        },
      })
      .then((res) => (this.classes = res.data));

    this.tableLoading = false;
    this.filtredTable = this.activeData;

    this.schoolDetails = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0];

    let { start, end } = this.settings.schoolarYears.find(
      (el) => el._id == this.settings.activeSchoolarYear
    );

    start = new Date(start).getFullYear();
    end = new Date(end).getFullYear();

    this.schoolarYearName = end + " / " + start;

    this.schoolType = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0].type;
    if (!this.schoolType)
      notification.warning({
        message: this.$t("bulletin.typeIntrouvable"),
        description: this.$t("bulletin.saisirSchoolType"),
      });

    // apiClient
    //   .get("/mark/classroomMarksStats/" + this.selectedTrimester + "/normal")
    //   .then(({ data }) => {
    //     this.classroomMarksStats = data;
    //   });
  },
  data() {
    return {
      subjectsDisabled: [],
      visibleBulletinDate: false,
      bulletinDate: new Date(),
      updatedChanges: true,
      printLoading: false,
      syncLoading: false,
      syncSubjectLoading: false,
      visibleClassnameList: false,
      visibleSubjectList: false,
      schoolType: null,
      filtredTable: [],
      subjectList: [],
      classroomMarksStats: {},
      selectedModule: this.$t("scolarite.listeModules"),
      selectedModuleName: "",
      bulletins: { students: [] },
      selectedClassName: "",
      markData: [],
      originalMarkData: [],
      activeModule: null,
      bulletinData: [],
      certifParams: [],
      moduleLoading: false,
      selectedTrimester: "1",
      selectedClasse: null,
      tableLoading: false,
      saveLoading: false,
      activeData: [],
      markRefs: [],
      filters: {
        classe: false,
        classe_val: null,
        month: false,
        month_val: null,
      },
      rowData: [],
      data1: null,
      classes: [],
      editable: false,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      columns: [],
      subsubjectData: [],
      listModules: [],
      selectedLevel: null,
      bulletinColors: {
        color1: [70, 187, 239],
        color2: [64, 187, 239],
        color3: [16, 169, 229],
        color4: [67, 62, 63],
        color5: [52, 172, 215],
        color6: [224, 237, 243],
        color7: [64, 187, 239],
        color8: [225, 225, 226],
        color9: [224, 237, 243],
      },
      schoolDetails: {},
      schoolarYearName: "",
      teacherAccess: [],
    };
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;

    if (this.updatedChanges) next();
    else
      this.$confirm({
        title: this.$t("all.unsavedchanges"),
        content: this.$t("all.unsavedchangesContent"),
        okText: this.$t("all.oui"),
        okType: "danger",
        cancelText: this.$t("action.annuler"),
        onOk() {
          _this.updatedChanges = true;
          next();
        },
        onCancel() {
          next(false);
        },
      });
  },
  methods: {
    moment,
    getSumMaxMArks(subsubjectData) {
      let sumMax = 0;
      subsubjectData.forEach((sub) => {
        sumMax += sub.maxMark;
      });
      return sumMax;
    },
    confirmLeaveReload(event) {
      if (!this.updatedChanges) {
        event.returnValue = this.$t("all.unsavedchangesContent");
      }
    },

    confirmeBulletinValidation() {
      const content = this.isValidatedBulletin()
        ? this.$t("bulletin.hideBulletin")
        : this.$t("bulletin.validateBulletin");
      this.$confirm({
        title: this.$t("all.sureTo"),
        content: (h) => <div style="color:red"> {content} </div>,
        onOk: () => {
          //console.log("OK");
          this.validateBulletin();
        },
        onCancel: () => {
          //console.log("Cancel");
        },
        class: "test",
      });
    },
    bulletinType: function (select) {
      switch (this.type) {
        case "MP":
          switch (select) {
            case 1:
              return "peda";
            case 2:
              return "bulletin";
          }
        case "MT":
          switch (select) {
            case 1:
              return "specific";
            case 2:
              return "bulletinSpec";
          }
        case "MS":
          switch (select) {
            case 1:
              return "verySpecific";
            case 2:
              return "bulletinVerySpec";
          }
      }
    },
    isValidatedBulletin() {
      if (!this.selectedClasse) return false;
      const classroom = this.classes.find(
        (item) => item._id === this.selectedClasse
      );

      if (!classroom) return false;

      const bulletin = classroom[this.bulletinType(2)] || {
        sem1: false,
        sem2: false,
        sem3: false,
      };

      if (this.selectedTrimester == 1 && bulletin.sem1 === true) return true;
      if (this.selectedTrimester == 2 && bulletin.sem2 === true) return true;
      if (this.selectedTrimester == 3 && bulletin.sem3 === true) return true;

      return false;
    },
    validateBulletin() {
      const classroom = this.classes.find(
        (item) => item._id === this.selectedClasse
      );

      if (!classroom) {
        console.error(
          "Classroom not found for selectedClasse:",
          this.selectedClasse
        );
        this.$message.error(this.$t("error.classroomNotFound"));
        return;
      }

      const bulletin = classroom[this.bulletinType(2)] || {
        sem1: false,
        sem2: false,
        sem3: false,
      };

      if (![1, 2, 3].includes(Number(this.selectedTrimester))) {
        console.error("Invalid trimester:", this.selectedTrimester);
        this.$message.error(this.$t("error.invalidTrimester"));
        return;
      }

      if (this.selectedTrimester == 1) bulletin.sem1 = !bulletin.sem1;
      if (this.selectedTrimester == 2) bulletin.sem2 = !bulletin.sem2;
      if (this.selectedTrimester == 3) bulletin.sem3 = !bulletin.sem3;

      const bulletinField =
        this.type === "MP"
          ? "bulletin"
          : this.type === "MT"
          ? "bulletinSpec"
          : "bulletinVerySpec";

      apiClient
        .patch(`/classrooms/${this.selectedClasse}`, {
          data: {
            [bulletinField]: bulletin,
          },
          bulletin: {
            classroom: this.selectedClasse,
            trimester: this.selectedTrimester,
            available: bulletin[`sem${this.selectedTrimester}`],
            type: this.bulletinType(2),
          },
        })
        .then(() => {
          this.classes = this.classes.map((item) => {
            if (item._id === this.selectedClasse) {
              item[this.bulletinType(2)] = bulletin;
            }
            return item;
          });

          if (bulletin[`sem${this.selectedTrimester}`]) {
            this.$message.success(
              this.$t("bulletin.validated", { name: this.selectedClassName })
            );
          } else {
            this.$message.success(
              this.$t("bulletin.unvalidated", { name: this.selectedClassName })
            );
          }
        })
        .catch((error) => {
          console.error("Error updating bulletin:", error);
          this.$message.error(this.$t("error.updateFailed"));
        });
    },
    async sleep(timeout) {
      await new Promise((r) => setTimeout(r, timeout));
    },
    componentToHex(c) {
      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    },

    rgbToHex(r, g, b) {
      return (
        "#" +
        this.componentToHex(r) +
        this.componentToHex(g) +
        this.componentToHex(b)
      );
    },
    async getModules(level) {
      this.moduleLoading = true;
      await apiClient
        .post("/bulletin/v2/module/filter/marks", {
          status: "active",
          level: level,
          type: this.type,
          trimester: this.selectedTrimester,
        })
        .then((res) => {
          if (res.data.length == 0) {
            this.listModules = [];
            return this.$message.warning(this.$t("error.aucModule"));
          }

          this.listModules = res.data;

          //    this.listModules = this.listModules.map((module) => {
          //      module.subjects = module.subjects.map((subject) => {
          //        const subsubjects = module.subjectsInfo[subject._id];
          //        subject.divided = false;
          //        if (subsubjects && subsubjects.subsubjectsMark) {
          //          subject.divided = true;
          //          subject.subsubjectData = [];
          //          for (const key in subsubjects.subsubjectsMark) {
          //            const s = module.subsubjects.find(
          //              (s) => String(s._id) == String(key)
          //            );

          //            const subsubject = subsubjects.subsubjectsMark[key];
          //            subject.subsubjectData.push({
          //              name: s.name,
          //              _id: s._id,
          //              divided: true,
          //              maxMark: subsubject,
          //            });
          //          }
          //        }

          //        return subject;
          //      });
          //      return module;
          //    });
          //
        })
        .catch((e) => {
          this.$message.error(this.$t("error.erreur"));
        })
        .finally(() => (this.moduleLoading = false));
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    async saveAll() {
      this.saveLoading = true;

      // ! DEPRECATED DWEB-969
      // check if subsubject marks are completed
      // for (const subject of this.subjectList) {
      //   if (subject.divided) {
      //     const subsubjects = subject.subsubjectData.map((s) => String(s._id));
      //     for (const student of this.activeData) {
      //       const marks = this.markData.filter((m) => {
      //         return (
      //           m.student == student._id &&
      //           m.divided &&
      //           subsubjects.includes(m.subsubject)
      //         );
      //       });
      //
      //       if (marks.length != 0 && marks.length != subsubjects.length) {
      //         this.saveLoading = false;
      //         return this.$message.warning(
      //           this.$t("warning.missedSubsubjects", { name: student.fullName })
      //         );
      //       }
      //     }
      //   }
      // }

      // check if marks are correct
      let thereError = false;
      for (const mark of this.markData) {
        if (!mark.divided) {
          if (isNaN(Number(mark.mark)) || mark.mark > 20 || mark.mark < 0) {
            thereError = true;
            break;
          }
        } else if (mark.divided) {
          let subsubject;
          for (const subject of this.subjectList) {
            if (subject.divided) {
              subsubject = subject.subsubjectData.find(
                (s) => s._id == mark.subsubject
              );
              if (subsubject) break;
            }
          }
          if (subsubject)
            if (
              isNaN(Number(mark.mark)) ||
              mark.mark > subsubject.maxMark ||
              mark.mark < 0
            ) {
              thereError = true;
              break;
            }
        }
      }

      if (thereError) {
        this.$message.warning(this.$t("warning.notCorrectMarks"));
        this.saveLoading = false;
        return;
      }

      // saving
      const toAddData = [];

      for (const mark of this.markData) {
        const originalMark = this.originalMarkData.find(
          (m) =>
            m.student == mark.student &&
            ((!m.divided && m.subject == mark.subject) ||
              (m.divided && m.subsubject == mark.subsubject))
        );
        if (originalMark) {
          if (Number(mark.mark) != Number(originalMark.mark))
            await apiClient
              .patch("/bulletin/v2/mark/" + originalMark._id, {
                data: {
                  mark: mark.mark,
                },
              })
              .then()
              .catch((err) => console.log(err));
        } else {
          const obj = {
            divided: mark.divided,
            student: mark.student,
            mark: mark.mark,
            trimester: this.selectedTrimester,
            classroom: this.selectedClasse,
            subject: mark.subject,
            type: "one",
          };
          if (mark.divided) obj.subsubject = mark.subsubject;
          toAddData.push(obj);
        }
      }

      await apiClient
        .put("/bulletin/v2/mark/many", {
          data: toAddData,
        })
        .then()
        .catch((err) => console.log(err));

      for (const originalMark of this.originalMarkData) {
        const mark = this.markData.find(
          (m) =>
            m.student == originalMark.student &&
            ((!m.divided && m.subject == originalMark.subject) ||
              (m.divided && m.subsubject == originalMark.subsubject))
        );
        if (!mark)
          await apiClient
            .delete("/bulletin/v2/mark/" + originalMark._id)
            .then()
            .catch((err) => console.log(err));
      }

      await this.updateMarks();

      this.updatedChanges = true;
      this.$message.success(this.$t("success.noteToutesEnregistre"));

      this.saveLoading = false;
    },

    deleteMark(id, studentId, divided) {
      this.markData = this.markData.filter(
        (m) =>
          !(
            m.student == studentId &&
            m.divided == divided &&
            (m.subsubject == id || m.subject == id)
          )
      );
      this.updatedChanges = false;
    },

    handleMarkChange(newMark, subjectId, subSubjectId, studentId, divided) {
      const mark = this.markData.find(
        (m) =>
          m.student == studentId &&
          ((divided && m.subsubject == subSubjectId) ||
            (!divided && m.subject == subjectId))
      );
      if (mark) mark.mark = Number(newMark);
      else {
        const obj = {
          student: studentId,
          mark: Number(newMark),
          divided: divided,
          subject: subjectId,
        };
        if (divided) obj.subsubject = subSubjectId;

        this.markData.push(obj);
      }

      this.updatedChanges = false;
    },

    getMarkValue(id, studentId, divided) {
      const mark = this.markData.find(
        (m) =>
          m.student == studentId &&
          m.divided == divided &&
          (m.subsubject == id || m.subject == id)
      );
      if (mark) return mark.mark;
      return null;
    },

    getTotalSubsubjects(record, subjectId) {
      try {
        const subjectList = this.listModules.flatMap((item) => item.subjects);
        const subject = subjectList.find((s) => s._id == subjectId);
        const subsubjects = subject.subsubjectData.map((s) => String(s._id));
        const marks = this.markData.filter((m) => {
          return (
            m.student == record._id &&
            m.divided &&
            subsubjects.includes(m.subsubject)
          );
        });

        let total = 0;
        let subSubjectTotalCoefs = 0;
        marks.forEach((mark) => {
          let subsubjectData = subject.subsubjectData.find(
            (s) => s._id == mark.subsubject
          );
          subSubjectTotalCoefs += subsubjectData.coefMark;
          total +=
            (mark.mark * (subsubjectData.coefMark ?? 1)) /
            subsubjectData.maxMark;
        });
        return total
          ? ((total * 20) / (subSubjectTotalCoefs ?? 1)).toFixed(2)
          : "";
      } catch (error) {
        return "";
      }
    },

    async handleModuleChange(val) {
      this.columns = [
        {
          title: this.$t("bulletin.nom"),
          dataIndex: "fullName",
          key: "fullName",
          scopedSlots: {
            customRender: "fullName",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.fullName
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
      ];

      this.subjectList = this.activeModule.subjects;

      for (const subject of this.subjectList) {
        const subjectColumn = {
          title: subject.name + this.getTeacherName(subject._id),
          dataIndex: subject._id,
          scopedSlots: { customRender: subject._id },
        };

        if (subject.subsubjectData?.length > 0) {
          subjectColumn.children = [
            ...subject.subsubjectData.map((ss) => ({
              title: `${ss.name} / ${ss.maxMark}`,
              dataIndex: ss._id,
              scopedSlots: { customRender: ss._id },
            })),
            {
              title: "/20",
              dataIndex: `${subject._id}_finalGrade`,
              scopedSlots: { customRender: `${subject._id}_finalGrade` },
            },
          ];
          delete subjectColumn.scopedSlots;
        }

        this.columns.push(subjectColumn);
      }

      this.updateMarks();
      this.columns.push({
        title: this.$t("bulletin.avg"),
        dataIndex: "moduleAverage",
        scopedSlots: { customRender: "moduleAverage" },
      });
    },
    calculateModuleAverage(record) {
      let totalMarks = 0;
      let totalSubjects = 0;

      this.subjectList.forEach((subject) => {
        if (subject.divided) {
          const total = this.getTotalSubsubjects(record, subject._id);

          if (total !== null && !isNaN(parseFloat(total))) {
            const numericTotal = parseFloat(total);

            totalMarks += numericTotal;

            totalSubjects++;
          }
        } else {
          const mark = this.getMarkValue(subject._id, record._id, false);

          if (mark !== null && !isNaN(parseFloat(mark))) {
            const numericMark = parseFloat(mark);

            totalMarks += numericMark;

            totalSubjects++;
          }
        }
      });

      const result =
        totalSubjects > 0 ? (totalMarks / totalSubjects).toFixed(2) : null;

      return result;
    },
    getAllSubsubjects() {
      const allSubsubjects = [];
      this.subjectList.forEach((subject) => {
        if (subject.subsubjectData) {
          subject.subsubjectData.forEach((ss) => {
            ss.parentSubject = subject._id;
            allSubsubjects.push(ss);
          });
        }
      });
      return allSubsubjects;
    },
    isFirstSubsubject(ssId) {
      const subject = this.getSubjectForSubsubject(ssId);
      return subject.subsubjectData[0]._id === ssId;
    },
    getSubjectForSubsubject(subsubjectId) {
      return (
        this.subjectList.find((subject) =>
          subject.subsubjectData?.some((ss) => ss._id === subsubjectId)
        ) || {}
      );
    },

    async updateMarks() {
      this.tableLoading = true;
      await apiClient
        .post("/bulletin/v2/mark/filter", {
          query: {
            classroom: this.selectedClasse,
            trimester: this.selectedTrimester,
            schoolarYear: this.settings.activeSchoolarYear,
            type: {
              $in: ["one"],
            },
          },
        })
        .then(({ data }) => {
          this.markData = [];
          this.originalMarkData = [];
          this.originalMarkData = data;
          data.map((mark) => {
            this.markData.push({
              student: mark.student,
              subject: mark.subject,
              subsubject: mark.subsubject,
              mark: mark.mark,
              divided: mark.divided,
            });
          });
        })
        .finally(() => (this.tableLoading = false));
    },

    async handleClassChange(on, value) {
      this.markRefs = [];
      this.selectedModule = "";
      this.moduleLoading = true;
      this.selectedClasse = value;
      this.selectedClassName = this.classes.find(
        (item) => item._id === value
      ).name;
      this.selectedLevel = this.classes.find(
        (item) => item._id === value
      ).level;
      let match = {};
      match[`schoolarYearsHistory.${this.settings.activeSchoolarYear}`] = value;
      await apiClient
        .post("/students/filter", {
          query: { status: "active" },
          aggregation: [
            {
              $match: match,
            },
            {
              $set: {
                classRoom: {
                  $convert: {
                    input: `$schoolarYearsHistory.${this.settings.activeSchoolarYear}`,
                    to: "objectId",
                    onError: null,
                    onNull: null,
                  },
                },
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classRoom",
                foreignField: "_id",
                as: "classroom",
              },
            },
            {
              $project: {
                _id: 1,
                firstName: 1,
                lastName: 1,
                gender: 1,
                firstNameLower: { $toLower: "$firstName" },
                classroom: {
                  _id: 1,
                  name: 1,
                },
              },
            },
            {
              $sort: {
                firstNameLower: 1,
              },
            },
          ],
        })
        .then(({ data }) => {
          this.rowData = [];
          data.map((elem) => {
            try {
              this.rowData.push({
                _id: elem._id,
                key: elem._id,
                fullName: elem.firstName + " " + elem.lastName,
                classroom: elem.classroom[0].name,
                classroom_id: elem.classroom[0]._id,
                gender: elem.gender,
              });
            } catch {}
          });
          this.activeData = this.rowData;
          this.tableLoading = false;
          this.filtredTable = this.activeData;
        });
      //get modules
      this.getModules(this.selectedLevel);
      this.updateMarks();
    },
    getTeachersAccess() {
      apiClient
        .get(`/teacherAccess/${this.selectedTrimester}`)
        .then((res) => (this.teacherAccess = res.data))
        .catch((err) => console.error(err));
    },
    getTeacherName(subjectId) {
      let teacherName = "";
      try {
        const teacher = this.teacherAccess.find((teacher) =>
          teacher.access.some(
            (access) =>
              access.classeRoom._id === this.selectedClasse &&
              access.subjects.some((sub) => sub._id === subjectId)
          )
        );
        if (teacher) {
          teacherName = teacher.fullName;
        }
      } catch (error) {
        return "";
      }
      return teacherName ? " (" + teacherName + ")" : "";
    },
    async printFicheExcel(withGrades = false) {
      this.printLoading = true;

      const workbook = new ExcelJS.Workbook();
      let className =
        this.classes.find((el) => el._id == this.selectedClasse)?.name || "";

      this.listModules.forEach((module) => {
        const worksheet = workbook.addWorksheet(module.name);

        const level1Header = [];
        const level2Header = [];
        let totalColumns = 0;

        let columnWidths = [];

        module.subjects.forEach((subject) => {
          const teacherName = this.getTeacherName(subject._id) || "";
          const isDivided =
            subject.subsubjectData && subject.subsubjectData.length;

          const subSubjects = isDivided
            ? subject.subsubjectData.map((s) => `${s.name} / ${s.maxMark}`)
            : [];

          subSubjects.unshift("20 / العدد");

          const subjectColspan = subSubjects.length;

          level1Header.push({
            header: `${subject.name}\n${teacherName}`,
            colspan: subjectColspan,
          });

          level2Header.push(...subSubjects);

          columnWidths.push(subjectColspan > 1 ? 20 : 40);

          totalColumns += subjectColspan;
        });

        level1Header.push({ header: "قائمة التلاميذ", colspan: 1 });
        level1Header.push({ header: "العدد الرتبي", colspan: 1 });

        level2Header.push("", "");

        totalColumns += 2;

        let currentColumn = 1;
        level1Header.forEach((header) => {
          if (header.colspan > 1) {
            worksheet.mergeCells(
              1,
              currentColumn,
              1,
              currentColumn + header.colspan - 1
            );
          }
          worksheet.getCell(1, currentColumn).value = header.header;
          worksheet.getCell(1, currentColumn).alignment = {
            horizontal: "center",
            vertical: "middle",
          };
          worksheet.getCell(1, currentColumn).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "B0B0B0" },
          };
          currentColumn += header.colspan;
        });

        worksheet.addRow([]);
        let emptyRow = worksheet.getRow(2);
        emptyRow.height = 20;
        emptyRow.eachCell((cell) => {
          cell.alignment = { horizontal: "center", vertical: "middle" };
        });

        currentColumn = 1;
        level2Header.forEach((subHeader) => {
          worksheet.getCell(2, currentColumn).value = subHeader;
          worksheet.getCell(2, currentColumn).alignment = {
            horizontal: "center",
            vertical: "middle",
          };
          currentColumn += 1;
        });

        let rowIndex = 3;
        this.activeData.forEach((student, studentIndex) => {
          const row = [];

          module.subjects.forEach((subject) => {
            const isDivided =
              subject.subsubjectData && subject.subsubjectData.length;
            if (isDivided) {
              const totalMark = this.getTotalSubsubjects(student, subject._id);
              row.push(withGrades ? totalMark : "");

              subject.subsubjectData.forEach((sub) => {
                const mark =
                  this.getMarkValue(sub._id, student._id, true) || "";
                row.push(withGrades ? mark : "");
              });
            } else {
              const mark =
                this.getMarkValue(subject._id, student._id, false) || "";
              row.push(withGrades ? mark : "");
            }
          });

          row.push(student.fullName);
          row.push(studentIndex + 1);

          worksheet.addRow(row);
          rowIndex += 1;
        });

        worksheet.eachRow((row, rowNumber) => {
          row.eachCell((cell) => {
            if (rowNumber <= 2) {
              cell.font = { bold: true };
              cell.alignment = { horizontal: "center", vertical: "middle" };
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "E8E8E8" },
              };
            } else {
              cell.alignment = { horizontal: "right", vertical: "middle" };
            }
          });
        });

        worksheet.columns = level2Header.map((header, index) => ({
          width: columnWidths[index] || 20,
        }));

        worksheet.getColumn(totalColumns - 1).width = 25;
        worksheet.getColumn(totalColumns).width = 10;
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: "application/octet-stream" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${className} - قائمة أعداد تلاميذ.xlsx`;
      link.click();

      this.printLoading = false;
    },
    async printFiche(withGrades = false) {
      if (withGrades) await this.updateMarks();
      this.printLoading = true;

      let teacherName = "";

      this.$gtag.event(
        withGrades ? "Imp fich par module remplie" : "Imp par module fich vide",
        {
          event_category: "Impression PDF",
          event_label: "Bulletin Modules section",
          value: 1,
        }
      );

      this.printLoading = true;
      var doc = new JsPDF({
        orientation: "p",
        unit: "mm",
        format: "a3",
      });
      const pdf_width = doc.internal.pageSize.width;

      let schoolDetails = this.user.building.find(
        (el) => el.dbName == this.settings.activeBuilding
      );
      let schoolName = schoolDetails.ArabicName
        ? schoolDetails.ArabicName
        : schoolDetails.name;
      let { start, end } = this.settings.schoolarYears.find(
        (el) => el._id == this.settings.activeSchoolarYear
      );

      start = new Date(start).getFullYear();
      end = new Date(end).getFullYear();

      //console.log(this.schoolarYearName);
      let schoolarYearName = end + " / " + start;
      let trimesterStr = "الثلاثي ";
      if (this.selectedTrimester == "1") trimesterStr += "الأول";
      if (this.selectedTrimester == "2") trimesterStr += "الثاني";
      if (this.selectedTrimester == "3") trimesterStr += "الثالث";

      let className = this.classes.find(
        (el) => el._id == this.selectedClasse
      ).name;
      className = className ? className : "";
      let date = new Date();

      this.listModules.forEach((module, i) => {
        if (i > 0) {
          doc.addPage();
        } ////
        doc.setFontSize(14);
        doc.setFont("Amiri", "Bold");
        let formattedDate = moment(date)
          .locale("ar-tn")
          .format("dddd ، D MMMM ، YYYY");
        doc.text(15, 15, formattedDate ? formattedDate : "");

        let width1 = doc.getTextWidth(`: المدرسة `);
        doc.text(pdf_width - width1 - 15, 15, ` : المدرسة `);
        let width2 = doc.getTextWidth(schoolName);
        doc.text(pdf_width - width1 - width2 - 15, 15, schoolName);

        width1 = doc.getTextWidth(`${schoolarYearName} : السنة الدراسية`);
        doc.text(
          pdf_width - width1 - 15,
          22,
          `${schoolarYearName} : السنة الدراسية`
        );

        doc.text(
          pdf_width - doc.getTextWidth(trimesterStr) - 15,
          29,
          trimesterStr
        );
        doc.setFontSize(22);
        doc.setTextColor(0, 0, 0);

        doc.text(pdf_width / 2 - 15, 15, "قائمة الأعداد");
        doc.setFontSize(18);
        width1 = doc.getTextWidth(className);

        doc.text(pdf_width / 2 - width1 / 2, 22, className);
        width1 = doc.getTextWidth(module.name);
        doc.setFontSize(20);

        doc.text(pdf_width / 2 - width1 / 2, 33, module.name);

        /////
        let level1Header = [];
        let level2header = [];

        module.subjects.forEach((subject, i) => {
          let teacherName = this.getTeacherName(subject._id);

          let numberOfCols = 1;
          let subSubjectArray = [];
          let devided = subject.subsubjectData && subject.subsubjectData.length;
          if (devided) {
            numberOfCols = subject.subsubjectData.length + 1;
            subSubjectArray = subject.subsubjectData.map(
              (s) => s.name + " \n/" + s.maxMark
            );
          }
          subSubjectArray.unshift({
            content: "العدد /20",
            styles: {
              textColor: [0, 0, 0], // RGB for black
              fillColor: [232, 232, 232],
            },
          });
          let subjectNameHeader =
            subject.name + (teacherName ? "\n" + teacherName : "");
          level1Header.push({
            content: subjectNameHeader,
            colSpan: numberOfCols,
            styles: {
              halign: "center",
              textColor: [0, 0, 0],
              fontSize: 12,
              fillColor: [232, 232, 232],
            },
          });
          level2header = level2header.concat(subSubjectArray);
        });
        /********start table */
        level1Header.push({
          content: "قائمة التلاميذ",
          rowSpan: 2,
          styles: { fontSize: 12 },
        });
        level1Header.push({
          content: "العدد \nالرتبي",
          rowSpan: 2,
          styles: {
            fontSize: 12,
            cellWidth: 16,
            cellPadding: 0, // Remove internal cell padding
          },
        });
        const header = [level1Header, level2header];
        let array = [];
        let index = 1;

        this.activeData.forEach((student) => {
          let data = [];
          module.subjects.forEach((subject) => {
            let tempData = [];
            if (subject.divided) {
              subject.subsubjectData.forEach((s) => {
                const mark = this.getMarkValue(s._id, student._id, true) || "";

                tempData.push(withGrades ? mark : "");
              });
              tempData.unshift({
                content: withGrades
                  ? this.getTotalSubsubjects(student, subject._id)
                  : "",
                styles: {
                  fillColor: [232, 232, 232],
                },
              });
            } else {
              const mark =
                this.getMarkValue(subject._id, student._id, false) || "";
              tempData.unshift({
                content: withGrades ? mark : "",
                styles: {
                  fillColor: [232, 232, 232],
                },
              });
            }
            data = data.concat(tempData);
          });

          data.push({
            content: student.fullName,
            styles: {
              halign: "right",
            },
          });
          data.push({
            content: index,
            styles: {
              halign: "right",
            },
          });
          index += 1;
          array.push(data);
        });

        doc.autoTable({
          theme: "grid",
          startY: 45,
          styles: {
            fontSize: 12,
            font: "Amiri",
            lineColor: [13, 12, 12],
            lineWidth: 0.05,
          },
          headStyles: {
            fontSize: 12,
            font: "Amiri",
            fontStyle: "Bold",
            fillColor: [245, 245, 245], // RGB for light gray
            textColor: [0, 0, 0],
          },
          bodyStyles: {
            fontSize: 12,
          },
          cellPadding: 0,
          body: array,
          head: header,
          margin: { top: 30 },
          didParseCell: function (data) {
            const columnIndex = data.column.index;
            const totalColumns = data.table.columns.length;
            if (data.row.index >= 0 && data.cell.section === "body") {
              const fixedHeight = 13; // Set the fixed row height
              const posY = data.cell.y + fixedHeight; // Calculate the new position for the row
              data.row.height = fixedHeight;
              doc.rect(
                data.cell.x,
                data.cell.y,
                data.cell.width,
                fixedHeight,
                "S"
              ); // Draw the cell manually
            }
            if (data.section == "head") {
              data.cell.styles.font = "Amiri";
              data.cell.styles.fontStyle = "bold";
              data.cell.styles.halign = "center";
            }

            if (columnIndex === 0) {
              data.cell.styles.fillColor = [232, 232, 232]; // RGB for gray
              data.cell.styles.fontStyle = "bold";
            }
            if (columnIndex < totalColumns - 2) {
              data.cell.styles.halign = "center";
            }
            data.cell.styles.valign = "middle";
          },
        });
      });

      this.printLoading = false;

      doc.save(className + " - قائمة أعداد تلاميذ.pdf");
    },

    async printFicheOld(withGrades = false) {
      this.$gtag.event(withGrades ? "Imp fich remplie" : "Imp fich vide", {
        event_category: "Impression PDF",
        event_label: "Bulletin specifique-NotesModules section",
        value: 1,
      });

      this.printLoading = true;
      var doc = new JsPDF({
        orientation: "p",
        unit: "mm",
        format: "a3",
      });
      const pdf_width = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;

      let schoolDetails = this.user.building.find(
        (el) => el.dbName == this.settings.activeBuilding
      );
      let schoolName = schoolDetails.ArabicName
        ? schoolDetails.ArabicName
        : schoolDetails.name;
      let { start, end } = this.settings.schoolarYears.find(
        (el) => el._id == this.settings.activeSchoolarYear
      );

      start = new Date(start).getFullYear();
      end = new Date(end).getFullYear();

      //console.log(this.schoolarYearName);
      let schoolarYearName = end + " / " + start;

      let className = this.classes.find(
        (el) => el._id == this.selectedClasse
      ).name;
      let date = new Date();
      doc.setFontSize(10);
      doc.setFont("Amiri", "Bold");
      doc.setTextColor(140, 140, 140);
      let formattedDate = moment(date)
        .locale("ar-tn")
        .format("dddd، D MMMM، YYYY");
      doc.text(15, 15, formattedDate ? formattedDate : "");

      let width1 = doc.getTextWidth(`: المدرسة `);
      doc.text(pdf_width - width1 - 15, 15, ` : المدرسة `);
      let width2 = doc.getTextWidth(schoolName);
      doc.text(pdf_width - width1 - width2 - 15, 15, schoolName);

      width1 = doc.getTextWidth(`${schoolarYearName} : السنة الدراسية`);
      doc.text(
        pdf_width - width1 - 15,
        20,
        `${schoolarYearName} : السنة الدراسية`
      );

      width1 = doc.getTextWidth(`: القسم `);
      doc.text(pdf_width - width1 - 15, 25, ` : القسم `);
      width2 = doc.getTextWidth(className);

      doc.text(
        pdf_width - width1 - width2 - 15,
        25,
        className ? className : ""
      );

      let first = true;
      this.listModules.forEach((module) => {
        let maxRows = 1;
        const rowsTable = {};
        module.subjects.forEach((subject) => {
          if (subject.divided) {
            if (subject.subsubjectData.length > maxRows)
              maxRows = subject.subsubjectData.length;
          }
        });

        module.subjects.forEach((subject) => {
          rowsTable[subject._id] = [];
          if (!subject.divided) {
            rowsTable[subject._id].push(maxRows);
          } else {
            if (subject.subsubjectData.length < maxRows) {
              let nb = Math.floor(maxRows / subject.subsubjectData.length);
              let total = nb * subject.subsubjectData.length;
              subject.subsubjectData.forEach((subsubject) => {
                rowsTable[subject._id].push(nb);
              });
              rowsTable[subject._id][rowsTable[subject._id].length - 1] =
                maxRows - total + nb;
            } else {
              subject.subsubjectData.forEach((subsubject) => {
                rowsTable[subject._id].push(1);
              });
            }
          }
        });

        if (!first) doc.addPage();
        else first = false;
        doc.setFontSize(14);
        if (/[a-zA-Z]/.test(module.name)) {
          doc.setFont("helvetica", "bold");
        } else {
          doc.setFont("Noto Sans Arabic", "Bold");
        }
        let width = doc.getTextWidth(module.name);
        doc.text(module.name ? module.name : "", pdf_width / 2 - width / 2, 30);

        const array = [];

        this.activeData.forEach((student) => {
          for (let i = 0; i < maxRows; i++) {
            const data = [];
            module.subjects.forEach((subject) => {
              if (rowsTable[subject._id][i]) {
                if (subject.divided) {
                  const subsubject = subject.subsubjectData[i];
                  const mark =
                    this.getMarkValue(
                      subject.subsubjectData[i]._id,
                      student._id,
                      true
                    ) || "";
                  data.push({
                    content: withGrades
                      ? mark + ` :  (${subsubject.maxMark}/)` + subsubject.name
                      : `:  (${subsubject.maxMark}/)` + subsubject.name,
                    rowSpan: rowsTable[subject._id][i],
                  });
                } else {
                  const mark =
                    this.getMarkValue(subject._id, student._id, false) || "";
                  data.push({
                    content: withGrades ? mark : "",
                    rowSpan: rowsTable[subject._id][i],
                  });
                }
              }
            });
            if (i == 0)
              data.push({ content: student.fullName, rowSpan: maxRows });

            array.push(data);
          }
        });

        const columnStyles = {};
        for (let i = 0; i < module.subjects.length; i++) {
          columnStyles[i] = { cellWidth: 140 / module.subjects.length };
        }

        doc.autoTable({
          theme: "grid",
          styles: {
            fontSize: 9,
            font: "Amiri",
          },
          headStyles: {
            fontSize: 9,
            font: "Noto Sans Arabic",
            fontStyle: "Bold",
            lineColor: [204, 204, 204],
            lineWidth: 0.1,
          },
          bodyStyles: {
            fontSize: 9,
          },
          cellPadding: 0,
          columnStyles: columnStyles,
          body: array,
          head: [
            [
              ...module.subjects.map((s) => ({
                content: s.name,
                halign: "center",
              })),
              {
                content: "الإسم و اللقب",
                halign: "center",
                valign: "middle",
                font: "helvetica",
              },
            ],
          ],
          margin: { top: 35 },
          didParseCell: function (data) {
            if (data.section == "head") {
              if (/[a-zA-Z]/.test(data.cell.text[0])) {
                data.cell.styles.font = "helvetica";
                data.cell.styles.fontStyle = "bold";
              } else {
                data.cell.styles.font = "Noto Sans Arabic";
                data.cell.styles.fontStyle = "Bold";
              }
            }
            data.cell.styles.halign = "center";
            data.cell.styles.valign = "middle";
          },
        });
      });

      const pages = doc.internal.getNumberOfPages();

      doc.setFontSize(8);
      doc.setFont("Amiri", "Bold");

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pdf_width / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(` ${pages} / ${j} صفحة`, horizontalPos, verticalPos, {
          align: "center",
        });
      }

      this.printLoading = false;

      if (withGrades) doc.save(className + " - Fiche remplie specifique.pdf");
      else doc.save(className + " - Fiche vide specifique.pdf");
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    unsavedChangesAlert(newValue, type) {
      this.$confirm({
        title: this.$t("all.unsavedchanges"),
        content: this.$t("all.unsavedchangesContent"),
        okText: this.$t("all.oui"),
        okType: "danger",
        cancelText: this.$t("action.annuler"),
        onOk: () => {
          if (type === "classe") {
            this.handleClassChange("classe", newValue);
          } else if (type === "module") {
            this.selectedModule = newValue;
            this.activeModule = this.listModules.find(
              (module) => module._id == newValue
            );
            this.selectedModuleName = this.activeModule.name;
            this.handleModuleChange(newValue);
          } else if (type === "trimester") {
            this.selectedTrimester = newValue;
            this.handleClassChange("classe", this.selectedClasse);
            if (this.selectedModule) this.updateMarks();
          }
          this.updatedChanges = true;
        },
      });
    },
    hasMarksChanged() {
      if (!this.markData || !this.originalMarkData) return false;

      return this.markData.some((currentMark) => {
        const originalMark = this.originalMarkData.find(
          (mark) =>
            mark.student === currentMark.student &&
            mark.subject === currentMark.subject &&
            mark.subsubject === currentMark.subsubject &&
            mark.divided === currentMark.divided
        );

        return !originalMark || originalMark.mark !== currentMark.mark;
      });
    },
  },
};
</script>
<style scoped>
.success .ant-btn-danger {
  background: #8bc34a !important;
  border-color: #8bc34a !important;
}

.ant-form-item {
  margin-bottom: 0px !important;
}

.danger .ant-input-number {
  border-color: #f5222e !important;
}

.danger .ant-input-number-focused {
  border-color: #f5222e !important;
}

.ant-input-number {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-group-addon {
  display: inline-block !important;
  height: 35px !important;
  width: 40px !important;
  padding-top: 5px !important;
  /* color: #a3ba99d2;*/
}
</style>
