<template class="mr-3">
  <div class="card">
    <div class="card-body">
      <div>
        <a-page-header
          :title="$t('menu.listeEnseignants')"
          sub-title="Modifier les accès des enseignants pour faciliter la gestion des notes et du contenu pédagogique dans EdTeacher."
          class="px-0"
        >
          <template slot="tags">
            <a-tag color="blue">
              {{ data.length + " " + $t("scolarite.enseignants") }}
            </a-tag>
          </template>
        </a-page-header>
        <div>
          <label class="fs-5">{{ this.$t("emploi.trimestre") }}</label>
          <a-select
            :placeholder="$t('paiement.selectionnerMois')"
            class="selectWidth mx-3 my-1"
            @change="handleTrimChange"
            default-value="1"
          >
            <a-select-option
              v-for="trimester in ['1', '2', '3']"
              :key="trimester"
              :value="trimester"
            >
              {{ trimester }}
            </a-select-option>
          </a-select>
        </div>
        <a-table
          :pagination="true"
          :data-source="data"
          :loading="tableLoading"
          :columns="columns"
          @change="handleTableChange"
          :scrollToFirstRowOnChange="false"
          rowKey="_id"
        >
          <template slot="avatar" slot-scope="text, record">
            <a-avatar
              size="large"
              v-if="record.photo"
              :src="settings.base_url + '/' + record.photo"
            />
            <a-avatar
              size="large"
              style="color: #f56a00; background-color: #fde3cf"
              v-else
              >{{ record.fullName[0].toUpperCase() }}</a-avatar
            >
          </template>
          <template slot="role" slot-scope="text, record">
            <a-tag
              v-for="r in text"
              :key="record._id + r"
              :color="r == 'teacher' ? 'green' : ''"
            >
              {{
                r == "teacher"
                  ? $t("emploi.enseignant")
                  : "Conseiller pédagogique"
              }}
            </a-tag>
          </template>
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`${$t('personnel.chercher')} ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
              >{{ $t("paiement.chercher") }}</a-button
            >
            <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters)"
              >{{ $t("action.reinitialiser") }}</a-button
            >
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>{{ text }}</template>
          </template>
          <template slot="classeRoom" slot-scope="text, record">
            <a-tag color="blue" v-for="item in record.access" :key="item._id">
              {{ item.classeRoom.name }}
            </a-tag>
          </template>

          <accessDetailsRow
            slot="expandedRowRender"
            slot-scope="record"
            :access="record.access"
            :key="record._id"
          />
          <template slot="operation" slot-scope="text, record">
            <drawer-add-access
              :key="record._id"
              :selectedTeacher="record._id"
              :classes="classroomAffectationProfile"
              :selectedTrimester="selectedTrimester"
              :listSubjects="subjectsList"
              @reload="getTeacherSubAccess"
              class="float-right"
            />
          </template>
          <template slot="duplicate" slot-scope="text, record">
            <modal-duplicate-access
              :key="record._id"
              :selectedTeacher="record._id"
              :classes="classroomAffectationProfile"
              :selectedTrimester="selectedTrimester"
              :listSubjects="subjectsList"
              @reload="getTeacherSubAccess"
              class="float-right"
            />
          </template>
        </a-table>
      </div>
    </div>
  </div>
  <!--les conseillées pédagogiques-->
</template>

<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import { mapState } from "vuex";
import moment from "moment";
import drawerAddAccess from "./components/drawerAddAccess";
import accessDetailsRow from "./components/accessDetailsRow";
import modalDuplicateAccess from "./components/modalDuplicateAccess";

export default {
  computed: mapState(["settings", "user"]),
  components: { drawerAddAccess, accessDetailsRow, modalDuplicateAccess },
  async created() {
    this.getTeacherSubAccess();

    // Get subject List
    apiClient
      .post("/subject/filter", { query: { status: "active" } })
      .then((res) => {
        this.subjectsList = res.data.map((elem) => ({
          ...elem,
          key: elem._id,
        }));
      })
      .catch(() => {});
  },
  data() {
    return {
      selectedTrimester: "1",

      profileDataLoading: false,
      classroomAffectationProfile: [],
      subjectsList: [],
      dateFormat: "DD/MM/YYYY",
      editingKey: "",
      tableLoading: true,
      visibleParentFile: false,
      data: [],
      filteredData: [],
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      targetKeys: [],
      previewVisible: false,
      previewImage: "",
      form: this.$form.createForm(this),
      updateForm: this.$form.createForm(this),
      value: 1,
      visibleData: false,
      columns: [
        {
          title: this.$t("enseignant.photo"),
          dataIndex: "photo",
          key: "avatar",
          width: "auto",
          scopedSlots: {
            customRender: "avatar",
          },
        },
        {
          title: this.$t("profil.fullname"),
          dataIndex: "fullName",
          key: "fullName",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "fullName",
          },
          onFilter: (value, record) =>
            record.fullName.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("utilisateur.role"),
          dataIndex: "role",
          key: "role",
          width: "auto",
          scopedSlots: {
            customRender: "role",
          },
        },
        {
          title: this.$t("bulletin.listeClasses"),
          dataIndex: "classeRoom",
          key: "classeRoom",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "classeRoom",
          },
          onFilter: (value, record) =>
            record.access.some((s) => {
              return s.classeRoom.name
                .toLowerCase()
                .includes(value.toLowerCase());
            }),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("action.modifier"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
        {
          title: "Dupliquer",
          dataIndex: "duplicate",
          scopedSlots: { customRender: "duplicate" },
        },
      ],
      selectedTeacher: "",
      GeneratedPassword: Math.random().toString(36).slice(2, 10),
    };
  },
  methods: {
    handleTrimChange(val) {
      this.selectedTrimester = val;
      this.getTeacherSubAccess();
    },
    getTeacherSubAccess() {
      this.tableLoading = true;
      apiClient
        .get("/teacherAccess/" + this.selectedTrimester)
        .then((res) => {
          this.data = res.data.map((elem) => ({ ...elem, key: elem._id }));
          this.filteredData = [...this.data];
        })
        .catch((e) => {
          this.$message.error(this.$t("error.aucEnseignant"));
          console.log(e);
        })
        .finally(() => (this.tableLoading = false));
    },
    handleTableChange(pagination, filters, sorter, extra) {
      this.filteredData = extra.currentDataSource;
    },
    moment,

    handleCancel(e = null) {
      this.form.resetFields();
      this.visibleData = false;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    showModal() {
      this.visibleData = true;
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
<style scoped>
.selectWidth {
  width: 70px;
}
</style>
