/*eslint-disable */
//ANCHOR class/month filter to this view: DONE

import apiClient from "@/services/axios";
import { mapState } from "vuex";
import "jspdf-autotable";
import { notification } from "ant-design-vue";
import moment from "moment";
import FinalBulletin from "../components/FinalBulletin.vue";
import AllFinalBulletin from "../components/AllFinalBulletin.vue";
import stats from "../components/stats.vue";
const pvPrintHelper = require("./bulletinHeplers/pv").default;

import MP from "./bulletinHeplers/peda";
import MT from "./bulletinHeplers/specPilote";
import MS from "./bulletinHeplers/specPilote";

const bulletinHeplers = { MP, MT, MS };
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    subModuleList: {
      type: Array,
      required: true,
    },
  },
  components: { FinalBulletin, AllFinalBulletin, stats },
  computed: {
    ...mapState(["settings", "user"]),
    schoolAccessTemplateID() {
      //specefic template are only available in bulletin specific
      if (this.type !== "MS") return "";
      const accessTemplates = [
        { id: "MS", dbName: "MalekEssaghir" },
        { id: "MP", dbName: "mondialPrimaire" },
        { id: "AC", dbName: "arcEnCiel" },
        { id: "CP", dbName: "coupolePrimaire" }, //Coupole EDWEB-1155
        { id: "CP", dbName: "coupoleLycee" }, //Coupole EDWEB-1155
        { id: "EV", dbName: "everestAcademy" }, //Everest EDWEB-1166
        { id: "CP", dbName: "learnToFly" }, //learntofly EDWEB-1170
        { id: "PP", dbName: "pythagorePrimaire" }, //Pythagore Primaire EDWEB-1169
        { id: "HC", dbName: "harmonySchool" }, //harmonySchool EDWEB-1180
        { id: "MP", dbName: "school1TEST" },
      ];
      let templateId = accessTemplates.find(
        (e) => e.dbName == this.schoolDetails.dbName
      );
      templateId = templateId ? templateId.id : "";
      //return "PP";
      return templateId;
    },
    groupBulletin() {
      return this.menuItems.filter(
        (item) => item.group === "g1" && (!item.condition || item.condition())
      );
    },
    groupAnnuel() {
      return this.menuItems.filter(
        (item) => item.group === "g2" && (!item.condition || item.condition())
      );
    },
    groupPV() {
      return this.menuItems.filter(
        (item) => item.group === "g5" && (!item.condition || item.condition())
      );
    },
    groupPVAnnuel() {
      return this.menuItems.filter(
        (item) => item.group === "g3" && (!item.condition || item.condition())
      );
    },
    groupWithTemplate() {
      return this.menuItems.filter(
        (item) => item.group === "g4" && (!item.condition || item.condition())
      );
    },
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.confirmLeaveReload);
  },
  async created() {
    window.addEventListener("beforeunload", this.confirmLeaveReload);
    this.tableLoading = true;
    let project = {
      _id: 1,
      name: 1,
      level: 1,
    };
    project[this.bulletinType(2)] = 1;
    await apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: project,
        },
      })
      .then((res) => (this.classes = res.data));

    apiClient
      .get(`/certification/${this.type}`)
      .then((res) => {
        this.certifParams = res.data;
      })
      .catch((e) => {
        console.log(e);
      });
    this.tableLoading = false;
    this.filtredTable = this.activeData;

    this.schoolDetails = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0];

    let { start, end } = this.settings.schoolarYears.find(
      (el) => el._id == this.settings.activeSchoolarYear
    );

    start = new Date(start).getFullYear();
    end = new Date(end).getFullYear();

    this.schoolarYearName = end + " / " + start;

    this.schoolType = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0].type;
    if (!this.schoolType)
      notification.warning({
        message: this.$t("bulletin.typeIntrouvable"),
        description: this.$t("bulletin.saisirSchoolType"),
      });

    this.getIncompleteMarks();
  },

  data() {
    return {
      loadingBulletin: false,
      listModules: [],
      bulletin: {},
      externalSubjectList: [],
      studentLoading: false,
      visibleBulletinDate: false,
      bulletinDate: new Date(),
      updatedChanges: true,
      printLoading: false,
      syncLoading: false,
      syncSubjectLoading: false,
      visibleClassnameList: false,
      visibleSubjectList: false,
      visibleFinalBulletin: false,
      visibleAllFinalBulletin: false,
      schoolType: null,
      filtredTable: [],
      incompleteMarksStats: {},
      selectedStudent: "",
      selectedStudentName: "",
      bulletins: { students: [] },
      selectedClassName: "",
      markData: [],
      originalMarkData: [],
      activeModule: null,
      bulletinData: [],
      certifParams: [],
      moduleLoading: false,
      selectedTrimester: "1",
      selectedClasse: null,
      tableLoading: false,
      saveLoading: false,
      activeData: [],
      markRefs: [],
      filters: {
        classe: false,
        classe_val: null,
        month: false,
        month_val: null,
      },
      rowData: [],
      data1: null,
      classes: [],
      editable: false,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      columns: [],
      subsubjectData: [],
      subjectList: [],
      selectedLevel: null,
      schoolDetails: {},
      schoolarYearName: "",
      usePersonalizedAverage: false,
      personalized: false,

      menuItems: [
        {
          key: "1",
          label: "bulletin.imprimerBulletin",
          group: "g1",
          action: "bulletinSwitcher",
          params: ["1"],
          condition: () => this.selectedStudent,
        },
        {
          key: "2",
          label: "bulletin.imprimerTout",
          group: "g1",
          action: "bulletinSwitcher",
          params: ["2"],
        },
        {
          key: "3",
          label: "Imprimer bulletin",
          group: "g2",
          action: "openFinalBulletin",
          condition: () => this.selectedStudent,
        },
        {
          key: "4",
          label: "Imprimer tout",
          group: "g2",
          action: "openAllFinalBulletin",
        },
        {
          key: "5",
          label: "Imprimer PV",
          group: "g3",
          action: "printPVAnnuel",
        },
        {
          key: "6",
          label: "Imprimer PV par niveau",
          group: "g3",
          action: "printPVAnnuelByLevel",
        },
        {
          key: "7",
          label: "bulletin.imprimerBulletin",
          group: "g4",
          action: "bulletinSwitcher",
          params: ["1", () => this.schoolAccessTemplateID],
          condition: () => this.selectedStudent,
        },
        {
          key: "8",
          label: "bulletin.imprimerTout",
          group: "g4",
          action: "bulletinSwitcher",
          params: ["2", () => this.schoolAccessTemplateID],
        },
        {
          key: "9",
          label: "Imprimer PV",
          group: "g5",
          action: "printPvHandler",
          params: ["1"],
        },
        {
          key: "10",
          label: "Imprimer PV par niveau",
          group: "g5",
          action: "printPvHandler",
          params: ["2"],
        },
      ],
      selectedPrintKey: null,
      showStamp: true,
    };
  },

  beforeRouteLeave(to, from, next) {
    const _this = this;

    if (this.updatedChanges) next();
    else
      this.$confirm({
        title: this.$t("all.unsavedchanges"),
        content: this.$t("all.unsavedchangesContent"),
        okText: this.$t("all.oui"),
        okType: "danger",
        cancelText: this.$t("action.annuler"),
        onOk() {
          _this.updatedChanges = true;
          next();
        },
        onCancel() {
          next(false);
        },
      });
  },
  methods: {
    //1st call on btn click
    async prePrintPdfHandler(key) {
      this.selectedPrintKey = key;

      this.$gtag.event("Imp bulletin", {
        event_category: "Impression PDF",
        event_label: "Bulletin peda-NotesEleves section",
        value: 1,
      });

      this.printLoading = true;
      this.visibleBulletinDate = true;
    },
    //2nd call on btn click
    handleOkDateModal() {
      this.visibleBulletinDate = false;
      this.pdfFunctionSelector(this.selectedPrintKey);
    },
    handleCancelDateModal() {
      this.visibleBulletinDate = false;
      this.printLoading = false;
      this.showStamp = true;
    },
    //3rd call on btn click
    pdfFunctionSelector(key) {
      const menuItem = this.menuItems.find((item) => item.key === key);
      if (menuItem && typeof this[menuItem.action] === "function") {
        const params = (menuItem.params || []).map((param) =>
          typeof param === "function" ? param() : param
        );
        this[menuItem.action](...params);
      }
    },
    async bulletinSwitcher(key, templateId = false) {
      let config = {
        selectedLevel: this.selectedLevel,
        schoolDetails: this.schoolDetails,
        selectedStudent: this.selectedStudent,
        bulletinDate: this.bulletinDate,
        certifs: this.certifParams,
        selectedClassName: this.selectedClassName,
        schoolarYearName: this.schoolarYearName,
        type: this.bulletinType(1),
        bulletinType: this.type,
        selectedTrimester: this.selectedTrimester,
        selectedStudentName: this.selectedStudentName,
        settings: this.settings,
        selectedClasse: this.selectedClasse,
        studentsData: this.rowData,
        showStamp: this.showStamp,
      };
      this.printLoading = true;
      try {
        switch (key) {
          case "1":
            await bulletinHeplers[this.type].prePrintBulletin(
              templateId,
              config,
              this
            );
            break;
          case "2":
            let missingStudent = [];
            try {
              missingStudent = this.incompleteMarksStats.students
                ? this.incompleteMarksStats.students[this.selectedClasse]
                : [];
            } catch (error) {
              console.error(error);
            }
            await bulletinHeplers[this.type].prePrintAllBulletin(
              templateId,
              config,
              this,
              missingStudent
            );
            break;
        }
      } catch (error) {
        console.error(error);
        this.$message.error(this.$t("error.erreur"));
      } finally {
        this.printLoading = false;
      }
    },
    fetchAffichageBulletin() {
      if (!this.selectedLevel) return;

      return apiClient
        .post("/affichageBulletin/filter", {
          query: {
            level: this.selectedLevel,
            type: this.type === "MS" ? "verySpecific" : this.type,
          },
        })
        .then((response) => {
          this.personalized = response.data[0].personalized;
        });
    },

    async printPvHandler(which) {
      let config = {
        selectedLevel: this.selectedLevel,
        schoolDetails: this.schoolDetails,
        selectedStudent: this.selectedStudent,
        bulletinDate: this.bulletinDate,
        certifs: this.certifParams,
        selectedClassName: this.selectedClassName,
        schoolarYearName: this.schoolarYearName,
        type: this.bulletinType(1),
        bulletinType: this.type,
        selectedTrimester: this.selectedTrimester,
        selectedStudentName: this.selectedStudentName,
        settings: this.settings,
        selectedClasse: this.selectedClasse,
        usePersonalizedAverage:
          this.type === "MS" ? this.usePersonalizedAverage : false,
      };
      this.printLoading = true;

      try {
        switch (which) {
          case "1":
            this.$gtag.event("Imp PV Bulletin", {
              event_category: "Impression PDF",
              event_label: "Bulletin peda-NotesEleves section",
              value: 1,
            });
            await pvPrintHelper.printPVBulletin(config);
            break;
          case "2":
            this.$gtag.event("Imp PV Bulletin by level", {
              event_category: "Impression PDF",
              event_label: "Bulletin peda-NotesEleves section",
              value: 1,
            });
            await pvPrintHelper.printPVBulletinLevel(config);
            break;
        }
      } catch (error) {
        console.error(error);
        this.$message.error(this.$t("error.erreur"));
      } finally {
        this.printLoading = false;

        this.usePersonalizedAverage = false;
      }
    },
    bulletinType: function (select) {
      switch (this.type) {
        case "MP":
          switch (select) {
            case 1:
              return "peda";
            case 2:
              return "bulletin";
          }
        case "MT":
          switch (select) {
            case 1:
              return "specific";
            case 2:
              return "bulletinSpec";
          }
        case "MS":
          switch (select) {
            case 1:
              return "verySpecific";
            case 2:
              return "bulletinVerySpec";
          }
      }
    },
    async getIncompleteMarks() {
      this.printLoading = true;
      apiClient
        .get(
          "/bulletin/v2/stats/incompleteMarks/" +
            this.type +
            "/" +
            this.selectedTrimester
        )
        .then(({ data }) => {
          this.incompleteMarksStats = data;
        })
        .finally(() => (this.printLoading = false));
    },
    bulletinTypeText: function (type) {
      switch (type) {
        case "MP":
          return this.$t("bulletin.bulletinPedagogique");
        case "MT":
          return this.$t("bulletin.bulletinSpecifique");
        case "MS":
          return this.$t("bulletin.bulletinVerySpecifique");
      }
    },
    async updateBulletin() {
      this.tableLoading = true;
      this.loadingBulletin = true;
      apiClient
        .get(
          `/bulletin/v2/bt/${this.type}/${this.selectedStudent}/${this.selectedTrimester}`
        )
        .then(({ data }) => {
          this.bulletin = data;
          this.loadingBulletin = false;
        })
        .catch(() => console.error("failed to load bulletin for stats"))
        .finally(() => (this.tableLoading = false));
    },
    getSelectedGroup(key) {
      const menuItem = this.menuItems.find((item) => item.key === key);
      return menuItem ? menuItem.group : null;
    },
    async updateCertifParams() {
      await apiClient
        .get("/certification/" + this.type == "MP" ? "peda" : "spec")
        .then((res) => {
          this.certifParams = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    circularColorPicker(submoduleId) {
      const submodulesIDs = this.subModuleList.map((sm) => sm._id);
      const colors = [
        "cyan",
        "red",
        "orange",
        "green",
        "blue",
        "purple",
        "pink",
      ];
      const nbColors = colors.length;
      return colors[submodulesIDs.indexOf(submoduleId) % nbColors];
    },
    confirmLeaveReload(event) {
      if (!this.updatedChanges) {
        event.returnValue = this.$t("all.unsavedchangesContent");
      }
    },
    async openFinalBulletin() {
      this.$gtag.event("Imp allBulletinAnnuel", {
        event_category: "Impression PDF",
        event_label: "Bulletin specific-NotesEleves section",
        value: 1,
      });

      this.visibleFinalBulletin = true;
    },
    async openAllFinalBulletin() {
      this.$gtag.event("Imp BulletinAnnuel", {
        event_category: "Impression PDF",
        event_label: "Bulletin specific-NotesEleves section",
        value: 1,
      });

      this.visibleAllFinalBulletin = true;
    },
    closeFinalBulletin() {
      this.visibleFinalBulletin = false;
    },
    closeAllFinalBulletin() {
      this.visibleAllFinalBulletin = false;
    },
    setPrintLoading(val) {
      this.printLoading = val;
    },
    moment,
    confirmeBulletinValidation() {
      const content = this.isValidatedBulletin()
        ? this.$t("bulletin.hideBulletin")
        : this.$t("bulletin.validateBulletin");
      this.$confirm({
        title: this.$t("all.sureTo"),
        content: (h) => <div style="color:red"> {content} </div>,
        onOk: () => {
          //console.log("OK");
          this.validateBulletin();
        },
        onCancel: () => {
          //console.log("Cancel");
        },
        class: "test",
      });
    },
    isValidatedBulletin() {
      // DONE
      if (this.selectedClasse) {
        const classsroom = this.classes.find(
          (item) => item._id === this.selectedClasse
        );
        const bulletin = classsroom
          ? classsroom[this.bulletinType(2)]
          : { sem1: false, sem2: false, sem3: false };
        if (this.selectedTrimester == 1 && bulletin.sem1 == true) return true;
        else if (this.selectedTrimester == 2 && bulletin.sem2 == true)
          return true;
        else if (this.selectedTrimester == 3 && bulletin.sem3 == true)
          return true;
      }
      return false;
    },
    validateBulletin() {
      // DONE
      const isValidated = this.isValidatedBulletin();
      const classsroom = this.classes.find(
        (item) => item._id === this.selectedClasse
      );
      const bulletin = classsroom
        ? classsroom[this.bulletinType(2)]
        : { sem1: false, sem2: false, sem3: false };
      if (this.selectedTrimester == 1) bulletin.sem1 = !bulletin.sem1;
      if (this.selectedTrimester == 2) bulletin.sem2 = !bulletin.sem2;
      if (this.selectedTrimester == 3) bulletin.sem3 = !bulletin.sem3;

      const bulletinField =
        this.type === "MP"
          ? "bulletin"
          : this.type === "MT"
          ? "bulletinSpec"
          : "bulletinVerySpec";
      apiClient
        .patch("/classrooms/" + this.selectedClasse, {
          data: {
            [bulletinField]: bulletin,
          },
          bulletin: {
            classroom: this.selectedClasse,
            trimester: this.selectedTrimester,
            avaliable: !isValidated,
            type: this.bulletinType(2),
          },
        })
        .then(() => {
          this.classes = this.classes.map((item) => {
            if (item._id === this.selectedClasse)
              item[this.bulletinType(2)] = bulletin;
            return item;
          });

          if (!isValidated)
            this.$message.success(
              this.$t("bulletin.validated", { name: this.selectedClassName })
            );
          else
            this.$message.success(
              this.$t("bulletin.unvalidated", { name: this.selectedClassName })
            );
        });
    },
    async sleep(timeout) {
      await new Promise((r) => setTimeout(r, timeout));
    },
    async chooseBulletinDate() {
      this.visibleBulletinDate = true;
      return new Promise(async (res) => {
        while (this.visibleBulletinDate) {
          await this.sleep(500);
        }
        res();
      });
    },
    async getModules(level) {
      await apiClient
        .post("/bulletin/v2/module/filter/marks", {
          status: "active",
          level: level,
          trimester: this.selectedTrimester,
          type: this.type,
        })
        .then((res) => {
          this.subjectsLoading = true;

          if (res.data.length == 0)
            return this.$message.warning(this.$t("error.aucModule"));

          this.subjectList = [];
          this.listModules = res.data;
          res.data.forEach((moduleItem) => {
            moduleItem.subjects.forEach((subject) => {
              const subjectsInfo = moduleItem.subjectsInfo;
              const subsubjects = subjectsInfo[subject._id];
              subject.moduleName = moduleItem.name;
              if (subsubjects && subsubjects.sousModule)
                subject.subModule = this.subModuleList.find(
                  (sm) => String(sm._id) == String(subsubjects.sousModule)
                );
              this.subjectList.push(subject);
            });
          });
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(this.$t("error.erreur"));
          this.moduleLoading = false;
        });
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    async saveAll() {
      this.saveLoading = true;

      // ! DEPRECATED DWEB-969
      // check if subsubject marks are completed
      //for (const subject of this.subjectList) {
      //  if (subject.divided) {
      //    const subsubjects = subject.subsubjectData.map((s) => String(s._id));
      //    const marks = this.markData.filter((m) => {
      //      return (
      //        m.student == this.selectedStudent &&
      //        m.divided &&
      //        subsubjects.includes(m.subsubject)
      //      );
      //    });

      //    if (marks.length != 0 && marks.length != subsubjects.length) {
      //      this.saveLoading = false;
      //      return this.$message.warning(
      //        this.$t("warning.missedSubsubjects", {
      //          name: subject.name,
      //        })
      //      );
      //    }
      //  }
      //}

      // check if marks are correct
      let thereError = false;
      for (const mark of this.markData) {
        if (!mark.divided) {
          if (isNaN(Number(mark.mark)) || mark.mark > 20 || mark.mark < 0) {
            thereError = true;
            break;
          }
        } else if (mark.divided) {
          let subsubject;
          for (const subject of this.subjectList) {
            if (subject.divided) {
              subsubject = subject.subsubjectData.find(
                (s) => s._id == mark.subsubject
              );
              if (subsubject) break;
            }
          }

          if (subsubject)
            if (
              isNaN(Number(mark.mark)) ||
              mark.mark > subsubject.maxMark ||
              mark.mark < 0
            ) {
              thereError = true;
              break;
            }
        }
      }

      if (thereError) {
        this.$message.warning(this.$t("warning.notCorrectMarks"));
        this.saveLoading = false;
        return;
      }

      // saving
      const toAddData = [];

      for (const mark of this.markData) {
        const originalMark = this.originalMarkData.find(
          (m) =>
            m.student == mark.student &&
            ((!m.divided && m.subject == mark.subject) ||
              (m.divided && m.subsubject == mark.subsubject))
        );
        if (originalMark) {
          if (Number(mark.mark) != Number(originalMark.mark))
            await apiClient
              .patch("/bulletin/v2/mark/" + originalMark._id, {
                data: {
                  mark: mark.mark,
                },
              })
              .then()
              .catch((err) => console.log(err));
        } else {
          const obj = {
            divided: mark.divided,
            student: mark.student,
            mark: mark.mark,
            trimester: this.selectedTrimester,
            classroom: this.selectedClasse,
            subject: mark.subject,
            type: "one",
          };
          if (mark.divided) obj.subsubject = mark.subsubject;
          toAddData.push(obj);
        }
      }

      await apiClient
        .put("/bulletin/v2/mark/many", {
          data: toAddData,
        })
        .then()
        .catch((err) => console.log(err));

      for (const originalMark of this.originalMarkData) {
        const mark = this.markData.find(
          (m) =>
            m.student == originalMark.student &&
            ((!m.divided && m.subject == originalMark.subject) ||
              (m.divided && m.subsubject == originalMark.subsubject))
        );
        if (!mark)
          await apiClient
            .delete("/bulletin/v2/mark/" + originalMark._id)
            .then()
            .catch((err) => console.log(err));
      }

      await this.updateMarks();
      this.saveLoading = false;
      this.updatedChanges = true;
      this.getIncompleteMarks();
      this.$message.success(this.$t("success.noteToutesEnregistre"));
    },

    deleteMark(id, studentId, divided) {
      this.markData = this.markData.filter(
        (m) =>
          !(
            m.student == studentId &&
            m.divided == divided &&
            (m.subsubject == id || m.subject == id)
          )
      );
      this.updatedChanges = false;
    },

    handleMarkChange(newMark, subjectId, subSubjectId, studentId, divided) {
      const mark = this.markData.find(
        (m) =>
          m.student == studentId &&
          ((divided && m.subsubject == subSubjectId) ||
            (!divided && m.subject == subjectId))
      );
      if (mark) mark.mark = Number(newMark);
      else {
        const obj = {
          student: studentId,
          mark: Number(newMark),
          divided: divided,
          subject: subjectId,
        };
        if (divided) obj.subsubject = subSubjectId;

        this.markData.push(obj);
      }

      this.updatedChanges = false;
    },

    getMarkValue(id, studentId, divided) {
      const mark = this.markData.find(
        (m) =>
          m.student == studentId &&
          m.divided == divided &&
          (m.subsubject == id || m.subject == id)
      );
      if (mark) return mark.mark;
      return null;
    },

    getTotalSubsubjects(record) {
      try {
        const marks = this.markData.filter((mark) => {
          return (
            mark.divided &&
            mark.subject == record._id &&
            record.subsubjectData.map((ss) => ss._id).includes(mark.subsubject)
          );
        });
        const subject = this.subjectList.find((s) => s._id == record._id);

        let total = 0;
        let subSubjectTotalCoefs = 0;
        marks.forEach((mark) => {
          let subsubjectData = subject.subsubjectData.find(
            (s) => s._id == mark.subsubject
          );
          subSubjectTotalCoefs += subsubjectData.coefMark;
          total +=
            (mark.mark * (subsubjectData.coefMark ?? 1)) /
            subsubjectData.maxMark;
        });
        return total
          ? ((total * 20) / (subSubjectTotalCoefs ?? 1)).toFixed(2)
          : "";
      } catch (error) {
        console.log(error);
      }
    },
    async handleStudentChange(val) {
      this.columns = [
        {
          title: this.$t("bulletin.matieres"),
          dataIndex: "name",
          key: "name",
          scopedSlots: {
            customRender: "name",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("bulletin.note"),
          dataIndex: "note",
          scopedSlots: { customRender: "note" },
        },
      ];

      this.updateMarks();
    },

    async updateMarks() {
      this.tableLoading = true;
      await apiClient
        .post("/bulletin/v2/mark/filter", {
          query: {
            classroom: this.selectedClasse,
            trimester: this.selectedTrimester,
            student: this.selectedStudent,
            schoolarYear: this.settings.activeSchoolarYear,
            type: {
              $in: ["one"],
            },
          },
        })
        .then(({ data }) => {
          this.markData = [];
          this.originalMarkData = [];
          this.originalMarkData = data;
          data.map((mark) => {
            this.markData.push({
              student: mark.student,
              subject: mark.subject,
              subsubject: mark.subsubject,
              mark: mark.mark,
              divided: mark.divided,
            });
          });
        })
        .finally(() => (this.tableLoading = false));
      await this.updateBulletin();
    },

    async handleClassChange(on, value) {
      this.studentLoading = true;
      this.selectedStudent = "";
      this.markRefs = [];
      this.moduleLoading = true;
      this.selectedClasse = value;
      this.selectedClassName = this.classes.find(
        (item) => item._id === value
      ).name;
      this.selectedLevel = this.classes.find(
        (item) => item._id === value
      ).level;
      let match = {};
      match[`schoolarYearsHistory.${this.settings.activeSchoolarYear}`] = value;
      await apiClient
        .post("/students/filter", {
          query: { status: "active" },
          aggregation: [
            {
              $match: match,
            },
            {
              $set: {
                classRoom: {
                  $convert: {
                    input: `$schoolarYearsHistory.${this.settings.activeSchoolarYear}`,
                    to: "objectId",
                    onError: null,
                    onNull: null,
                  },
                },
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classRoom",
                foreignField: "_id",
                as: "classroom",
              },
            },
            {
              $project: {
                _id: 1,
                firstName: 1,
                lastName: 1,
                gender: 1,
                firstNameLower: { $toLower: "$firstName" },
                photo: 1,
                classroom: {
                  _id: 1,
                  name: 1,
                },
              },
            },
            {
              $sort: {
                firstNameLower: 1,
              },
            },
          ],
        })
        .then(({ data }) => {
          this.rowData = [];
          data.map((elem) => {
            try {
              this.rowData.push({
                _id: elem._id,
                key: elem._id,
                fullName: elem.firstName + " " + elem.lastName,
                classroom: elem.classroom[0].name,
                classroom_id: elem.classroom[0]._id,
                gender: elem.gender,
                photo: elem.photo,
              });
            } catch {}
          });
          this.activeData = this.rowData;
          this.tableLoading = false;
          this.filtredTable = this.activeData;
          this.studentLoading = false;
        });
      //get modules
      this.getModules(this.selectedLevel);
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    unsavedChangesAlert(newValue, type) {
      this.$confirm({
        title: this.$t("all.unsavedchanges"),
        content: this.$t("all.unsavedchangesContent"),
        okText: this.$t("all.oui"),
        okType: "danger",
        cancelText: this.$t("action.annuler"),
        onOk: () => {
          if (type === "classe") {
            this.handleClassChange("classe", newValue);
          } else if (type === "student") {
            this.selectedStudent = newValue;
            this.selectedStudentName = this.activeData.find(
              (s) => s._id == newValue
            ).fullName;
            this.handleStudentChange(newValue);
          } else if (type === "trimester") {
            this.selectedTrimester = newValue;
            this.handleClassChange("classe", this.selectedClasse);
            if (this.selectedStudent) this.updateMarks();
          }
          this.updatedChanges = true;
        },
      });
    },
    hasMarksChanged() {
      if (!this.markData || !this.originalMarkData) return false;

      return this.markData.some((currentMark) => {
        const originalMark = this.originalMarkData.find(
          (mark) =>
            mark.student === currentMark.student &&
            mark.subject === currentMark.subject &&
            mark.subsubject === currentMark.subsubject &&
            mark.divided === currentMark.divided
        );

        return !originalMark || originalMark.mark !== currentMark.mark;
      });
    },
  },
  watch: {
    selectedLevel: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.fetchAffichageBulletin();
        }
      },
    },
  },
};
