<template>
  <div class="card">
    <a-modal v-model="visibleModal" @cancel="closeModal" :footer="false">
      <a-form :form="form" @submit="updateBuildingLogo">
        <a-form-item>
          <a-upload-dragger
            class="row"
            v-decorator="[
              'photo',
              {
                rules: [
                  {
                    required: true,
                    message: 'Ajouter le neauvau logo!',
                  },
                ],
              },
            ]"
            v-if="visibleModal"
            :multiple="false"
            :before-upload="beforeUpload"
            :file-list="fileList"
            :customRequest="addTofileList"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="file-image" />
            </p>
            <p class="ant-upload-text">
              {{ $t("etablissement.clickOrDragToUpload") }}
            </p>
          </a-upload-dragger>
        </a-form-item>
        <div class="form-actions mt-0 row">
          <a-button @click="closeModal" class="col-md-3 offset-md-2 mx-3">
            {{ $t("all.cancel") }}
          </a-button>
          <a-button
            style="col-md-4 offset-md-1"
            type="primary"
            class="mr-3"
            htmlType="submit"
            >{{ $t("all.save") }}</a-button
          >
        </div>
      </a-form>
    </a-modal>
    <a-modal
      :dialog-style="{ top: '10px' }"
      v-if="selectedBuilding"
      v-model="visibleAccessModal"
      @cancel="closeAccessModal"
      :footer="false"
      :title="$t('etablissement.editingAccess')"
      :width="1000"
    >
      <a-form :form="accessForm" @submit="updateBuildingAccess">
        <a-divider class="mt-0">EdManager</a-divider>
        <div class="row">
          <div
            class="col-md-4"
            v-for="item in accessData.edManager"
            :key="item.key"
          >
            <a-form-item class="mt-0 mb-0">
              <a-switch
                class="switch"
                v-decorator="[
                  'edManager.' + item.key,
                  {
                    valuePropName: 'checked',
                    initialValue: selectedBuilding.access.edManager[item.key],
                  },
                ]"
              />
              <label style="margin-left: 10px"
                ><i :class="item.icon"></i> {{ $t(item.lang) }}
              </label>
            </a-form-item>
          </div>
        </div>
        <a-divider class="mt-0">EdParent</a-divider>
        <div class="row">
          <div
            class="col-md-4"
            v-for="item in accessData.edParent"
            :key="item.key"
          >
            <a-form-item class="mt-0 mb-0">
              <a-switch
                class="switch"
                v-decorator="[
                  'edParent.' + item.key,
                  {
                    valuePropName: 'checked',
                    initialValue: selectedBuilding.access.edParent[item.key],
                  },
                ]"
              />
              <label style="margin-left: 10px"
                ><i :class="item.icon"></i> {{ $t(item.lang) }}
              </label>
            </a-form-item>
          </div>
        </div>
        <a-divider class="mt-0">EdLearning</a-divider>
        <div class="row">
          <div
            class="col-md-4"
            v-for="item in accessData.edLearning"
            :key="item.key"
          >
            <a-form-item class="mt-0 mb-0">
              <a-switch
                class="switch"
                v-decorator="[
                  'edLearning.' + item.key,
                  {
                    valuePropName: 'checked',
                    initialValue: selectedBuilding.access.edLearning[item.key],
                  },
                ]"
              />
              <label style="margin-left: 10px"
                ><i :class="item.icon"></i> {{ $t(item.lang) }}
              </label>
            </a-form-item>
          </div>
        </div>
        <div class="form-actions mb-0 row">
          <a-button @click="closeAccessModal" class="mx-3" style="float: right">
            {{ $t("all.cancel") }}
          </a-button>
          <a-button
            type="primary"
            class="mr-3"
            style="float: right"
            :loading="loadingACUpdate"
            :disabled="loadingACUpdate"
            htmlType="submit"
            >{{ $t("all.save") }}</a-button
          >
        </div>
      </a-form>
    </a-modal>
    <a-modal
      :dialog-style="{ top: '10px' }"
      v-if="selectedBuilding"
      v-model="visibleConfigModal"
      @cancel="closeAccessModal"
      :footer="false"
      :title="$t('etablissement.editConfig')"
      :width="700"
    >
      <a-form
        :form="configForm"
        @submit="updateBuildingConfig"
        :key="selectedBuilding._id"
      >
        <div class="row">
          <div class="col-md-12" key="teacherParentChat">
            <a-form-item class="mt-0 mb-0">
              <a-alert
                :message="$t('etablissement.alertDisableChat')"
                banner
                type="info"
                show-icon
                closable
              />

              <a-switch
                class="switch"
                v-model="selectedBuilding.config.teacherParentChat"
              >
                <a-icon slot="checkedChildren" type="check" />
                <a-icon slot="unCheckedChildren" type="close" />
              </a-switch>
              <label style="margin-left: 10px"
                ><i class="fa fa-weixin"></i> {{ $t("menu.ptchat") }}
              </label>
            </a-form-item>
          </div>
        </div>
        <div class="form-actions mb-0 row">
          <a-button @click="closeAccessModal" class="mx-3" style="float: right">
            {{ $t("all.cancel") }}
          </a-button>
          <a-button
            type="primary"
            class="mr-3"
            style="float: right"
            :loading="loadingACUpdate"
            :disabled="loadingACUpdate"
            htmlType="submit"
            >{{ $t("all.save") }}</a-button
          >
        </div>
      </a-form>
    </a-modal>
    <a-table
      :loading="tableLeading"
      :rowKey="'_id'"
      :columns="columns"
      :data-source="data"
      :pagination="true"
      :scroll="{ x: 'max-content' }"
    >
      <template slot="logoUpload" slot-scope="text, record">
        <a-popover
          :title="$t('etablissement.clickForUploadLogo')"
          trigger="hover"
        >
          <a-avatar
            v-if="record.base_url"
            class="building-logo"
            :src="record.base_url + '/' + record.logo"
            :size="64"
            icon="user"
            @click="changeImage(record)"
          />
          <a-avatar
            v-else
            class="building-logo"
            :src="settings.base_url + '/' + record.logo"
            :size="64"
            icon="user"
            @click="changeImage(record)"
          />
        </a-popover>
      </template>
      <div
        slot="filterDropdown"
        slot-scope="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
        style="padding: 8px"
      >
        <a-input
          v-ant-ref="(c) => (searchInput = c)"
          :placeholder="`Search ${column.dataIndex}`"
          :value="selectedKeys[0]"
          style="width: 188px; margin-bottom: 8px; display: block"
          @change="
            (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
          "
          @pressEnter="
            () => handleSearch(selectedKeys, confirm, column.dataIndex)
          "
        />
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        >
          Search
        </a-button>
        <a-button
          size="small"
          style="width: 90px"
          @click="() => handleReset(clearFilters)"
        >
          Reset
        </a-button>
      </div>
      <a-icon
        slot="filterIcon"
        slot-scope="filtered"
        type="search"
        :style="{ color: filtered ? '#108ee9' : undefined }"
      />

      <template
        v-for="col in [
          'name',
          'legalName',
          'ArabicName',
          'delegeRegional',
          'headMaster',
          'createdAt',
          'phone',
          'adress',
        ]"
        :slot="col"
        slot-scope="text, record"
      >
        <div v-if="col != 'createdAt'" :key="col">
          <a-input
            v-if="record.editable"
            :type="col == 'phone' ? 'number' : 'text'"
            style="margin: -5px 0"
            :value="text"
            @change="(e) => handleChange(e.target.value, record._id, col)"
          />
          <template v-else>{{ text }}</template>
        </div>
        <div v-else :key="col">
          <template>{{ text }}</template>
        </div>
      </template>
      <template :slot="'reductionType'" slot-scope="text, record">
        <a-select
          v-if="record.editable"
          :default-value="text"
          style="width: 100%"
          @change="(value) => handleChange(value, record._id, 'reductionType')"
        >
          <a-select-option value="pourcentage">{{
            $t("etablissement.pourcentage")
          }}</a-select-option>
          <a-select-option value="dinar">{{
            $t("etablissement.dinar")
          }}</a-select-option>
        </a-select>
        <template v-else>{{ $t("etablissement." + text) }}</template>
      </template>
      <template :slot="'type'" slot-scope="text, record">
        <a-select
          v-if="record.editable"
          :default-value="text"
          style="width: 100%"
          @change="(value) => handleChange(value, record._id, 'type')"
        >
          <a-select-option value="jardinEnfant">{{
            $t("etablissement.jardinEnfant")
          }}</a-select-option>
          <a-select-option value="ecole">{{
            $t("etablissement.ecole")
          }}</a-select-option>
          <a-select-option value="college">{{
            $t("etablissement.college")
          }}</a-select-option>
          <a-select-option value="lycee">{{
            $t("etablissement.lycee")
          }}</a-select-option>
          <a-select-option value="collegeEtLycee">{{
            $t("etablissement.collegeEtLycee")
          }}</a-select-option>
          <a-select-option value="primaireEtCollege">{{
            $t("etablissement.primaireEtCollege")
          }}</a-select-option>
        </a-select>
        <template v-else>{{ $t("etablissement." + text) }}</template>
      </template>
      <span slot="operation" slot-scope="text, record">
        <div class="editable-row-operations">
          <span v-if="record.editable">
            <a-button
              size="small"
              type="primary"
              @click="() => save(record._id)"
              >{{ $t("all.save") }}</a-button
            >
            <a-button class="ml-1" size="small" type="danger">
              <a-popconfirm
                :title="$t('all.sureToCancelAlert')"
                @confirm="() => cancel(record._id)"
              >
                <a>{{ $t("all.cancel") }}</a>
              </a-popconfirm>
            </a-button>
          </span>
          <span v-else>
            <a-button
              type="primary"
              :disabled="editingKey !== ''"
              @click="() => edit(record._id)"
            >
              <a-icon type="edit" />
              {{ $t("all.edit") }}
            </a-button>
          </span>
        </div>
      </span>

      <span slot="config" slot-scope="text, record">
        <a-button
          icon="api"
          @click="openConfigModal(record)"
          :disabled="record.editable || editingKey !== ''"
        >
          {{ $t("etablissement.editConfig") }}
        </a-button>
      </span>
      <span slot="access" slot-scope="text, record">
        <a-button
          icon="api"
          @click="openAccessModal(record)"
          :disabled="record.editable || editingKey !== ''"
        >
          {{ $t("etablissement.editAccess") }}
        </a-button>
      </span>
    </a-table>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import moment from "moment";
import { mapState } from "vuex";
var buildingsData = [];

export default {
  name: "buildings",
  computed: mapState(["settings", "user"]),
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - Etablissements",
    });

    let buidlingList = [];
    this.user.building.forEach((currentItem) => {
      buidlingList.push(currentItem._id);
    });

    if (this.user.edtrustAdmin) {
      this.columns.push({
        title: this.$t("etablissement.access"),
        dataIndex: "access",
        key: "access",
        scopedSlots: { customRender: "access" },
      });
    }

    await apiClient
      .post("/buildings/filter", {
        query: {
          _id: buidlingList,
          active: true,
          "access.edManager.active": true,
        },
      })
      .then((res) => {
        buildingsData = [...res.data];
        buildingsData = buildingsData.map((elem) => {
          return {
            ...elem,
            logoUpload: elem._id,
            key: elem._id,
            createdAt: moment(elem.createdAt).format("DD-MM-YYYY"),
          };
        });
        this.data = buildingsData;
        this.cacheData = buildingsData.map((item) => ({ ...item }));
        this.tableLeading = false;
      })
      .catch(() => this.$message.error($t("error.aucEtablissement")));
  },
  data() {
    return {
      loadingACUpdate: false,
      selectedBuilding: null,
      visibleAccessModal: false,
      visibleConfigModal: false,
      form: this.$form.createForm(this),
      accessForm: this.$form.createForm(this),
      configForm: this.$form.createForm(this),
      columns: [
        {
          title: this.$t("etablissement.logo"),
          dataIndex: "logoUpload",
          key: "logoUpload",
          scopedSlots: { customRender: "logoUpload" },
        },
        {
          title: this.$t("etablissement.nom"),
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
          sorter: (a, b) => a.name.length - b.name.length,
          sortDirections: ["descend", "ascend"],
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender",
          },
          onFilter: (value, record) =>
            record.name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("etablissement.ArabicName"),
          dataIndex: "ArabicName",
          key: "ArabicName",
          sorter: (a, b) => a.ArabicName.length - b.ArabicName.length,
          sortDirections: ["descend", "ascend"],
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "ArabicName",
          },
          onFilter: (value, record) =>
            record.ArabicName.toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("etablissement.delegeRegional"),
          dataIndex: "delegeRegional",
          key: "delegeRegional",
          sorter: (a, b) => a.delegeRegional.length - b.delegeRegional.length,
          sortDirections: ["descend", "ascend"],
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "delegeRegional",
          },
          onFilter: (value, record) =>
            record.delegeRegional
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("etablissement.designation"),
          dataIndex: "legalName",
          key: "legalName",
          sorter: (a, b) => a.legalName.length - b.legalName.length,
          sortDirections: ["descend", "ascend"],
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "legalName",
          },
          onFilter: (value, record) =>
            record.legalName
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("etablissement.directrice"),
          dataIndex: "headMaster",
          key: "headMaster",
          sorter: (a, b) => a.headMaster.length - b.headMaster.length,
          sortDirections: ["descend", "ascend"],
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "headMaster",
          },
          onFilter: (value, record) =>
            record.headMaster
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("etablissement.dateEnregistrement"),
          dataIndex: "createdAt",
          key: "createdAt",
          editable: false,
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "createdAt",
          },
          onFilter: (value, record) =>
            record.createdAt
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("etablissement.telephone"),
          dataIndex: "phone",
          key: "phone",
          sorter: (a, b) => a.phone - b.phone,
          sortDirections: ["descend", "ascend"],
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "phone",
          },
          onFilter: (value, record) =>
            record.phone.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("etablissement.adresse"),
          dataIndex: "adress",
          key: "adress",
          sorter: (a, b) => a.adress.length - b.adress.length,
          sortDirections: ["descend", "ascend"],
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "adress",
          },
          onFilter: (value, record) =>
            record.adress
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("etablissement.type"),
          dataIndex: "type",
          key: "type",
          sortDirections: ["descend", "ascend"],
          width: 200,
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "type",
          },
          onFilter: (value, record) =>
            record.type.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("etablissement.reductionType"),
          dataIndex: "reductionType",
          key: "reductionType",
          scopedSlots: { customRender: "reductionType" },
          sortDirections: ["descend", "ascend"],
          width: 200,
        },
        {
          title: this.$t("action.actions"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
        {
          title: this.$t("etablissement.config"),
          dataIndex: "config",
          key: "config",
          scopedSlots: { customRender: "config" },
        },
      ],
      tableLeading: true,
      data: null,
      cacheData: null,
      editingKey: "",
      fileList: [],
      visibleModal: false,
      buildingLogo: null,
      buildingInModal: {},
      accessData: {
        edManager: [
          { lang: "EdManager", icon: "", key: "active" },
          {
            lang: "menu.generale",
            icon: "fa fa-file-text",
            key: "statstiqueGeneral",
          },
          {
            lang: "menu.parametrageEtablissement",
            icon: "fa fa-university",
            key: "parametrageEtablissement",
          },
          {
            lang: "menu.parametrageComptabilite",
            icon: "fa fa-sort-numeric-desc",
            key: "parametrageComptabilite",
          },
          {
            lang: "menu.parametrageScolarite",
            icon: "fa fa-pencil",
            key: "parametrageScolarite",
          },
          {
            lang: "bulletin.bulletin",
            icon: "fa fa-file-text",
            key: "bulletin",
          },
          {
            lang: "scolarite.certification",
            icon: "fa fa-file-text",
            key: "certificate",
          },
          { lang: "menu.sms", icon: "fa fa-envelope", key: "sms" },
          {
            lang: "menu.smartSchedule",
            icon: "fa fa-clock-o",
            key: "smartSchedule",
          },
          {
            lang: "menu.administration",
            icon: "fa fa-expeditedssl",
            key: "adminstration",
          },
          {
            lang: "menu.listeEleves",
            icon: "fa fa-database",
            key: "studentList",
          },
          { lang: "menu.activites", icon: "fa fa-tags", key: "activites" },
          { lang: "cantine.cantine", icon: "fa fa-cutlery", key: "cantine" },
          {
            lang: "menu.scolarite",
            icon: "fa fa-graduation-cap",
            key: "scolarite",
          },
          {
            lang: "menu.listePunition",
            icon: "fa fa-legal",
            key: "punishments",
          },
          { lang: "emploi.emplois", icon: "fa fa-calendar", key: "emplois" },
          { lang: "menu.assiduite", icon: "fa fa-th-list", key: "attendance" },
          { lang: "menu.personnel", icon: "fa fa-users", key: "personnel" },
          {
            lang: "menu.comptabilite",
            icon: "fa fa-money",
            key: "comptabilite",
          },
          { lang: "menu.declaration", icon: "fa fa-eye", key: "declaration" },
          { lang: "menu.actulite", icon: "fa fa-newspaper-o", key: "news" },
          { lang: "menu.calender", icon: "fa fa-calendar", key: "calender" },
          { lang: "menu.ptchat", icon: "fa fa-weixin", key: "ptchat" },
          { lang: "menu.chat", icon: "fa fa-weixin", key: "chat" },
          {
            lang: "Teacher Availability",
            icon: "",
            key: "teacherAvailability",
          },
        ],
        edParent: [
          { lang: "EdParent", icon: "", key: "active" },
          {
            lang: "menu.listeReglement",
            icon: "fa fa-money",
            key: "liste_reglement",
          },
        ],
        edLearning: [
          { lang: "EdLearning", icon: "", key: "active" },
          { lang: "Advisor", icon: "fa fa-briefcase", key: "advisor" },
          { lang: "Sghartoon", icon: "", key: "sghartoon" },
          {
            lang: "Teacher Availability",
            icon: "",
            key: "teacherAvailability",
          },
        ],
      },
    };
  },
  methods: {
    closeModal() {
      this.visibleModal = false;
      this.fileList = [];
      this.buildingLogo = null;
    },
    updateBuildingLogo(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        let formData = new FormData();
        formData.append("photo", this.buildingLogo);
        apiClient
          .patch("/buildings/" + this.buildingInModal._id + "/logo", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            this.data = this.data.map((b) =>
              b._id == this.buildingInModal._id
                ? { ...b, logo: res.data.imageURL }
                : b
            );
            this.closeModal();
            this.updateBuildingList(this.buildingInModal._id, {
              logo: res.data.imageURL,
            });
            this.$message.success(this.$t("success.logoMSA"));
            // window.location.reload();
          })
          .catch(() => this.$message.error(this.$t("error.impoTelecharger")));
      });
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error(this.$t("error.fichiers"));
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error(this.$t("error.image"));
      }
      if (isJpgOrPng && isLt2M) {
        this.$message.success(
          this.$t("success.fileUploadedPleaseSave", { name: file.name })
        );
        this.buildingLogo = file;
      }
      return isJpgOrPng && isLt2M;
    },
    addTofileList() {
      this.fileList = [this.buildingLogo];
    },
    changeImage(record) {
      this.visibleModal = true;
      this.buildingInModal = record;
    },
    updateBuildingList(key, update) {
      const targetDB = this.data.filter((b) => b._id == key)[0];
      let target = this.settings.image.filter(
        (b) => b.db == targetDB.dbName
      )[0];
      if (update.logo) target.logo = update.logo;
      if (update.name) target.name = update.name;
      const newBuildingsList = this.settings.image.map((b) =>
        b.db == target.db ? target : b
      );
      let UserTarget = this.user.building.filter(
        (b) => b.dbName == targetDB.dbName
      )[0];
      UserTarget.type = update.type;
      UserTarget.reductionType = update.reductionType;

      const newUserBuildingsList = this.user.building.map((b) =>
        b.dbName == UserTarget.dbName ? UserTarget : b
      );
      this.$store.commit("user/SET_STATE", {
        building: newUserBuildingsList,
      });

      this.$store.commit("settings/CHANGE_SETTING", {
        setting: "image",
        value: newBuildingsList,
      });
    },
    updateBuilding(key, updates) {
      /*
       //DONE: Update building in nav after updating the current building
      */
      apiClient
        .patch("/buildings/" + key, { data: updates })
        .then((res) => {
          this.updateBuildingList(key, {
            name: updates.name,
            type: updates.type,
            reductionType: updates.reductionType,
          });
          this.$message.success(
            this.$t("success.buildingMSA", { name: updates.name })
          );
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(
            this.$t("error.buildingEchoue", { name: updates.name })
          );
        });
    },
    handleChange(value, key, column) {
      console.log(value);
      console.log(key);
      console.log(column);
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    async save(key) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      if (target && targetCache) {
        delete target.editable;
        this.data = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
      }
      this.editingKey = "";
      const updates = {
        adress: target.adress,
        name: target.name,
        headMaster: target.headMaster,
        phone: target.phone,
        legalName: target.legalName,
        type: target.type,
        ArabicName: target.ArabicName,
        delegeRegional: target.delegeRegional,
        reductionType: target.reductionType,
      };
      await this.updateBuilding(key, updates);
    },
    cancel(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = "";
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },
    openAccessModal(record) {
      this.selectedBuilding = record;
      this.visibleAccessModal = true;
    },
    openConfigModal(record) {
      this.selectedBuilding = { ...record };
      this.visibleConfigModal = true;
    },
    closeAccessModal() {
      this.visibleAccessModal = false;
      this.visibleConfigModal = false;
    },
    updateBuildingAccess(e) {
      e.preventDefault();
      this.accessForm.validateFieldsAndScroll((err, values) => {
        if (err) return;
        this.loadingACUpdate = true;
        apiClient
          .post(
            "/buildings/" + this.selectedBuilding._id + "/updateAccess",
            values
          )
          .then((res) => {
            this.data = this.data.map((b) =>
              b._id == this.selectedBuilding._id ? { ...b, access: values } : b
            );

            this.$store.commit("user/SET_STATE", {
              building: this.data,
            });

            this.closeAccessModal();
            this.$message.success(this.$t("etablissement.accessUpdated"));
            this.$emit("reload");
          })
          .catch(() =>
            this.$message.error(this.$t("liste.changementNonEffectue"))
          )
          .finally(() => (this.loadingACUpdate = false));
      });
    },
    updateBuildingConfig(e) {
      e.preventDefault();
      this.configForm.validateFieldsAndScroll((err, values) => {
        if (err) return;
        this.loadingACUpdate = true;
        apiClient
          .post("/buildings/" + this.selectedBuilding._id + "/updateConfig", {
            config: this.selectedBuilding.config,
          })
          .then((res) => {
            this.data = this.data.map((b) =>
              b._id == this.selectedBuilding._id
                ? { ...this.selectedBuilding }
                : b
            );

            this.closeAccessModal();
            this.$message.success(this.$t("etablissement.accessUpdated"));
            this.$emit("reload");
          })
          .catch(() =>
            this.$message.error(this.$t("error.changementNonEffectue"))
          )
          .finally(() => (this.loadingACUpdate = false));
      });
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
  },
};
</script>
<style scoped>
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* Others */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.building-logo:hover {
  cursor: pointer;
}
</style>
