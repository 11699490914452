<template>
  <div class="card col-xl-12 col-lg-12">
    <div class="card-header card-header-flex flex-column">
      <a-tabs
        class="kit-tabs"
        :default-active-key="activeKey"
        @change="callback"
      >
        <a-tab-pane :tab="this.$t('menu.notesParEleve')" key="1" />
        <a-tab-pane :tab="this.$t('menu.notesModule')" key="2" />
        <a-tab-pane :tab="this.$t('menu.notesModuleComplet')" key="3" />
        <a-tab-pane
          v-if="type == 'MP'"
          :tab="this.$t('appreciation.adminAppreciation')"
          key="4"
        />
        <a-tab-pane
          v-if="
            verifyBuildingAccess('certificate') && ['MP', 'MS'].includes(type)
          "
          :tab="this.$t('menu.certificate')"
          key="5"
        />
      </a-tabs>
    </div>
    <div class="card-body" v-if="loading">
      <span class="indicator-progress">
        Veillez patientez...
        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
      </span>
    </div>
    <div class="card-body px-2" v-else>
      <component
        v-bind:is="currentTabComponent"
        :key="type + loadKey"
        :type="type"
        :subModuleList="subModuleList"
      ></component>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import appreciationAdmin from "./appreciationAdmin";
import subjectMark from "./subjectMarks";
import moduleMark from "./moduleMarks";
import studentMarks from "./studentMarks";
import certification from "./certification";
import apiClient from "@/services/axios";
import { mapState } from "vuex";

export default {
  name: "Bulletin",
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  components: {
    subjectMark,
    appreciationAdmin,
    moduleMark,
    studentMarks,
    certification,
  },
  data() {
    return {
      activeKey: "1",
      loadKey: 0,
      selectedMarkBy: "",
      subModuleList: {},
      loading: true,
    };
  },
  computed: {
    currentTabComponent: function () {
      switch (this.activeKey) {
        case "1":
          return "studentMarks";
        case "2":
          return "subjectMark";
        case "3":
          return "moduleMark";
        case "4":
          return "appreciationAdmin";
        case "5":
          return "certification";
      }
    },
    ...mapState(["settings", "user"]),
    schoolDetails() {
      return (
        this.user.building.filter(
          (el) => el.dbName == this.settings.activeBuilding
        )[0] || {}
      );
    },
  },
  async created() {
    this.loading = true;
    await this.getSubModuleList();
    this.loading = false;
  },
  methods: {
    verifyBuildingAccess: function (item) {
      let access = this.schoolDetails.access.edManager[item];
      if (access && access == true) return true;
      else return false;
    },
    getSubModuleList: async function () {
      try {
        const { data } = await apiClient.post("/bulletin/v2/sub-module/list", {
          status: "active",
        });
        this.subModuleList = data;
      } catch (error) {
        this.$message.error(this.$t("error.erreur"));
      }
    },
    callback: function (key) {
      this.activeKey = key;
      this.loadKey += 1;
    },
  },
};
</script>

<style scoped>
::v-deep .ant-tabs-nav-wrap {
  overflow: hidden !important;
}
</style>
