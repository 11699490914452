<template>
  <div>
    <div class="d-flex flex-wrap flex-column align-items-center">
      <a-button type="primary" @click="fetchPayments"
        ><a-icon type="dollar" /> {{ $t("profil.paiement") }}</a-button
      >
    </div>

    <a-modal
      :dialog-style="{ top: '20px' }"
      v-model="visibleModal"
      @cancel="resetPayments"
      width="75%"
      :footer="false"
    >
      <div class="card" v-if="loading"><a-skeleton active /></div>
      <div class="card" v-else>
        <a-page-header
          style="border: 1px solid rgb(235, 237, 240)"
          class="card-header"
          :title="
            $t('profil.paiement') +
            ' : ' +
            student.firstName +
            ' ' +
            student.lastName
          "
        >
          <div class="row">
            <div class="col-md-4" style="font-size: 24px">
              <b>{{ classroomName }}</b>
            </div>
            <div class="col-md-4"></div>
            <div class="col-md-4 mb-3">
              <a-select
                @change="selectReceiptType"
                show-search
                style="width: 100%"
                option-filter-prop="children"
                :placeholder="$t('recette.typePaiement')"
                :filter-option="filterOption"
              >
                <a-select-option
                  :value="item._id"
                  :name="item.name"
                  v-for="item in receiptTypes"
                  :key="item._id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4"></div>
            <a-dropdown class="col-md-4">
              <a-menu
                slot="overlay"
                @click="
                  (v) => {
                    validPayment(v);
                  }
                "
              >
                <a-menu-item key="Receipt">
                  <a-icon type="user" />{{
                    $t("recette.recuPaiement")
                  }}</a-menu-item
                >
                <a-menu-item key="Bill">
                  <a-icon type="user" />{{
                    $t("recette.facturePaiement")
                  }}</a-menu-item
                >
              </a-menu>
              <a-button :loading="loadingValider" :disabled="loadingValider">
                {{ $t("recette.valider") }} <a-icon type="down" />
              </a-button>
            </a-dropdown>
          </div>
        </a-page-header>
        <!--reduction en TND -->
        <div class="row mt-2 mb-2" v-if="reductionType === 'dinar'">
          <div class="col-md-4 center pl-4">
            <b>{{ $t("recette.reducFact") }} (TND)</b>
          </div>
          <div class="col-md-1 center">
            <a-input-number
              :min="0"
              :v-model="TndOnTransReduction"
              :default-value="0"
              @change="
                (v) => {
                  TndOnTransReduction = v;
                  showTotal(null, true);
                }
              "
            />
          </div>
          <div class="col-md-3 mt-3">
            <ul>
              <li>
                {{ $t("recette.avantReduction") }} :
                <b>{{ total.bReduction.toFixed(2) }}</b>
              </li>
              <li>
                {{ $t("recette.apresReduction") }} :
                <b>{{ total.aReduction.toFixed(2) }}</b>
              </li>
              <li>
                {{ $t("recette.reduction") }} (%) :
                <b>{{ onTransReduction.toFixed(2) }}</b>
              </li>
            </ul>
          </div>
          <div class="col-md-4 center">
            <a-button @click="checkEmALL">
              <a-icon type="dollar" />{{ $t("recette.paiementTotal") }}
            </a-button>
            <br />
            <a-switch
              v-if="dataAvance.length > 0"
              v-model="withAvance"
              checked-children="Payer avec avance"
              un-checked-children="Payer sans avance"
              class="mt-3"
            />
          </div>
        </div>
        <!--reduction % -->
        <div class="row mt-2 mb-2" v-else>
          <div class="col-md-4 center pl-4">
            <b>{{ $t("recette.reducFact") }} (%)</b>
          </div>
          <div class="col-md-1 center">
            <a-input-number
              :min="0"
              :max="100"
              :v-model="onTransReduction"
              :default-value="0"
              @change="
                (v) => {
                  onTransReduction = v;
                  showTotal(null, true);
                }
              "
            />
          </div>
          <div class="col-md-3 mt-3">
            <ul>
              <li>
                {{ $t("recette.avantReduction") }} :
                <b>{{ total.bReduction.toFixed(2) }}</b>
              </li>
              <li>
                {{ $t("recette.apresReduction") }} :
                <b>{{ total.aReduction.toFixed(2) }}</b>
              </li>
              <li>
                {{ $t("recette.reduction") }} (TND) :
                <b>{{ total.reduction.toFixed(2) }}</b>
              </li>
            </ul>
          </div>
          <div class="col-md-4 center">
            <a-button @click="checkEmALL">
              <a-icon type="dollar" />{{ $t("recette.paiementTotal") }}
            </a-button>
            <br />
            <a-switch
              v-if="dataAvance.length > 0"
              v-model="withAvance"
              checked-children="Payer avec avance"
              un-checked-children="Payer sans avance"
              class="mt-3"
            />
          </div>
        </div>
        <!--avance start % -->

        <div class="row m-2" v-if="dataAvance.length > 0 && withAvance">
          <div class="col-md-12">
            <a-divider>Selectionnez les avances a utilisier</a-divider>
          </div>
          <div class="col-md-12">
            <div style="margin-bottom: 16px">
              <span style="margin-left: 8px"> Historique des avances </span>
            </div>
          </div>

          <div class="col-md-9 mycontent-left">
            <a-table
              :columns="columnsAvance"
              :data-source="dataAvance"
              size="middle"
            >
              <template slot="date" slot-scope="text">
                {{ moment(text).format("DD/MM/YYYY") }}
              </template>
            </a-table>
          </div>

          <div class="col-md-3">
            <div style="margin-bottom: 16px">
              <a-badge color="green" dot>
                <a-icon type="notification" />
              </a-badge>

              <span class="h5 font-weight-bold text-success pl-2">
                Solde parent : {{ dataAvance[0].parent.solde }}
              </span>
            </div>
            <div>
              <a-form-item label="Montant avance">
                <a-input-number
                  style="width: 100%"
                  placeholder="Montant de l'avance"
                  v-model="montantAvance"
                  place
                  :min="0"
                  :max="
                    total.aReduction > dataAvance[0].parent.solde
                      ? dataAvance[0].parent.solde
                      : total.aReduction
                  "
                />
              </a-form-item>
            </div>
            <div v-show="total.aReduction - montantAvance >= 0">
              <span class="bg-light text-dark lead">
                Reste a payer : {{ total.aReduction - montantAvance }}
              </span>
            </div>
          </div>
        </div>
        <!--avance end -->
        <a-form class="card-body row overflow" :form="form">
          <div v-if="modeName.toLocaleLowerCase().includes('chèque')">
            <div class="row m-2">
              <div class="col-md-12">
                <a-divider>{{ $t("recette.saisirInfoCheque") }}</a-divider>
              </div>
              <div class="col-md-1">Indice</div>
              <div class="col-md-2">Numéro:</div>
              <div class="col-md-2">Date d'échance:</div>
              <div class="col-md-1">Banque:</div>
              <div class="col-md-2">Titulaire:</div>
              <div class="col-md-1">Montant:</div>
              <div class="col-md-2">Versable ?</div>
            </div>
            <div
              class="row m-2"
              v-for="(item, index) in chequesData"
              :key="index"
            >
              <div class="col-md-1">
                <a-form-item class="m-0"> Chéque {{ index + 1 }} </a-form-item>
              </div>
              <div class="col-md-2">
                <a-form-item class="mb-0">
                  <a-input v-model="item.num" placeholder="Numéro" />
                </a-form-item>
              </div>
              <div class="col-md-2">
                <a-form-item class="mb-0">
                  <a-date-picker
                    v-model="item.dateEchance"
                    placeholder="Date d'échance"
                  />
                </a-form-item>
              </div>
              <div class="col-md-1">
                <a-form-item class="mb-0">
                  <a-input v-model="item.bank" placeholder="Banque" />
                </a-form-item>
              </div>
              <div class="col-md-2">
                <a-form-item class="mb-0">
                  <a-input v-model="item.titulaire" placeholder="Titulaire" />
                </a-form-item>
              </div>
              <div class="col-md-1">
                <a-form-item class="mb-0">
                  <a-input-number
                    style="width: 100%"
                    :default-value="0"
                    v-model="item.montant"
                    placeholder="Montant"
                    @change="
                      () => {
                        calMontantCheqEsp();
                      }
                    "
                  />
                </a-form-item>
              </div>
              <div class="col-md-2">
                <a-form-item class="mb-0">
                  <a-radio-group :default-value="true" v-model="item.versable">
                    <a-radio :value="true"> Versable </a-radio>
                    <a-radio :value="false"> Non versable </a-radio>
                  </a-radio-group>
                </a-form-item>
              </div>
              <div class="col-md-1">
                <a-button
                  v-if="index > 0"
                  type="dashed"
                  shape="circle"
                  icon="delete"
                  @click="
                    () => {
                      chequesData.splice(index, 1);
                    }
                  "
                />
              </div>
            </div>
            <div class="row m-2">
              <a-button
                type="primary"
                class="m-auto"
                @click="
                  () => {
                    chequesData.push({ ...chequeVide });
                  }
                "
              >
                Ajouter un chéque
              </a-button>
            </div>
          </div>

          <!--Chèque et espéce-->
          <div class="row m-3" v-if="modeName === 'Chèque et espéce'">
            <!--les montant-->
            <div class="col-md-6">
              <a-form-item label="Montant chèque:" class="mb-1">
                <a-input-number
                  style="width: 100%"
                  :default-value="0"
                  :disabled="true"
                  v-decorator="['montantCheque']"
                />
              </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="Montant espèce" class="mb-1">
                <a-input-number
                  style="width: 100%"
                  :default-value="0"
                  :disabled="true"
                  v-decorator="['montantEspece']"
                />
              </a-form-item>
            </div>
            <div
              class="col-md-12 ant-form-item-control has-error m-1"
              v-if="disabledChangeEspaceCheque"
            >
              <div class="ant-form-explain">
                Les informations sur les montants des chèques et le montant en
                espèces sont incorrectes, veuillez les corriger avant de
                distribuer les montants
              </div>
              <div class="ant-form-explain">
                Le montant total doit être : {{ total.aReduction.toFixed(2) }}
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <a-divider>Selectionnez les frais a payer</a-divider>
          </div>
          <a-collapse
            style="width: 100%"
            v-model="activeCollapseKey"
            expand-icon-position="right"
          >
            <a-collapse-panel
              style="width: 100%"
              v-for="payment in allDataPayement"
              :key="payment._id"
              :header="payment.name"
            >
              <div class="row mb-0 ml-1" v-if="payment.montantTotal > 0">
                {{ $t("recette.montantTotal") }} :
                <b> {{ " " + payment.montantTotal.toFixed(2) }}</b>
              </div>
              <div
                v-if="
                  modeName == 'Chèque et espéce' && payment.montantTotal > 0
                "
              >
                <div class="row mb-0">
                  <div class="col-md-6 mb-0">
                    <a-form-item label="Montant chèque:" class="mb-1">
                      <a-input-number
                        style="width: 100%"
                        @change="
                          (val) => {
                            updatePaymentsChequeEspace(
                              payment._id,
                              val,
                              'cheque'
                            );
                          }
                        "
                        :disabled="disabledChangeEspaceCheque && true"
                        v-model="payment.montantCheque"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-6 mb-0">
                    <a-form-item label="Montant espèce" class="mb-1">
                      <a-input-number
                        style="width: 100%"
                        @change="
                          (val) => {
                            updatePaymentsChequeEspace(
                              payment._id,
                              val,
                              'espace'
                            );
                          }
                        "
                        :disabled="disabledChangeEspaceCheque && true"
                        v-model="payment.montantEspece"
                      />
                    </a-form-item>
                  </div>
                </div>
              </div>
              <!-- <a-icon slot="extra" type="hourglass" /> -->
              <table class="table table-striped col-md-12">
                <thead>
                  <th colspan="3">
                    <div class="row">
                      <div class="col-md-4">
                        <span v-if="payment.type == 'classRoom'"
                          >Scolarité:</span
                        >
                        <span v-else>Activite:</span>
                        {{ payment.name }}
                      </div>
                      <div class="col-md-4">
                        {{ $t("all.startDate") }}:
                        {{ moment(payment.start).format("DD-MM-YYYY") }}
                      </div>
                      <div class="col-md-4">
                        {{ $t("all.endDate") }}:
                        {{
                          payment.start
                            ? moment(payment.end).format("DD-MM-YYYY")
                            : "-"
                        }}
                      </div>
                    </div>
                  </th>
                </thead>
                <tr>
                  <td class="p-2">{{ $t("recette.paiementPour") }}</td>
                  <td class="p-2">{{ $t("recette.restePayer") }}</td>
                  <td class="p-2">{{ $t("recette.payerMain") }}</td>
                </tr>
                <tbody>
                  <tr v-for="(fee, key) in payment.fees" :key="key">
                    <td class="m-0 p-2">
                      {{ getFeeLabel(fee.feeName, payment.mode) }}
                    </td>
                    <td>
                      <div
                        class="mt-2"
                        v-if="fee.isPayed === false || fee.isPayed === null"
                      >
                        {{ fee.feeAfterReduction }}
                        <sup v-if="onTransReduction > 0" class="text-success"
                          >-{{ onTransReduction.toFixed(2) }}%</sup
                        >

                        <div v-if="onTransReduction > 0">
                          ={{
                            (
                              fee.feeAfterReduction *
                              ((100 - onTransReduction) / 100)
                            ).toFixed(2)
                          }}
                        </div>
                      </div>
                      <div v-else>0</div>
                    </td>
                    <td>
                      <div
                        class="mt-2"
                        v-if="fee.isPayed === false || fee.isPayed === null"
                      >
                        <a-switch
                          @click="
                            (a) => {
                              showTotal(a);
                            }
                          "
                          v-model="checked[payment._id][fee.feeName]"
                        >
                          <a-icon slot="checkedChildren" type="check" />
                          <a-icon slot="unCheckedChildren" type="close" />
                        </a-switch>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </a-collapse-panel>
          </a-collapse>
        </a-form>
      </div>
      <!-- <payment-validator
        v-if="visibleModal"
        :receiptTypes="receiptTypes"
        :data="ActivityPaymentModal"
        :idParent="idParent"
        v-on:reload="reload"
      ></payment-validator> -->
    </a-modal>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import writtenNumber from "written-number";
import "jspdf-autotable";
import apiClient from "@/services/axios";
import { PageHeader } from "ant-design-vue";
import { mapState } from "vuex";
import { printPDF } from "../../printPDFMultiPayment";

const columnsAvance = [
  {
    title: "Date d'ajout",
    dataIndex: "createdAt",
    key: "date",
    scopedSlots: {
      customRender: "date",
    },
  },
  {
    title: "Montant espéce",
    dataIndex: "montantEspece",
    key: "montantEspece",
    sorter: (a, b) => a.montantEspece - b.montantEspece,
  },
  {
    title: "Montant chèque",
    dataIndex: "montantCheque",
    key: "montantCheque",
    sorter: (a, b) => a.montantCheque - b.montantCheque,
  },
  {
    title: "Montant avance",
    dataIndex: "totalAvance",
    key: "totalAvance",
    sorter: (a, b) => a.totalAvance - b.totalAvance,
  },
];

export default {
  computed: {
    ...mapState(["settings", "user"]),
  },
  async created() {
    this.chequesData.push({ ...this.chequeVide });
    this.onTransReduction = 0;
    const building = this.user.building.find(
      (el) => el.dbName == this.settings.activeBuilding
    );
    if (building) this.reductionType = building.reductionType || "pourcentage";
  },
  beforeDestroy() {
    this.onTransReduction = 0;
  },

  components: {
    "a-page-header": PageHeader,
  },
  props: {
    classroomName: {
      required: true,
    },

    idParent: {
      required: true,
    },
    student: {
      required: true,
    },
  },
  data() {
    return {
      receiptTypes: [],
      dataAvance: [],
      activeCollapseKey: null,
      disabledChangeEspaceCheque: true,
      selectAllValue: true,
      visibleModal: false,
      loadingValider: false,
      form: this.$form.createForm(this),
      selectedReceiptType: null,
      modeName: "",
      onTransReduction: 0,
      TndOnTransReduction: 0,
      reductionType: "dinar",
      FISCAL: parseFloat(process.env.VUE_APP_FISCAL),
      TVA: parseFloat(process.env.VUE_APP_TVA),
      result: {},
      checked: {},
      total: {
        bReduction: 0,
        aReduction: 0,
        reduction: 0,
      },
      //avance
      withAvance: false,
      columnsAvance,
      loading: false,
      montantAvance: null,
      allDataPayement: [],
      chequesData: [],
      chequeVide: {
        num: null,
        dateEchance: null,
        bank: null,
        titulaire: null,
        montant: null,
        versable: true,
      },
    };
  },

  methods: {
    getFeeLabel(key, mode) {
      try {
        if (key == "inscription") return "Inscription";
        if (mode == "M") return moment(key).format("MMMM") + " (" + key + ")";
        if (mode == "T") return "Tranche " + key;
      } catch (error) {
        console.error(error.stack);
        return "--";
      }
    },
    async fetchPayments() {
      this.selectedReceiptType = null;
      this.montantAvance = null;
      this.visibleModal = true;
      this.loading = true;
      await apiClient
        .post("/parents/filter", {
          query: { _id: this.idParent },
          aggregation: {
            $project: {
              father: 1,
              mother: 1,
            },
          },
        })
        .then((res) => (this.parents = res.data))
        .catch();

      const payment = apiClient
        .get("/payment/pvalidator/" + this.student._id)
        .then((res) => {
          this.allDataPayement = res.data;
          this.activeCollapseKey = [
            this.allDataPayement[0] ? this.allDataPayement[0]._id : null,
          ];
          for (const payment of this.allDataPayement) {
            payment.montantEspece = 0;
            payment.montantCheque = 0;
            payment.montantTotal = 0;
            this.checked[payment._id] = {};
            if (payment.fees) {
              for (const fee of payment.fees) {
                if (fee.isPayed === false || fee.isPayed === null)
                  this.checked[payment._id][fee.feeName] = false;
              }
            }
          }
        });

      await Promise.all([this.getAvances(), this.getReceiptTypes(), payment]);
      this.loading = false;
    },
    resetPayments() {
      this.visibleModal = false;
      this.loading = false;
      this.allDataPayement = [];
    },
    reload(data) {
      this.$emit("reload");
    },
    showTotal(value, reductionChanged = false) {
      let checked = this.checked;
      this.total.bReduction = 0;
      this.total.aReduction = 0;
      this.total.reduction = 0;

      for (const paymentId in this.checked) {
        const record = this.allDataPayement.find((p) => p._id == paymentId);

        record.montantTotal = 0;
        //if reduction type is TND
        if (this.reductionType === "dinar") {
          for (const feeName in checked[paymentId]) {
            if (checked[paymentId][feeName]) {
              record.montantTotal += Number(
                record.fees.find((p) => p.feeName == feeName).feeAfterReduction
              );
            }
          }

          this.total.bReduction += record.montantTotal;
          this.total.aReduction = this.total.bReduction;
          this.total.aReduction -=
            this.TndOnTransReduction < this.total.aReduction
              ? this.TndOnTransReduction
              : this.total.aReduction;

          this.total.reduction = this.total.bReduction - this.total.aReduction;
          if (this.TndOnTransReduction >= this.total.bReduction)
            this.onTransReduction = 100;
          else if (this.total.bReduction > 0)
            this.onTransReduction =
              100 - (100 * this.total.aReduction) / this.total.bReduction;
          else this.onTransReduction = 0;
        }
        //if reduction type is %
        else {
          for (const feeName in checked[paymentId]) {
            if (checked[paymentId][feeName]) {
              record.montantTotal += Number(
                record.fees.find((p) => p.feeName == feeName).feeAfterReduction
              );
              this.total.aReduction +=
                Number(
                  record.fees.find((p) => p.feeName == feeName)
                    .feeAfterReduction
                ) *
                ((100 - this.onTransReduction) / 100);
            }
          }
          this.total.bReduction += record.montantTotal;
        }

        this.total.reduction = this.total.bReduction - this.total.aReduction;
      }

      // fix payments prix
      this.allDataPayement.map((payment) => {
        payment.montantTotal =
          payment.montantTotal -
          (payment.montantTotal * this.onTransReduction) / 100;
        return payment;
      });

      if (this.modeName == "Chèque et espéce") {
        this.updatePaymentsChequeEspace();
        this.calMontantCheqEsp();
      }
    },
    calMontantCheqEsp() {
      let totalCheq = 0;
      let totalEsp = 0;
      this.chequesData.forEach((c) => {
        totalCheq += c.montant;
      });
      if (this.total.aReduction > 0)
        totalEsp = this.total.aReduction - totalCheq;
      this.form.setFieldsValue({
        montantCheque: totalCheq,
        montantEspece: totalEsp,
      });
      if (totalEsp < 0) this.disabledChangeEspaceCheque = true;
      else this.disabledChangeEspaceCheque = false;
    },
    updatePaymentsChequeEspace(paymentId = undefined, montant, type) {
      setTimeout(() => {
        const totalCheque = this.form.getFieldValue("montantCheque");
        const totalEspece = this.form.getFieldValue("montantEspece");
        for (const payment of this.allDataPayement) {
          payment.montantCheque = 0;
          payment.montantEspece = 0;
        }
        if (
          this.total.aReduction.toFixed(2) !=
            parseFloat(totalCheque + totalEspece).toFixed(2) &&
          totalEspece < 0
        ) {
          this.disabledChangeEspaceCheque = true;
          return;
        }
        this.disabledChangeEspaceCheque = false;

        let remainingCheque = totalCheque;
        let remainingEspece = totalEspece;

        const payments = this.allDataPayement
          .filter((payment) => payment.montantTotal > 0)
          .map((payment) => {
            if (paymentId == payment._id) {
              if (type == "cheque") payment.montantCheque = montant;
              if (type == "espace") payment.montantEspece = montant;
            }

            remainingCheque -= payment.montantCheque;
            remainingEspece -= payment.montantEspece;
            return payment;
          });

        payments.map((payment) => {
          const chequeAllocation = Math.min(
            remainingCheque,
            payment.montantTotal - payment.montantCheque - payment.montantEspece
          );
          payment.montantCheque += chequeAllocation;
          remainingCheque -= chequeAllocation;

          const espaceAllocation = Math.min(
            remainingEspece,
            payment.montantTotal - payment.montantCheque - payment.montantEspece
          );
          payment.montantEspece += espaceAllocation;
          remainingEspece -= espaceAllocation;

          payment.montantEspece = parseFloat(payment.montantEspece).toFixed(2);
          payment.montantCheque = parseFloat(payment.montantCheque).toFixed(2);
          return payment;
        });

        //check incorrect montant
        for (const payment of payments) {
          if (payment.montantCheque < 0 || payment.montantEspece < 0) {
            this.updatePaymentsChequeEspace();
          }
        }

        this.allDataPayement = [...payments];
      }, 100);
    },
    moment,
    checkEmALL() {
      for (const id in this.checked) {
        for (const key in this.checked[id]) {
          this.checked[id][key] = this.selectAllValue;
        }
      }

      this.selectAllValue = !this.selectAllValue;

      this.showTotal(null, false);
      this.calMontantCheqEsp();
    },
    notZeroAmount() {
      let result = false;
      for (const id in this.checked) {
        for (const key in this.checked[id]) {
          if (this.checked[id][key]) {
            result = true;
            break;
          }
        }
      }
      return result;
    },
    validPayment(type) {
      this.loadingValider = true;
      if (!this.selectedReceiptType) {
        this.$message.warning("Selectionner le mode de paiement");
        this.loadingValider = false;
      } else
        this.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            this.loadingValider = true;
            let data = {
              transactions: [],
            };
            if (!this.notZeroAmount()) {
              this.$message.error(
                "vous ne pouvez pas valider un paiement d'un montant égal à zéro"
              );
              this.loadingValider = false;
              return;
            }

            let modeId = 1;

            //if payment selectedReceiptType is cheque et espéce
            if (this.modeName === "Chèque et espéce") {
              if (
                this.total.aReduction.toFixed(2) !=
                values.montantCheque + values.montantEspece
              ) {
                this.$message.warning(
                  "La somme des montants chèque et espèce doit être égale à " +
                    this.total.aReduction.toFixed(2)
                );
                this.loadingValider = false;
                return;
              }
              data.montantCheque = values.montantCheque.toFixed(2);
              data.montantEspece = values.montantEspece.toFixed(2);
              data.cheques = this.chequesData;
              modeId = 3;
            } else if (this.modeName === "chèque") {
              data.montantCheque = this.total.aReduction.toFixed(2);
              data.cheques = this.chequesData;
              modeId = 2;
            } else {
              data.montantEspece = this.total.aReduction.toFixed(2);
              modeId = 1;
            }

            data.montantTotal = 0;
            data.montantTotal +=
              Number(data.montantEspece || 0) + Number(data.montantCheque || 0);
            let avanceListReglement = [];

            for (const payment of this.allDataPayement) {
              let fees = {};
              let onInscription = false;
              for (const fee of payment.fees) {
                if (this.checked[payment._id][fee.feeName]) {
                  fees[fee.feeName] =
                    (Number(fee.feeAfterReduction) *
                      (100 - this.onTransReduction)) /
                    100;

                  avanceListReglement.push(
                    payment.typeAS == "A"
                      ? "Activité/" + payment.name
                      : "Scolarité/" + payment.name
                  );
                }
              }
              if (this.checked[payment._id].inscription) onInscription = true;

              if (Object.keys(fees).length > 0 || onInscription) {
                const p = {
                  payment: payment._id,
                  onTransReduction: parseFloat(this.onTransReduction).toFixed(
                    2
                  ),
                  typeAS: payment.typeAS,
                  fees,
                  onInscription,
                  date: Date.now(),
                  receiptType: this.selectedReceiptType,
                  mode: payment.mode,
                  typeFR: type.key == "Receipt" ? "R" : "F",
                  montantTotal: parseFloat(payment.montantTotal).toFixed(2),
                };
                if (modeId == 1)
                  p.montantEspece = parseFloat(payment.montantTotal).toFixed(2);
                else if (modeId == 2)
                  p.montantCheque = parseFloat(payment.montantTotal).toFixed(2);
                else if (modeId == 3) {
                  p.montantCheque = parseFloat(payment.montantCheque).toFixed(
                    2
                  );
                  p.montantEspece = parseFloat(payment.montantEspece).toFixed(
                    2
                  );
                }
                data.transactions.push(p);
              }
            }
            apiClient
              .put("/transaction/many", data)
              .then(async (res) => {
                if (this.withAvance && this.montantAvance > 0) {
                  let avanceData = {
                    receiptType: this.selectedReceiptType,
                    parent: this.idParent,
                    totalAvance: -this.montantAvance,
                    typeReglement: [...new Set(avanceListReglement)],
                    serialMulti: res.data.serialMulti,
                    used: true,
                  };
                  await apiClient
                    .put("/avance", avanceData)
                    .then((res) => {
                      this.$message.success(
                        "Avance débiter du solde parent avec succées"
                      );
                    })
                    .catch((e) => {
                      console.error(e);
                      this.$message.error(e.response.data.message);
                    });
                }
                this.$message.success("Paiment ajouté");
                this.visibleModal = false;
                this.print(res.data.serialMulti);
                this.generatePDF(res.data);
                this.$emit("reload");
              })
              .catch((e) => {
                console.log(e);
                this.$message.warning("Paiement non effectuer");
              })
              .finally(() => (this.loadingValider = false));
          } else {
            this.$message.warning("Paiement non effectuer");
            this.loadingValider = false;
          }
        });
    },
    async print(serialMulti) {
      const studentId =
        this.$route.params.studentId || this.$route.path.split("/").pop();
      await apiClient
        .get("/multitransaction/" + studentId)
        .then((res) => {
          const transaction = res.data.formattedMultiTransactions.find(
            (item) => item.multiTransactionSerial === serialMulti
          );
          if (transaction) {
            printPDF(transaction, this.settings, res.data.nomClient, res.data.studentName);
          }
        })
        .catch();
    },
    async generatePDF(transactions) {
      const serials = [];
      const titre =
        record.typeFR == "F"
          ? "Facture N. " + record.serial
          : "Reçue N. " + record.serial;

      const doc = pdfHeader(this.settings, this.nomClient, titre);
      for (let j = 0; j < transactions.length; j++) {
        const record = transactions[j];
        serials.push(record.serial);

        let montantHT = record.montantHT;
        let montantTTC = record.montantTotal;

        const dataType =
          record.typeAS == "A"
            ? record.payment.activity
            : record.payment.classroom;

        // get the client name from the parent list
        const client = this.parents.find(
          (p) => p._id == record.payment.student.parent
        );
        const nomClient =
          client.mother.firstName +
          " " +
          client.mother.lastName +
          " ET " +
          client.father.firstName +
          " " +
          client.father.lastName;

        doc.text(20, 70, "Nom et Prénom : " + nomClient);
        doc.setFontSize(10);
        doc.addImage(imgData, "JPEG", 8, 8, 40, 40);

        const date = new Date();
        const options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        doc.setFontSize(12);
        doc.text(150, 40, date.toLocaleDateString("fr-FR", options));

        let arr = [];
        const array = [];

        let onTransReduction = record.onTransReduction
          ? record.onTransReduction / 100
          : 0;

        const typeReglement =
          record.typeAS == "A"
            ? "Activité/" + record.payment.activity.designation
            : "Scolarité/" + record.payment.classroom.name;

        const studentFullname =
          record.payment.student.lastName +
          " " +
          record.payment.student.firstName;

        let repReduction = record.payment.repReduction;
        let baseReduction = false;
        if (repReduction) {
          for (const month in record.months) {
            if (Number(repReduction[month])) {
              baseReduction = true;
              break;
            }
          }
          if (record.onInscription && repReduction.inscription)
            baseReduction = true;
        }

        if (record.onInscription) {
          arr.push(studentFullname);
          arr.push("Inscription ");
          arr.push(typeReglement);

          const inscriptionFee = this.getMonthPrix(
            record.payment,
            dataType.inscription,
            "inscription"
          );

          arr.push(
            record.typeFR == "F"
              ? inscriptionFee - inscriptionFee * record.tva
              : inscriptionFee
          );

          array.push(arr);
          arr = [];
        }
        if (record.months) {
          Object.keys(record.months).forEach((k) => {
            arr.push(studentFullname);

            let paymentDate = new Date(k);
            let monthName = paymentDate.toLocaleString("fr-FR", {
              month: "long",
            });
            arr.push(monthName);
            arr.push(typeReglement);

            const monthly = this.getMonthPrix(
              record.payment,
              dataType.monthly,
              k
            );

            if (baseReduction) {
              arr.push((repReduction[k] ? repReduction[k] : 0) + "%");
            }

            arr.push(
              record.typeFR == "F" ? monthly - monthly * record.tva : monthly
            );

            array.push(arr);
            arr = [];
          });
        }
        let rowData = [
          montantHT,
          montantTTC - montantHT - Number(record.fiscal),
          record.fiscal,
          montantTTC,
        ];

        if (onTransReduction) {
          if (record.typeFR == "R") {
            arr.push({
              colSpan: baseReduction ? 4 : 3,
              content: "Total sans réduction",
              styles: { valign: "middle", halign: "center", fontStyle: "bold" },
            });
            arr.push({
              content: (montantTTC / (1 - onTransReduction)).toFixed(2),
              styles: { valign: "middle", halign: "center", fontStyle: "bold" },
            });
            array.push(arr);
            arr = [];
          }
          arr.push({
            colSpan: baseReduction ? 4 : 3,
            content: "Réduction",
            styles: { valign: "middle", halign: "center", fontStyle: "bold" },
          });
          arr.push({
            content: (onTransReduction * 100).toFixed(2) + "%",
            styles: { valign: "middle", halign: "center", fontStyle: "bold" },
          });
          array.push(arr);
          arr = [];
        }

        let rowDataText = ["Total HTVA", "TVA", "Timbre", "Total TTC"];
        for (let i = 0; i < 4; i++) {
          if (record.typeFR == "R") {
            i = 3;
            rowDataText[i] = "Total";
          }
          arr.push({
            colSpan: baseReduction ? 4 : 3,
            content: rowDataText[i],
            styles: { valign: "middle", halign: "center", fontStyle: "bold" },
          });
          arr.push({
            content: parseFloat(rowData[i]).toFixed(2),
            styles: { valign: "middle", halign: "center", fontStyle: "bold" },
          });
          array.push(arr);
          arr = [];
        }
        doc.autoTable({
          styles: {
            lineWidth: 0.2,
            valign: "middle",
            halign: "center",
            fontStyle: "bold",
            font: "Amiri",
          },
          startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
          head: baseReduction
            ? [
                [
                  "Enfant",
                  "Frais",
                  "Désignation",
                  "réduction de base",
                  "Montant",
                ],
              ]
            : [["Enfant", "Frais", "Désignation", "Montant"]],
          body: array,
          foot: [
            [
              {
                colSpan: baseReduction ? 5 : 4,
                content:
                  "Le montant total est " +
                  writtenNumber(montantTTC, { lang: "fr" }) +
                  " DT",
                styles: { halign: "center" },
              },
            ],
          ],
        });

        if (transactions.length - 1 != j) doc.addPage();
      }

      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering

      const filename = serials.join(" / ");
      doc.save(filename + ".pdf");
    },
    selectReceiptType(selectedReceiptType, e) {
      this.modeName = e.data.attrs.name;
      this.selectedReceiptType = selectedReceiptType;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    getMonthPrix(payment, originalPrice, month) {
      originalPrice = parseFloat(originalPrice);
      if (payment.repReduction) {
        const reductionPercentage = parseFloat(payment.repReduction[month]);
        if (
          isNaN(reductionPercentage) ||
          reductionPercentage < 0 ||
          reductionPercentage > 100
        )
          return parseFloat(originalPrice);

        // Calculate the new price
        const reductionAmount = (originalPrice * reductionPercentage) / 100;
        const newPrice = originalPrice - reductionAmount;
        return parseFloat(newPrice);
      }
      return parseFloat(originalPrice);
    },
    //avance methodes
    async getAvances() {
      await apiClient
        .get("/avance/" + this.idParent)
        .then((res) => {
          this.dataAvance = res.data.map((e) => {
            return {
              key: e._id,
              ...e,
            };
          });
        })
        .catch((e) => {
          console.error(e.stack);
          this.$message.warning(
            "Un problème du connexion, veuillez rafraîchir la page!4"
          );
        })
        .finally(() => (this.tableLoading = false));
    },
    async getReceiptTypes() {
      await apiClient
        .post("/receipt_types/filter", {
          query: { status: "active" },
        })
        .then((res) => (this.receiptTypes = res.data))
        .catch((e) =>
          this.$message.warning(this.$t("warning.connectionProblem"))
        );
    },
  },
};
</script>
<style scoped>
td {
  padding: 0%;
}
.center {
  margin: auto;
  width: 50%;
}
.mycontent-left {
  border-right: 1px dashed #333;
}
</style>
<style>
.ant-switch-inner {
  font-size: 18px !important;
}
</style>
