<template>
  <div>
    <div class="row success">
      <div class="col-xl-8">
        <h5 style="margin-bottom: 30px">
          <span style="margin-right: 30px">{{
            $t("bulletin.certByMoyenne")
          }}</span>
        </h5>
      </div>
    </div>
    <div class="row">
      <div :class="showSwitchMoyBtn ? 'col-xl-3' : 'col-xl-4'">
        <label>{{ $t("bulletin.listeClasses") }}</label>
        <a-select
          show-search
          :placeholder="$t('emploi.listeClasses')"
          option-filter-prop="children"
          :filter-option="filterOption"
          style="width: 150px; margin-left: 10px"
          @change="
            (val) => {
              handleClassChange('classe', val);
            }
          "
        >
          <a-select-option value="all">---</a-select-option>
          <a-select-option
            v-for="classe in classes"
            :key="classe._id"
            :value="classe._id"
            >{{ classe.name }}</a-select-option
          >
        </a-select>
      </div>

      <div :class="showSwitchMoyBtn ? 'col-xl-3' : 'col-xl-4'">
        <label style="margin-right: 5px">{{
          this.$t("emploi.trimestre")
        }}</label>
        <a-select
          :placeholder="$t('paiement.selectionnerMois')"
          style="width: 100px; margin-left: 15px; margin-right: 20px"
          @change="
            (val) => {
              this.selectedTrimester = val;
              this.updateStudentMoy();
            }
          "
          default-value="1"
        >
          <a-select-option
            v-for="trimester in ['1', '2', '3']"
            :key="trimester"
            :value="trimester"
            >{{ trimester }}</a-select-option
          >
        </a-select>
      </div>
      <div v-if="showSwitchMoyBtn" class="col-xl-3">
        <a-switch
          v-model="usePersonalizedMoyenne"
          :checked-children="$t('certification.moyPer')"
          :un-checked-children="$t('certification.moySpec')"
        />
      </div>
      <div
        :class="showSwitchMoyBtn ? 'col-xl-3' : 'col-xl-4'"
        style="display: flex; justify-content: end"
        v-if="selectedTrimester && selectedClasse"
      >
        <a-button
          type="primary"
          :loading="printLoading"
          :disabled="printLoading"
          @click="printCertificates()"
        >
          <a-icon v-if="!printLoading" type="file-pdf" />
          {{ $t("action.imprimer") }}
        </a-button>
      </div>
    </div>
    <div class="py-3"></div>
    <div class="row">
      <div class="col-xl-12">
        <div class="car" v-if="selectedTrimester && selectedClasse">
          <div class="data-table">
            <a-table
              @change="tableChanged"
              :pagination="false"
              :data-source="filtredTable"
              :columns="columns"
              :loading="tableLoading"
            >
              <template slot="moyenne" slot-scope="text">
                <span>{{ text }}</span>
              </template>

              <template slot="certificate" slot-scope="text, record">
                <span>{{
                  record.moyenne
                    ? getCertificationNameByMoyenne(record.moyenne)
                    : "--"
                }}</span>
              </template>

              <div
                slot="filterDropdown"
                slot-scope="{
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                  column,
                }"
                style="padding: 8px"
              >
                <a-input
                  v-ant-ref="(c) => (searchInput = c)"
                  :placeholder="`${$t('personnel.chercher')} ${column.title}`"
                  :value="selectedKeys[0]"
                  style="width: 188px; margin-bottom: 8px; display: block"
                  @change="
                    (e) =>
                      setSelectedKeys(e.target.value ? [e.target.value] : [])
                  "
                  @pressEnter="
                    () => handleSearch(selectedKeys, confirm, column.dataIndex)
                  "
                />
                <a-button
                  type="primary"
                  icon="search"
                  size="small"
                  style="width: 90px; margin-right: 8px"
                  @click="
                    () => handleSearch(selectedKeys, confirm, column.dataIndex)
                  "
                  >{{ $t("paiement.chercher") }}</a-button
                >
                <a-button
                  size="small"
                  style="width: 90px"
                  @click="() => handleReset(clearFilters)"
                  >{{ $t("action.reinitialiser") }}</a-button
                >
              </div>
              <a-icon
                slot="filterIcon"
                slot-scope="filtered"
                type="search"
                :style="{ color: filtered ? '#108ee9' : undefined }"
              />
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import apiClient from "@/services/axios";
import "jspdf-autotable";
import moment from "moment";
import { drawAllCertificate } from "@/utilities/pdfCertificate";
const validMoy = (moy) => {
  if (!moy || moy == "--") return false;
  if (typeof moy == "number") return true;
  return !isNaN(moy) && !isNaN(parseFloat(moy));
};

export default {
  name: "Certificate",
  computed: {
    ...mapState(["settings", "user"]),
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      usePersonalizedMoyenne: false,
      printLoading: false,
      showSwitchMoyBtn: false,
      certifParams: [],
      classes: [],
      tableLoading: false,
      studentLoading: false,
      students: [],
      selectedClasse: null,
      selectedClassName: null,
      selectedTrimester: "1",
      filtredTable: [],
      columns: [
        {
          title: this.$t("inscription.nom"),
          dataIndex: "fullName",
          key: "fullName",
          scopedSlots: {
            customRender: "fullName",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.fullName
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("certification.moyenne"),
          dataIndex: "moyenne",
          scopedSlots: { customRender: "moyenne" },
        },
        {
          title: this.$t("menu.certificate"),
          dataIndex: "certificate",
          scopedSlots: { customRender: "certificate" },
        },
      ],
      bulletinData: [],
    };
  },

  async created() {
    this.tableLoading = true;

    await apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
            level: 1,
            bulletin: 1,
          },
        },
      })
      .then(({ data }) => (this.classes = data));

    this.schoolDetails = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0];
    let { start, end } = this.settings.schoolarYears.find(
      (el) => el._id == this.settings.activeSchoolarYear
    );

    start = new Date(start).getFullYear();
    end = new Date(end).getFullYear();

    this.schoolarYearName = end + " / " + start;
    this.schoolType = this.schoolDetails.type;
    let type = this.type;
    await apiClient
      .get("/certification/" + type)
      .then((res) => {
        this.certifParams = res.data;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  watch: {
    usePersonalizedMoyenne: function (val) {
      if (!val) this.filtredTable = this.bulletinData;
      else
        this.filtredTable = this.bulletinData.map((s) => ({
          ...s,
          moyenne: s.personalizedMoyenne,
        }));
    },
  },

  methods: {
    async handleClassChange(on, value) {
      this.studentLoading = true;
      this.selectedClasse = value;
      this.selectedClassName = this.classes.find((item) => item._id === value);
      let match = {};
      match[`schoolarYearsHistory.${this.settings.activeSchoolarYear}`] = value;

      await this.updateStudentMoy();
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    updateShowBtn() {
      if (this.type == "MS" && this.selectedTrimester && this.selectedClasse) {
        if (this.bulletinData && this.bulletinData.length > 0)
          return this.bulletinData.some((b) => validMoy(b.personalizedMoyenne));
      }
      return false;
    },
    async updateStudentMoy() {
      this.tableLoading = true;
      this.printLoading = true;
      await apiClient
        .get(
          `/bulletin/v2/bt/${this.type}/all/` +
            this.selectedClasse +
            "/" +
            this.selectedTrimester
        )
        .then(({ data }) => {
          if (data.students) {
            this.bulletinData = data.students;
            this.filtredTable = data.students;
            this.showSwitchMoyBtn = this.updateShowBtn();
          }
        })
        .catch((error) => {
          console.error("error ", error);
          this.showSwitchMoyBtn = false;
        })
        .finally(() => {
          this.tableLoading = false;
          this.printLoading = false;
        });
    },

    getCertificationNameByMoyenne(moyenne) {
      if (!moyenne) return "";
      for (const cert of this.certifParams)
        if (
          Number(moyenne) >= Number(cert.min) &&
          Number(moyenne) <= Number(cert.max)
        )
          return cert.name;
      return "";
    },

    getCertificationByMoyenne(moyenne) {
      for (const cert of this.certifParams)
        if (
          Number(moyenne) >= Number(cert.min) &&
          Number(moyenne) <= Number(cert.max)
        )
          return cert;
      return null;
    },

    printCertificates() {
      this.printLoading = true;
      setTimeout(async () => {
        const students = [];
        this.filtredTable.forEach((student) => {
          try {
            if (!student.moyenne) return;
            if (!this.getCertificationByMoyenne(student.moyenne)) return;
            let certificate = this.getCertificationByMoyenne(student.moyenne);
            if (!certificate.fileF || !certificate.fileM) {
              return this.$message.error(
                "Veuillez ajouter les templates d'affichage des certificats "
              );
            }

            let certifImgUrl =
              this.settings.base_url +
              "/" +
              (student.gender == "male"
                ? certificate.fileM
                : certificate.fileF);
            students.push({
              ...student,
              certifImgUrl: certifImgUrl,
              certificate: certificate,
            });
          } catch (error) {
            console.log(error);
          }
        });
        if (students.length == 0) {
          this.printLoading = false;
          return this.$message.error(
            "Veuillez compléter les paramètres d'affichage des certificats "
          );
        }

        const doc = await drawAllCertificate(students);
        doc.save("certificates-" + this.selectedClassName.name + ".pdf");
        this.printLoading = false;
      }, 10);
    },

    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    moment,
  },
};
</script>
