<template>
  <div>
    <div style="margin: 15px">
      <h4>{{ $t("bulletin.performancesNotes") }}</h4>
    </div>

    <!--begin::Chart-->
    <apexchart
      class="statistics-widget-3-chart card-rounded-bottom"
      :options="options"
      :series="series"
      :height="300"
      type="line"
    ></apexchart>
    <!--end::Chart-->
  </div>
</template>

<script>
export default {
  props: ["bulletin"],
  data() {
    return {
      options: {},
      series: [],
      labelColor: "#3F4254",
      colors: ["#0095E8", "#67c23a", "#D9214E"],
      lightColors: ["#FFF5F8", "#e8fff3", "#F1FAFF"],
    };
  },

  watch: {
    bulletin: function (value) {
      this.updateChart();
    },
  },
  created() {
    if (this.bulletin) this.updateChart();
  },
  methods: {
    updateChart() {
      const subjectsSerie = [];
      const minSerie = [];
      const maxSerie = [];
      const studentSerie = [];

      if (this.bulletin && this.bulletin.modules) {
        for (const m of this.bulletin.modules) {
          for (const s of m.subjects) {
            subjectsSerie.push(s.name);
            minSerie.push(s.min);
            maxSerie.push(s.max);
            studentSerie.push(Number(s.mark));
          }
        }
        this.updateOptions(subjectsSerie);
        this.updateSeries(maxSerie, studentSerie, minSerie);
      }
    },

    updateSeries(maxSerie, studentSerie, minSerie) {
      this.series = [
        {
          name: this.$t("bulletin.notePlusHaute"),

          data: maxSerie,
        },
        {
          name: this.$t("bulletin.noteEleve"),
          data: studentSerie,
        },
        {
          name: this.$t("bulletin.notePlusBasse"),

          data: minSerie,
        },
      ];
    },
    updateOptions(subjectsSerie) {
      this.options = {
        chart: {
          height: 300,
          type: "line",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: false,
          },
        },

        stroke: {
          curve: "smooth",
          show: true,
          width: 3,
          color: this.colors,
        },
        xaxis: {
          type: "string",
          categories: subjectsSerie,
          labels: {
            show: false,
            style: {
              colors: this.labelColor,
              fontSize: "12px",
            },
          },
        },
        yaxis: [
          {
            labels: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
        tooltip: {
          style: {
            fontSize: "12px",
          },
          y: {},
        },
        fill: {
          type: "gradient",
          gradient: {
            stops: [0, 100],
          },
        },
        color: this.colors,
        markers: {
          color: this.colors,
          strokeColor: this.lightColors,
          strokeWidth: 3,
        },
      };
    },
  },
};
</script>
