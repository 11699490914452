<template>
  <div style="width: 100%">
    <div
      class="card"
      style="padding: 15px"
      v-if="classroomMarksStats && classroomMarksStats.classrooms"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="pr-3">
          <h2 class="font-size-18 font-weight-bold mb-1 text-dark">
            {{ $t("bulletin.classesActives") }}
          </h2>
          <p
            class="font-size-15 mb-3"
            v-if="classroomMarksStats.classrooms.length !== 0"
          >
            {{ $t("bulletin.nombreClassesNonNote") }}
          </p>
          <p class="font-size-15 mb-3" v-else>
            {{ $t("bulletin.tousClassesSontNotes") }}
          </p>
        </div>
        <div
          v-if="classroomMarksStats.classrooms.length !== 0"
          class="text-success font-weight-bold font-size-24"
        >
          {{ classroomMarksStats.classrooms.length }}
          {{ $t("bulletin.classes") }}
        </div>

        <div class="text-success font-weight-bold font-size-24" v-else>
          <a-icon type="check" />
        </div>
      </div>

      <div
        class="d-flex text-gray-5 justify-content-right font-size-14"
        v-if="classroomMarksStats.classrooms.length !== 0"
      >
        <a-button @click="visibleClassnameList = true">{{
          $t("action.afficher")
        }}</a-button>
      </div>
    </div>
    <div
      class="card"
      style="padding: 15px"
      v-if="selectedClasse && classroomMarksStats.students"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="pr-3">
          <h2 class="font-size-18 font-weight-bold mb-1 text-dark">
            {{ selectedClassName }}
          </h2>
          <p
            class="font-size-15 mb-3"
            v-if="
              classroomMarksStats.students[selectedClasse] &&
              classroomMarksStats.students[selectedClasse].length !== 0
            "
          >
            {{ $t("bulletin.nombreElevesNonNotes") }}
          </p>
          <p class="font-size-15 mb-3" v-else>
            {{ $t("bulletin.tousElevesSontNotes") }}
          </p>
        </div>
        <div
          v-if="
            classroomMarksStats.students[selectedClasse] &&
            classroomMarksStats.students[selectedClasse].length !== 0
          "
          class="text-success font-weight-bold font-size-24"
        >
          {{ classroomMarksStats.students[selectedClasse].length }}
          {{ $t("menu.eleves") }}
        </div>
        <div class="text-success font-weight-bold font-size-24" v-else>
          <a-icon type="check" />
        </div>
      </div>

      <div
        class="d-flex text-gray-5 justify-content-right font-size-14"
        v-if="classroomMarksStats.students[selectedClasse]"
      >
        <a-button @click="visibleStudentList = true">{{
          $t("action.afficher")
        }}</a-button>
      </div>
    </div>

    <a-divider v-if="selectedStudent && selectedClasse">
      <span class="font-size-18">{{ selectedStudentName }}</span>
    </a-divider>

    <div class="card" v-if="selectedStudent && selectedClasse">
      <div class="card-body">
        <template v-if="!loadingBulletin">
          <div style="font-size: 1.75rem; color: black">
            {{ $t("bulletin.moyenneTrimestre", { moyenne: bulletin.moyenne }) }}
          </div>
          <div>
            {{ $t("bulletin.moyenneMax", { moyenne: bulletin.maxMoyenne })
            }}<br />
            {{ $t("bulletin.moyenneMin", { moyenne: bulletin.minMoyenne }) }}
          </div>
        </template>
        <template v-else>
          <a-col :span="16" :offset="11">
            <a-spin size="large" />
          </a-col>
        </template>
      </div>
    </div>

    <div v-if="selectedStudent && selectedClasse">
      <div v-if="!loadingBulletin">
        <div class="card">
          <marksChart :bulletin="bulletin" :key="subjectModuleKey" />
        </div>
        <div class="card">
          <moduleChart :bulletin="bulletin" :key="subjectModuleKey" />
        </div>
      </div>
    </div>

    <a-modal
      v-model="visibleStudentList"
      :title="$t('bulletin.listeEleveNonNote')"
      :width="500"
      :height="700"
      :key="'studentListNotMarked'"
      @ok="
        () => {
          visibleStudentList = false;
        }
      "
      @cancel="
        () => {
          visibleStudentList = false;
        }
      "
    >
      <div
        :style="{ overflowY: 'scroll' }"
        v-if="selectedClasse && classroomMarksStats.students"
      >
        <div
          v-for="fullname in classroomMarksStats.students[selectedClasse]"
          :key="fullname"
        >
          {{ fullname }}
        </div>
      </div>
    </a-modal>
    <a-modal
      v-model="visibleClassnameList"
      :title="$t('bulletin.listeClassesNonNote')"
      :width="500"
      :height="700"
      :key="'classnameListNotMarked'"
      @ok="
        () => {
          visibleClassnameList = false;
        }
      "
      @cancel="
        () => {
          visibleClassnameList = false;
        }
      "
    >
      <div :style="{ overflowY: 'scroll' }">
        <div
          v-for="classname in classroomMarksStats.classrooms"
          :key="classname"
        >
          {{ classname }}
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import marksChart from "./marksChart.vue";
import moduleChart from "./moduleChart";
import apiClient from "@/services/axios";
export default {
  name: "stats",
  components: {
    marksChart,
    moduleChart,
  },
  props: {
    selectedClasse: {
      //done
      type: String,
      default: "",
    },
    selectedStudent: {
      //done
      type: String,
      default: "",
    },
    studentMarks: {
      //done
      required: false,
    },
    BulletinType: {
      //done
      required: false,
    },
    trimestre: {
      //done
      required: false,
    },
    listModules: {
      required: false,
    },
    loadingBulletin: {
      //done
      required: false,
    },
    subjects: {
      //done
      required: false,
    },
    bulletin: {
      required: false,
    },
    selectedStudentName: {
      //done
      required: false,
    },
    classroomMarksStats: {
      //done
      required: false,
    },
    selectedClassName: {
      //done
      required: false,
    },
  },
  data() {
    return {
      visibleStudentList: false,
      visibleClassnameList: false,
      subjectModuleKey: 1,
    };
  },
  watch: {
    bulletin: function (val) {
      this.subjectModuleKey++;
    },
  },
};
</script>
