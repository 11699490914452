var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 p-2"},[_c('h3',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t("bulletin.bulletinPedagogique")))])]),_c('div',{staticClass:"col-md-9"},[_c('a-select',{staticClass:"pb-2 pt-2",staticStyle:{"width":"100%"},attrs:{"size":"large","default-value":_vm.defaultLevel[_vm.schoolType]},on:{"change":_vm.handleLevelChangePeda}},_vm._l((_vm.levelSelectData[_vm.schoolType]),function(value,name,index){return _c('a-select-option',{key:index,attrs:{"value":name}},[_vm._v(" "+_vm._s(value))])}),1)],1)]),_c('div',{staticClass:"card p-4"},[(!_vm.loadingDataPeda)?[_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{on:{"change":(val) => {
                  if (!val.target.checked) _vm.targetDataPeda.identUnique = false;
                }},model:{value:(_vm.targetDataPeda.identUnique),callback:function ($$v) {_vm.$set(_vm.targetDataPeda, "identUnique", $$v)},expression:"targetDataPeda.identUnique"}},[_vm._v(" "+_vm._s(_vm.$t("liste.identifiantUnique"))+" ")])],1)]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{on:{"change":(val) => {
                  if (!val.target.checked)
                    _vm.targetDataPeda.numberStudentInsc = false;
                }},model:{value:(_vm.targetDataPeda.numberStudentInsc),callback:function ($$v) {_vm.$set(_vm.targetDataPeda, "numberStudentInsc", $$v)},expression:"targetDataPeda.numberStudentInsc"}},[_vm._v(" ﻋﺪﺩ ﺍﻟﺘﻼﻣﻴﺬ ﺍﻟﻤﺮﺳّﻤﻴﻦ ")])],1)])]:[_c('a-row',[_c('a-col',{attrs:{"span":16,"offset":11}},[_c('a-spin',{attrs:{"size":"large"}})],1)],1)]],2),_c('a-button',{staticStyle:{"margin-right":"5px","margin-bottom":"10px","background":"#8bc34a !important","border-color":"#8bc34a !important"},attrs:{"disabled":_vm.loadingSavePeda || _vm.loadingDataPeda,"loading":_vm.loadingSavePeda,"type":"primary"},on:{"click":_vm.saveChangePeda}},[_vm._v(" "+_vm._s(_vm.$t("all.save"))+" ")])],1),_c('a-divider'),_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 p-2"},[_c('h3',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t("bulletin.bulletinSpecifique")))])]),_c('div',{staticClass:"col-md-9"},[_c('a-select',{staticClass:"pb-2 pt-2",staticStyle:{"width":"100%"},attrs:{"size":"large","default-value":_vm.defaultLevel[_vm.schoolType]},on:{"change":_vm.handleLevelChange}},_vm._l((_vm.levelSelectData[_vm.schoolType]),function(value,name,index){return _c('a-select-option',{key:index,attrs:{"value":name}},[_vm._v(" "+_vm._s(value))])}),1)],1)]),_c('div',{staticClass:"card p-4"},[(!_vm.loadingData)?[_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{on:{"change":(val) => {
                  if (!val.target.checked) _vm.targetData.logo = false;
                }},model:{value:(_vm.targetData.logo),callback:function ($$v) {_vm.$set(_vm.targetData, "logo", $$v)},expression:"targetData.logo"}},[_vm._v(" Logo de l'école ")])],1)]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{on:{"change":(val) => {
                  if (!val.target.checked) _vm.targetData.personalized = false;
                }},model:{value:(_vm.targetData.specific),callback:function ($$v) {_vm.$set(_vm.targetData, "specific", $$v)},expression:"targetData.specific"}},[_vm._v(" "+_vm._s(_vm.$t("bulletin.moyenneSpecifique"))+" ")])],1),(_vm.targetData.personalized)?_c('div',{staticClass:"col-3"},[_c('a-label',[_vm._v(_vm._s(_vm.$t("scolarite.coefficient"))+" : ")]),_c('a-input-number',{attrs:{"disabled":!_vm.targetData.specific},model:{value:(_vm.targetData.specificCoef),callback:function ($$v) {_vm.$set(_vm.targetData, "specificCoef", $$v)},expression:"targetData.specificCoef"}})],1):_vm._e(),_c('div',{staticClass:"col-5"},[_c('a-label',[_vm._v(_vm._s(_vm.$t("bulletin.label"))+" : ")]),_c('a-input',{staticStyle:{"width":"85%"},attrs:{"maxLength":20,"disabled":!_vm.targetData.specific},model:{value:(_vm.targetData.specificName),callback:function ($$v) {_vm.$set(_vm.targetData, "specificName", $$v)},expression:"targetData.specificName"}})],1)]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{on:{"change":(val) => {
                  if (!val.target.checked) _vm.targetData.personalized = false;
                }},model:{value:(_vm.targetData.pedagogic),callback:function ($$v) {_vm.$set(_vm.targetData, "pedagogic", $$v)},expression:"targetData.pedagogic"}},[_vm._v(" "+_vm._s(_vm.$t("bulletin.moyennePedagogique"))+" ")])],1),(_vm.targetData.personalized)?_c('div',{staticClass:"col-3"},[_c('a-label',[_vm._v(_vm._s(_vm.$t("scolarite.coefficient"))+" : ")]),_c('a-input-number',{attrs:{"disabled":!_vm.targetData.pedagogic},model:{value:(_vm.targetData.pedagogicCoef),callback:function ($$v) {_vm.$set(_vm.targetData, "pedagogicCoef", $$v)},expression:"targetData.pedagogicCoef"}})],1):_vm._e(),_c('div',{staticClass:"col-5"},[_c('a-label',[_vm._v(_vm._s(_vm.$t("bulletin.label"))+" : ")]),_c('a-input',{staticStyle:{"width":"85%"},attrs:{"maxLength":20,"disabled":!_vm.targetData.pedagogic},model:{value:(_vm.targetData.pedagogicName),callback:function ($$v) {_vm.$set(_vm.targetData, "pedagogicName", $$v)},expression:"targetData.pedagogicName"}})],1)]),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{on:{"change":(val) => {
                  if (!val.target.checked) _vm.targetData.certif = false;
                }},model:{value:(_vm.targetData.certif),callback:function ($$v) {_vm.$set(_vm.targetData, "certif", $$v)},expression:"targetData.certif"}},[_vm._v(" "+_vm._s(_vm.$t("scolarite.certification"))+" ")])],1)]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{on:{"change":(val) => {
                  if (!val.target.checked) _vm.targetData.identUnique = false;
                }},model:{value:(_vm.targetData.identUnique),callback:function ($$v) {_vm.$set(_vm.targetData, "identUnique", $$v)},expression:"targetData.identUnique"}},[_vm._v(" "+_vm._s(_vm.$t("liste.identifiantUnique"))+" ")])],1)]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{on:{"change":(val) => {
                  if (!val.target.checked)
                    _vm.targetData.numberStudentInsc = false;
                }},model:{value:(_vm.targetData.numberStudentInsc),callback:function ($$v) {_vm.$set(_vm.targetData, "numberStudentInsc", $$v)},expression:"targetData.numberStudentInsc"}},[_vm._v(" ﻋﺪﺩ ﺍﻟﺘﻼﻣﻴﺬ ﺍﻟﻤﺮﺳّﻤﻴﻦ ")])],1)]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{on:{"change":(val) => {
                  if (!val.target.checked) _vm.targetData.fuseSubSubject = false;
                }},model:{value:(_vm.targetData.fuseSubSubject),callback:function ($$v) {_vm.$set(_vm.targetData, "fuseSubSubject", $$v)},expression:"targetData.fuseSubSubject"}},[_vm._v(" "+_vm._s(_vm.$t("bulletin.fuseSubSubject"))+" ")])],1)]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{on:{"change":(val) => {
                  if (!val.target.checked) _vm.targetData.hideSubject = false;
                }},model:{value:(_vm.targetData.hideSubject),callback:function ($$v) {_vm.$set(_vm.targetData, "hideSubject", $$v)},expression:"targetData.hideSubject"}},[_vm._v(" "+_vm._s(_vm.$t("bulletin.hideSubject"))+" ")])],1)]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{on:{"change":(val) => {
                  if (!val.target.checked) _vm.targetData.photo = false;
                }},model:{value:(_vm.targetData.photo),callback:function ($$v) {_vm.$set(_vm.targetData, "photo", $$v)},expression:"targetData.photo"}},[_vm._v(" "+_vm._s(_vm.$t("enseignant.photo"))+" ")])],1)]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{on:{"change":(val) => {
                  if (!val.target.checked) _vm.targetData.hideDate = false;
                }},model:{value:(_vm.targetData.hideDate),callback:function ($$v) {_vm.$set(_vm.targetData, "hideDate", $$v)},expression:"targetData.hideDate"}},[_vm._v(" "+_vm._s(_vm.$t("bulletin.hideDate"))+" ")])],1)]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{attrs:{"disabled":!_vm.targetData.specific || !_vm.targetData.pedagogic},model:{value:(_vm.targetData.personalized),callback:function ($$v) {_vm.$set(_vm.targetData, "personalized", $$v)},expression:"targetData.personalized"}},[_vm._v(" "+_vm._s(_vm.$t("bulletin.moyennePersonalized"))+" ")])],1),_c('div',{staticClass:"col-4"},[_c('a-label',[_vm._v(_vm._s(_vm.$t("bulletin.label"))+" : ")]),_c('a-input',{staticStyle:{"width":"85%"},attrs:{"maxLength":30,"disabled":!_vm.targetData.personalized ||
                !_vm.targetData.specific ||
                !_vm.targetData.pedagogic},model:{value:(_vm.targetData.personalizedName),callback:function ($$v) {_vm.$set(_vm.targetData, "personalizedName", $$v)},expression:"targetData.personalizedName"}})],1),(_vm.targetData.personalized)?_c('div',{staticClass:"col-7 mt-2"},[_c('a-label',[_vm._v(_vm._s(_vm.$t("bulletin.formulePersonalized"))+" : ")]),_c('a-tag',{staticStyle:{"font-size":"15px"},attrs:{"color":"blue"}},[_vm._v(" ( "+_vm._s(_vm.$t("bulletin.moyenneSpecifique"))+" * "+_vm._s(_vm.targetData.specificCoef)+" + "+_vm._s(_vm.$t("bulletin.moyennePedagogique"))+" * "+_vm._s(_vm.targetData.pedagogicCoef)+" ) / "+_vm._s(_vm.targetData.specificCoef + _vm.targetData.pedagogicCoef)+" ")])],1):_vm._e()])]:[_c('a-row',[_c('a-col',{attrs:{"span":16,"offset":11}},[_c('a-spin',{attrs:{"size":"large"}})],1)],1)]],2),_c('a-button',{staticStyle:{"margin-right":"5px","margin-bottom":"10px","background":"#8bc34a !important","border-color":"#8bc34a !important"},attrs:{"disabled":_vm.loadingSave || _vm.loadingData,"loading":_vm.loadingSave,"type":"primary"},on:{"click":_vm.saveChange}},[_vm._v(" "+_vm._s(_vm.$t("all.save"))+" ")])],1),_c('a-divider'),_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 p-2"},[_c('h3',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t("bulletin.bulletinVerySpecifique")))])]),_c('div',{staticClass:"col-md-9"},[_c('a-select',{staticClass:"pb-2 pt-2",staticStyle:{"width":"100%"},attrs:{"size":"large","default-value":_vm.defaultLevel[_vm.schoolType]},on:{"change":_vm.handleLevelChangeVerySpecific}},_vm._l((_vm.levelSelectData[_vm.schoolType]),function(value,name,index){return _c('a-select-option',{key:index,attrs:{"value":name}},[_vm._v(" "+_vm._s(value))])}),1)],1)]),_c('div',{staticClass:"card p-4"},[(!_vm.loadingData2)?[_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{on:{"change":(val) => {
                  if (!val.target.checked) _vm.targetData2.logo = false;
                }},model:{value:(_vm.targetData2.logo),callback:function ($$v) {_vm.$set(_vm.targetData2, "logo", $$v)},expression:"targetData2.logo"}},[_vm._v(" Logo de l'école ")])],1)]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{on:{"change":(val) => {
                  if (!val.target.checked) _vm.targetData2.personalized = false;
                }},model:{value:(_vm.targetData2.verySpecific),callback:function ($$v) {_vm.$set(_vm.targetData2, "verySpecific", $$v)},expression:"targetData2.verySpecific"}},[_vm._v(" "+_vm._s(_vm.$t("bulletin.moyenneVerySpecifique"))+" ")])],1),(
              _vm.targetData2.personalized &&
              _vm.schoolDetails.dbName !== 'everestAcademy'
            )?_c('div',{staticClass:"col-3"},[_c('a-label',[_vm._v(_vm._s(_vm.$t("scolarite.coefficient"))+" : ")]),_c('a-input-number',{attrs:{"disabled":!_vm.targetData2.verySpecific},model:{value:(_vm.targetData2.verySpecificCoef),callback:function ($$v) {_vm.$set(_vm.targetData2, "verySpecificCoef", $$v)},expression:"targetData2.verySpecificCoef"}})],1):_vm._e(),_c('div',{staticClass:"col-5"},[_c('a-label',[_vm._v(_vm._s(_vm.$t("bulletin.label"))+" : ")]),_c('a-input',{staticStyle:{"width":"85%"},attrs:{"maxLength":20,"disabled":!_vm.targetData2.verySpecific},model:{value:(_vm.targetData2.verySpecificName),callback:function ($$v) {_vm.$set(_vm.targetData2, "verySpecificName", $$v)},expression:"targetData2.verySpecificName"}})],1)]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{on:{"change":(val) => {
                  if (!val.target.checked) _vm.targetData2.personalized = false;
                }},model:{value:(_vm.targetData2.pedagogic),callback:function ($$v) {_vm.$set(_vm.targetData2, "pedagogic", $$v)},expression:"targetData2.pedagogic"}},[_vm._v(" "+_vm._s(_vm.$t("bulletin.moyennePedagogique"))+" ")])],1),(
              _vm.targetData2.personalized &&
              _vm.schoolDetails.dbName !== 'everestAcademy'
            )?_c('div',{staticClass:"col-3"},[_c('a-label',[_vm._v(_vm._s(_vm.$t("scolarite.coefficient"))+" : ")]),_c('a-input-number',{attrs:{"disabled":!_vm.targetData2.pedagogic},model:{value:(_vm.targetData2.pedagogicCoef),callback:function ($$v) {_vm.$set(_vm.targetData2, "pedagogicCoef", $$v)},expression:"targetData2.pedagogicCoef"}})],1):_vm._e(),_c('div',{staticClass:"col-5"},[_c('a-label',[_vm._v(_vm._s(_vm.$t("bulletin.label"))+" : ")]),_c('a-input',{staticStyle:{"width":"85%"},attrs:{"maxLength":20,"disabled":!_vm.targetData2.pedagogic},model:{value:(_vm.targetData2.pedagogicName),callback:function ($$v) {_vm.$set(_vm.targetData2, "pedagogicName", $$v)},expression:"targetData2.pedagogicName"}})],1)]),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{on:{"change":(val) => {
                  if (!val.target.checked) _vm.targetData2.certif = false;
                }},model:{value:(_vm.targetData2.certif),callback:function ($$v) {_vm.$set(_vm.targetData2, "certif", $$v)},expression:"targetData2.certif"}},[_vm._v(" "+_vm._s(_vm.$t("scolarite.certification"))+" ")])],1)]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{on:{"change":(val) => {
                  if (!val.target.checked) _vm.targetData2.identUnique = false;
                }},model:{value:(_vm.targetData2.identUnique),callback:function ($$v) {_vm.$set(_vm.targetData2, "identUnique", $$v)},expression:"targetData2.identUnique"}},[_vm._v(" "+_vm._s(_vm.$t("liste.identifiantUnique"))+" ")])],1)]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{on:{"change":(val) => {
                  if (!val.target.checked)
                    _vm.targetData2.numberStudentInsc = false;
                }},model:{value:(_vm.targetData2.numberStudentInsc),callback:function ($$v) {_vm.$set(_vm.targetData2, "numberStudentInsc", $$v)},expression:"targetData2.numberStudentInsc"}},[_vm._v(" ﻋﺪﺩ ﺍﻟﺘﻼﻣﻴﺬ ﺍﻟﻤﺮﺳّﻤﻴﻦ ")])],1)]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{on:{"change":(val) => {
                  if (!val.target.checked) _vm.targetData2.fuseSubSubject = false;
                }},model:{value:(_vm.targetData2.fuseSubSubject),callback:function ($$v) {_vm.$set(_vm.targetData2, "fuseSubSubject", $$v)},expression:"targetData2.fuseSubSubject"}},[_vm._v(" "+_vm._s(_vm.$t("bulletin.fuseSubSubject"))+" ")])],1)]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{on:{"change":(val) => {
                  if (!val.target.checked) _vm.targetData2.hideSubject = false;
                }},model:{value:(_vm.targetData2.hideSubject),callback:function ($$v) {_vm.$set(_vm.targetData2, "hideSubject", $$v)},expression:"targetData2.hideSubject"}},[_vm._v(" "+_vm._s(_vm.$t("bulletin.hideSubject"))+" ")])],1)]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{on:{"change":(val) => {
                  if (!val.target.checked) _vm.targetData2.photo = false;
                }},model:{value:(_vm.targetData2.photo),callback:function ($$v) {_vm.$set(_vm.targetData2, "photo", $$v)},expression:"targetData2.photo"}},[_vm._v(" "+_vm._s(_vm.$t("enseignant.photo"))+" ")])],1)]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{on:{"change":(val) => {
                  if (!val.target.checked) _vm.targetData2.hideDate = false;
                }},model:{value:(_vm.targetData2.hideDate),callback:function ($$v) {_vm.$set(_vm.targetData2, "hideDate", $$v)},expression:"targetData2.hideDate"}},[_vm._v(" "+_vm._s(_vm.$t("bulletin.hideDate"))+" ")])],1)]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-3"},[_c('a-checkbox',{attrs:{"disabled":!_vm.targetData2.verySpecific || !_vm.targetData2.pedagogic},model:{value:(_vm.targetData2.personalized),callback:function ($$v) {_vm.$set(_vm.targetData2, "personalized", $$v)},expression:"targetData2.personalized"}},[_vm._v(" "+_vm._s(_vm.$t("bulletin.moyennePersonalized"))+" ")])],1),_c('div',{staticClass:"col-4"},[_c('a-label',[_vm._v(_vm._s(_vm.$t("bulletin.label"))+" : ")]),_c('a-input',{staticStyle:{"width":"85%"},attrs:{"maxLength":30,"disabled":!_vm.targetData2.personalized ||
                !_vm.targetData2.verySpecific ||
                !_vm.targetData2.pedagogic},model:{value:(_vm.targetData2.personalizedName),callback:function ($$v) {_vm.$set(_vm.targetData2, "personalizedName", $$v)},expression:"targetData2.personalizedName"}})],1),(_vm.targetData2.personalized)?_c('div',{staticClass:"col-12 mt-2"},[_c('a-label',[_vm._v(_vm._s(_vm.$t("bulletin.formulePersonalized"))+" : ")]),_c('a-tag',{staticStyle:{"font-size":"15px"},attrs:{"color":"blue"}},[_c('span',{attrs:{"dir":"ltr"},domProps:{"innerHTML":_vm._s(_vm.formattedText)}})])],1):_vm._e()])]:[_c('a-row',[_c('a-col',{attrs:{"span":16,"offset":11}},[_c('a-spin',{attrs:{"size":"large"}})],1)],1)]],2),_c('a-button',{staticStyle:{"margin-right":"5px","margin-bottom":"10px","background":"#8bc34a !important","border-color":"#8bc34a !important"},attrs:{"disabled":_vm.loadingSave2 || _vm.loadingData2,"loading":_vm.loadingSave2,"type":"primary"},on:{"click":_vm.saveChangeVerySpecific}},[_vm._v(" "+_vm._s(_vm.$t("all.save"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }