<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-md-4">
          <a-select
            id="typeJournal"
            show-search
            allow-clear
            mode="multiple"
            :placeholder="$t('recette.typeJournal')"
            option-filter-prop="children"
            style="width: 100%"
            :filter-option="filterOption"
            @change="
              (val) => {
                handleChange('tags', val);
              }
            "
          >
            <a-select-option
              v-for="(item, key) in recipetTypes"
              :key="key"
              :value="key"
            >
              {{
                item == "espéce"
                  ? $t("recette.espece")
                  : item == "chèque"
                  ? $t("recette.cheque")
                  : item == "Chèque et espéce"
                  ? $t("recette.chequeEtEspece")
                  : item == "Activité"
                  ? $t("recette.activite")
                  : item == "Scolarité"
                  ? $t("menu.scolarite")
                  : item == "Récus"
                  ? $t("recette.recus")
                  : item == "Facture"
                  ? $t("recette.factureTag")
                  : item == "Tous"
                  ? $t("recette.tous")
                  : item
              }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-md-8">
          <a-range-picker
            style="width: 100%"
            :placeholder="[$t('paiement.dateDebut'), $t('paiement.dateFin')]"
            format="DD-MM-YYYY"
            :value="monthRangeData"
            :mode="mode"
            @panelChange="
              (val) => {
                handleChange('date', val);
              }
            "
            @change="
              (val) => {
                handleChange('date', val);
              }
            "
          />
        </div>
      </div>
      <div class="row justify-content-end mt-3">
        <div class="col-4 col-sm-3">
          <json-csv
            class="ant-btn"
            style="width: 100%"
            :fields="fields"
            :labels="labels"
            :data="csvTable"
            :name="'journalCaisse.csv'"
            ><a-icon
              class="anticon mt-2"
              style="color: green"
              type="file-excel"
            /><span>CSV</span></json-csv
          >
        </div>
        <a-button-group class="mr-3">
          <a-button
            type="primary"
            @click="imprimer"
            :loading="tableLoading || btnLoading"
            ><a-icon type="printer" />{{ $t("action.imprimerTab") }}</a-button
          >
          <a-button
            type="primary"
            @click="imprimerBrouillardCaisse"
            :loading="tableLoading || btnLoading"
            ><a-icon type="cloud-download" />{{
              $t("recette.bouillard")
            }}</a-button
          >
        </a-button-group>
      </div>
    </div>
    <div class="card-body">
      <a-table
        @change="tableChanged"
        rowKey="_id"
        id="help"
        :pagination="true"
        :data-source="data"
        :columns="columns"
        :loading="tableLoading"
        :scroll="{ x: 'max-content' }"
      >
        <template slot="date" slot-scope="text">
          {{ moment(text).format("DD/MM/YYYY") }}
        </template>
        <template slot="modePaiement" slot-scope="text, record">
          <a-button
            icon="search"
            type="dashed"
            block
            v-if="record.cheque"
            @click="chequeDetailsModal(record)"
          >
            {{
              text == "espéce"
                ? $t("recette.espece")
                : text == "chèque"
                ? $t("recette.cheque")
                : text == "Chèque et espéce"
                ? $t("recette.chequeEtEspece")
                : text
            }}
          </a-button>
          <div v-else>
            {{
              text == "espéce"
                ? $t("recette.espece")
                : text == "chèque"
                ? $t("recette.cheque")
                : text == "Chèque et espéce"
                ? $t("recette.chequeEtEspece")
                : text
            }}
          </div>
        </template>
        <template slot="cheque" slot-scope="text">
          <div v-if="text">{{ text }}</div>
          <div v-else>--</div>
        </template>
        <a-tag color="#2db7f5" slot="userName" slot-scope="text">
          {{ text ? text : "--" }}
        </a-tag>
        <div slot="tags" slot-scope="text, record">
          <div class="d-block">
            <span v-for="item in record.tags" :key="item">
              <a-tag color="blue" v-if="item != 'all'">
                {{
                  recipetTypes[item] == "espéce"
                    ? $t("recette.espece")
                    : recipetTypes[item] == "chèque"
                    ? $t("recette.cheque")
                    : recipetTypes[item] == "Chèque et espéce"
                    ? $t("recette.chequeEtEspece")
                    : recipetTypes[item] == "Activité"
                    ? $t("recette.activite")
                    : recipetTypes[item] == "Scolarité"
                    ? $t("menu.scolarite")
                    : recipetTypes[item] == "Récus"
                    ? $t("recette.recus")
                    : recipetTypes[item] == "Facture"
                    ? $t("recette.factureTag")
                    : recipetTypes[item] == "Tous"
                    ? $t("recette.tous")
                    : recipetTypes[item]
                }}
              </a-tag>
            </span>
            <span v-if="record.status === 'cancelled'">
              <a-tag color="red">
                {{ $t("recette.annule") }}
              </a-tag>
              <a-tag v-if="record.type == 'Avoir'" color="orange">
                {{ $t("paiement.avoir") }}
              </a-tag>
            </span>
          </div>
        </div>
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`${$t('personnel.chercher')} ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >{{ $t("paiement.chercher") }}</a-button
          >
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
            >{{ $t("action.reinitialiser") }}</a-button
          >
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>{{ text }}</template>
        </template>
      </a-table>
    </div>
    <a-modal
      v-model="chequeVisible"
      :title="$t('recette.detailsCheque')"
      @ok="handleOk"
      @cancel="handleOk"
      :cancelText="$t('action.annuler')"
      :okText="$t('recette.ok')"
    >
      <div v-if="activeCheque.montantCheque || activeCheque.montantEspece">
        <a-descriptions>
          <a-descriptions-item span="2">
            <template v-slot:label>
              <span class="ant-descriptions-item-label">Montant chèque :</span>
            </template>
            {{ activeCheque.montantCheque }}
          </a-descriptions-item>
          <a-descriptions-item span="3" v-if="activeCheque.montantEspece">
            <template v-slot:label>
              <span class="ant-descriptions-item-label">Montant espèce :</span>
            </template>
            {{ activeCheque.montantEspece }}
          </a-descriptions-item>
        </a-descriptions>
        <a-divider></a-divider>
      </div>

      <div>
        <div v-if="loadedChequeData">
          <a-descriptions>
            <a-descriptions-item>
              <template v-slot:label>
                <span class="ant-descriptions-item-label">{{
                  $t("recette.chequeMontantTotal")
                }}</span>
              </template>
              <a-tag
                >{{
                  loadedChequeData.montant || activeCheque.montantCheque
                }}
                TND</a-tag
              >
            </a-descriptions-item>
          </a-descriptions>
          <a-descriptions>
            <a-descriptions-item
              stlye="width:100%"
              v-if="
                loadedChequeData.relatedTransactions &&
                loadedChequeData.relatedTransactions.length > 0
              "
            >
              <template v-slot:label>
                <span class="ant-descriptions-item-label">{{
                  $t("recette.relatedPayments")
                }}</span>
              </template>
              <table class="table table-striped" stlye="width:100%">
                <thead>
                  <tr>
                    <th>{{ $t("recette.numSerie") }}</th>
                    <th>{{ $t("comptabilite.nom") }}</th>
                    <th>{{ $t("recette.montantCheque") }} TND</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="t in loadedChequeData.relatedTransactions"
                    :key="t._id"
                  >
                    <td>{{ t.serial }}</td>
                    <td>
                      {{
                        t.typeAS == "A"
                          ? "Activity/" + t.payment.activity.designation
                          : "Scolarité/" + t.payment.classroom.name
                      }}
                    </td>
                    <td>{{ t.montantCheque }}</td>
                  </tr>
                </tbody>
              </table>
            </a-descriptions-item>
          </a-descriptions>
        </div>
        <div v-else>
          <a-skeleton active :paragraph="{ rows: 1 }" />
        </div>
        <a-divider></a-divider>
      </div>

      <a-descriptions>
        <a-descriptions-item span="2">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.numero")
            }}</span>
          </template>
          {{ activeCheque.num }}
        </a-descriptions-item>
        <a-descriptions-item span="3">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.titulaire")
            }}</span>
          </template>
          {{ activeCheque.titulaire }}
        </a-descriptions-item>
        <a-descriptions-item span="4">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.banque")
            }}</span>
          </template>
          <a-tag color="blue">
            {{ activeCheque.bank }}
          </a-tag>
        </a-descriptions-item>

        <a-descriptions-item span="4">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.dateEcheance")
            }}</span>
          </template>
          {{ moment(activeCheque.dateEchance).format("DD/MM/YYYY") }}
        </a-descriptions-item>
      </a-descriptions>
      <a-alert
        :message="activeCheque.diff"
        type="info"
        :close-text="$t('recette.fermer')"
      />
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import moment from "moment";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { mapState } from "vuex";
import JsonCsv from "vue-json-csv";
const recipetTypes = {
  all: "Tous",
  activity: "Activité",
  scolarity: "Scolarité",
  receipt: "Récus",
  bill: "Facture",
};

export default {
  components: {
    JsonCsv,
  },
  async created() {
    this.tableLoading = true;

    await this.getRecipetsType();
    await this.getTransactions();
    await this.getCancellations();

    this.tableLoading = false;
    this.filtredTable = this.data.map((item) => {
      item.montantTTC = item.montantTTC.toFixed(2);
      item.montantHT = item.montantHT
        ? item.montantHT.toFixed(2)
        : item.montantHT;
      return item;
    });
  },
  computed: {
    ...mapState(["settings"]),
    csvTable() {
      var data = this.filtredTable.map((item) => {
        let returnItem = { ...item };
        returnItem.NumCheque = item.cheque ? item.cheque.num : "--";

        returnItem.date = moment(item.date).format("DD/MM/YYYY");
        return returnItem;
      });
      return data;
    },
  },
  data() {
    return {
      loadedChequeData: null,
      classList: [],
      activities: [],
      btnLoading: false,
      depenseData: [],
      dataEmp: [],
      labels: {
        nomclient: "Nom client",
        typeReglement: "Type Reglement",
        modePaiement: "Mode paiement",
        montantHT: "Montant HT",
        montantTTC: "Montant TTC",
        NumCheque: "Numéro du chèque",

        date: "Date de paiement",
      },
      fields: [
        "nomClient",
        "typeReglement",
        "modePaiement",
        "montantHT",
        "montantTTC",
        "date",
        "NumCheque",
      ],
      recipetTypes,
      reglementAnnuler: [],
      data: [],
      mode: ["date", "date"],
      rowData: [],
      monthRangeData: [],
      cacheData: [],
      chequeVisible: false,
      activeCheque: {},
      filters: {
        date: false,
        date_val: null,
        tags: false,
        tags_val: null,
      },
      columns: [
        {
          title: this.$t("recette.numero"),
          dataIndex: "serial",
          key: "serial",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.serial.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.nom"),
          dataIndex: "nomClient",
          key: "nomClient",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "nomClient",
          },
          onFilter: (value, record) =>
            record.nomClient.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.typeReglement"),
          dataIndex: "typeReglement",
          key: "typeReglement",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "typeReglement",
          },
          onFilter: (value, record) =>
            record.typeReglement.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.modePaiement"),
          dataIndex: "modePaiement",
          key: "modePaiement",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "modePaiement",
          },
          onFilter: (value, record) => {
            if (record.cheque) {
              return (
                record.modePaiement
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                record.cheque.num.toLowerCase().includes(value.toLowerCase()) ||
                record.cheque.bank
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                record.cheque.titulaire
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                record.cheque.dateEchance
                  .toLowerCase()
                  .includes(value.toLowerCase())
              );
            } else {
              return;
              record.modePaiement.toLowerCase().includes(value.toLowerCase());
            }
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.montantHT"),
          dataIndex: "montantHT",
          key: "montantHT",
          sorter: (a, b) => a.montantHT - b.montantHT,
        },
        {
          title: this.$t("recette.montantTTC"),
          dataIndex: "montantTTC",
          key: "montantTTC",
          sorter: (a, b) => a.montantTTC - b.montantTTC,
        },
        {
          //Mots clés
          title: this.$t("recette.motCles"),
          dataIndex: "tags",
          key: "tags",
          scopedSlots: {
            customRender: "tags",
          },
        },
        {
          $lookup: {
            from: "services",
            localField: "activity.service",
            foreignField: "_id",
            as: "service",
          },
        },
        {
          title: this.$t("recette.datePaiement"),
          dataIndex: "date",
          key: "date",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "date",
          },
          onFilter: (value, record) => {
            return moment(record.date).format("DD/MM/YYYY").includes(value);
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.doneBy"),
          dataIndex: "userName",
          key: "userName",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "userName",
          },
          onFilter: (value, record) => {
            return (
              record.userName &&
              record.userName.toLowerCase().includes(value.toLowerCase())
            );
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.numCheque"),
          dataIndex: "cheque.num",
          key: "cheque",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "cheque",
          },
          onFilter: (value, record) =>
            record.cheque.num.includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
      ],
      filtredTable: [],
    };
  },
  methods: {
    chequeDetailsModal(record) {
      let dateEchance = record.cheque.dateEchance
        ? moment(record.cheque.dateEchance).format("DD/MM/YYYY")
        : "--";

      let difference = moment(record.cheque.dateEchance).fromNow();
      record.cheque.dateEchance = dateEchance;
      this.activeCheque = record.cheque;
      this.activeCheque.diff = difference;
      this.activeCheque.montantCheque = record.montantCheque;
      this.activeCheque.montantEspece = record.montantEspece;
      this.chequeVisible = true;

      this.activeCheque.transactionId = record._id;
      this.getChequeDetails(record.cheque._id);
    },
    getChequeDetails(id) {
      apiClient
        .get("/cheque/" + id)
        .then((res) => {
          this.loadedChequeData = res.data;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    handleOk(e) {
      this.chequeVisible = false;
      this.loadedChequeData = null;
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    moment,
    imprimer() {
      this.$gtag.event("Imp Journal Caisse", {
        event_category: "Impression PDF",
        event_label: "comptabilite:JournalCaisse",
        value: 1,
      });

      this.btnLoading = true;
      let typeJournal = document
        .getElementById("typeJournal")
        .innerText.replace(/\n/g, ",");
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      let filterText = "";
      if (this.monthRangeData[0])
        filterText =
          "De " +
          this.monthRangeData[0]._d.toLocaleDateString("fr-FR", {
            month: "long",
            year: "numeric",
          }) +
          " à " +
          this.monthRangeData[1]._d.toLocaleDateString("fr-FR", {
            month: "long",
            year: "numeric",
          });
      var titre = typeJournal === "Type journale" ? "Tous" : typeJournal; //Type journale
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(15);
      doc.text(70, 60, "Journal caisse - " + titre);
      doc.text(89, 70, filterText);
      doc.setFontSize(10);
      try {
        doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      } catch (error) {
        console.log(error);
      }
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      doc.setFontSize(8);
      doc.setFillColor("#FFCCCC");
      doc.setDrawColor("#FFCCCC");
      doc.rect(15, 74, 4, 4, "FD");
      doc.text(22, 77, "Annulation");
      doc.setFillColor("#FFCC66");
      doc.setDrawColor("#FFCC66");
      doc.rect(40, 74, 4, 4, "FD");
      doc.text(47, 77, "Avoir");
      doc.setFontSize(15);
      var arr = [];
      const array = [];
      let sommeHT = 0;
      let sommeTTC = 0;
      for (let i = 0; i < this.filtredTable.length; i++) {
        let studentName = this.filtredTable[i].student
          ? this.filtredTable[i].student.lastName +
            " " +
            this.filtredTable[i].student.firstName
          : "--";
        if (this.filtredTable[i].status === "cancelled") {
          arr.push({
            content: this.filtredTable[i].serial,
            styles: {
              fillColor:
                this.filtredTable[i].type == "Avoir"
                  ? [255, 234, 153]
                  : [255, 204, 204],
            },
          });
          arr.push({
            content: this.filtredTable[i].nomClient,
            styles: {
              fillColor:
                this.filtredTable[i].type == "Avoir"
                  ? [255, 234, 153]
                  : [255, 204, 204],
            },
          });
          arr.push({
            content: studentName,
            styles: {
              fillColor:
                this.filtredTable[i].type == "Avoir"
                  ? [255, 234, 153]
                  : [255, 204, 204],
            },
          });
          arr.push({
            content: this.filtredTable[i].typeReglement,
            styles: {
              fillColor:
                this.filtredTable[i].type == "Avoir"
                  ? [255, 234, 153]
                  : [255, 204, 204],
            },
          });
          arr.push({
            content: this.filtredTable[i].modePaiement,
            styles: {
              fillColor:
                this.filtredTable[i].type == "Avoir"
                  ? [255, 234, 153]
                  : [255, 204, 204],
            },
          });
          arr.push({
            content: this.filtredTable[i].cheque
              ? this.filtredTable[i].cheque.num
              : "--",
            styles: {
              fillColor:
                this.filtredTable[i].type == "Avoir"
                  ? [255, 234, 153]
                  : [255, 204, 204],
            },
          });
          arr.push({
            content: "--",
            styles: {
              fillColor:
                this.filtredTable[i].type == "Avoir"
                  ? [255, 234, 153]
                  : [255, 204, 204],
            },
          });
          arr.push({
            content: this.filtredTable[i].montantTTC,
            styles: {
              fillColor:
                this.filtredTable[i].type == "Avoir"
                  ? [255, 234, 153]
                  : [255, 204, 204],
            },
          });
          sommeTTC += parseFloat(this.filtredTable[i].montantTTC);
          array.push(arr);
          arr = [];
        } else {
          arr.push(this.filtredTable[i].serial);
          arr.push(this.filtredTable[i].nomClient);
          arr.push(studentName);

          arr.push(this.filtredTable[i].typeReglement);
          arr.push(this.filtredTable[i].modePaiement);
          arr.push(
            this.filtredTable[i].cheque ? this.filtredTable[i].cheque.num : "--"
          );
          arr.push(this.filtredTable[i].montantHT);
          sommeHT += parseFloat(this.filtredTable[i].montantHT);
          arr.push(this.filtredTable[i].montantTTC);
          sommeTTC += parseFloat(this.filtredTable[i].montantTTC);
          array.push(arr);
          arr = [];
        }
      }
      doc.autoTable({
        styles: {
          lineWidth: 0.2,
          valign: "middle",
          halign: "center",
          fontStyle: "bold",
          font: "Amiri",
        },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        head: [
          [
            "Numéro",
            "Client",
            "Enfant",
            "Type réglement",
            "Mode paiement",
            "Numéro du chèque",

            "Montant HT",
            "Montant TTC",
          ],
        ],
        body: array,
        foot: [
          [
            { colSpan: 6, content: "Total", styles: { halign: "center" } },
            parseFloat(sommeHT).toFixed(2),
            parseFloat(sommeTTC).toFixed(2),
          ],
        ],
      });
      //reglement annulé table
      doc.addPage();
      var reglementAnuul = [];
      sommeTTC = 0;
      for (let i = 0; i < this.reglementAnnuler.length; i++) {
        if (!this.reglementAnnuler[i].avoir) {
          arr.push(this.reglementAnnuler[i].serial);
          arr.push(this.reglementAnnuler[i].clientName);
          arr.push(this.reglementAnnuler[i].type);
          arr.push(this.reglementAnnuler[i].bill == "Oui" ? "Facture" : "Reçu");
          arr.push(this.reglementAnnuler[i].money);
          sommeTTC += parseFloat(this.reglementAnnuler[i].money);
          arr.push(this.reglementAnnuler[i].date);
          reglementAnuul.push(arr);
          arr = [];
        }
      }
      doc.text(70, 30, "Liste des réglementss annulés");
      doc.autoTable({
        styles: {
          lineWidth: 0.2,
          valign: "middle",
          halign: "center",
          fontStyle: "bold",
          font: "Amiri",
        },
        head: [
          [
            "Num",
            "Nom parent",
            "Type réglement",
            "Facture/reçu",
            "Montant TTC",
            "Date d'annulation",
          ],
        ],
        body: reglementAnuul,
        foot: [
          [
            {
              colSpan: 4,
              content: "Total : ",
              styles: { halign: "center" },
            },
            parseFloat(sommeTTC).toFixed(2),
          ],
        ],
        //startY: doc.lastAutoTable.finalY + 15,
        startY: 40,
      });

      //reglement annulé table
      doc.addPage();
      var reglementAvoir = [];
      sommeTTC = 0;
      for (let i = 0; i < this.reglementAnnuler.length; i++) {
        if (this.reglementAnnuler[i].avoir) {
          arr.push(this.reglementAnnuler[i].serial);
          arr.push(this.reglementAnnuler[i].clientName);
          arr.push(this.reglementAnnuler[i].type.split("Avoir: ")[1]);
          arr.push(this.reglementAnnuler[i].bill == "Oui" ? "Facture" : "Reçu");
          arr.push(this.reglementAnnuler[i].money);
          sommeTTC += parseFloat(this.reglementAnnuler[i].money);
          arr.push(moment(this.reglementAnnuler[i].date).format("DD/MM/YYYY"));
          reglementAvoir.push(arr);
          arr = [];
        }
      }

      const pdf_width = doc.internal.pageSize.getWidth();

      doc.text(pdf_width / 2, 30, "Liste des avoirs", { align: "center" });
      doc.autoTable({
        styles: {
          lineWidth: 0.2,
          valign: "middle",
          halign: "center",
          fontStyle: "bold",
          font: "Amiri",
        },
        head: [
          [
            "Num",
            "Nom parent",
            "Type réglement",
            "Facture/reçu",
            "Montant TTC",
            "Date d'avoir",
          ],
        ],
        body: reglementAvoir,
        foot: [
          [
            {
              colSpan: 4,
              content: "Total : ",
              styles: { halign: "center" },
            },
            parseFloat(sommeTTC).toFixed(2),
          ],
        ],
        //startY: doc.lastAutoTable.finalY + 15,
        startY: 40,
      });

      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Journal caisse.pdf");
      this.btnLoading = false;
    },
    async imprimerBrouillardCaisse() {
      this.$gtag.event("Imp Bouillard caisse", {
        event_category: "Impression PDF",
        event_label: "comptabilite:JournalCaisse",
        value: 1,
      });

      this.btnLoading = true;
      await apiClient
        .post("/depenses/filter", {
          query: {},
        })
        .then((res) => {
          this.depenseData = res.data;
        })
        .catch();

      await apiClient
        .post("/employee_payments/filter", {
          query: {},
        })
        .then((res) => {
          this.dataEmp = res.data.map((el) => {
            el.emp = true;
            return el;
          });
        })
        .catch();

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      let filterText = "";
      if (this.monthRangeData[0])
        filterText =
          "De " +
          this.monthRangeData[0]._d.toLocaleDateString("fr-FR", {
            day: "numeric",
            month: "long",
            year: "numeric",
          }) +
          " à " +
          this.monthRangeData[1]._d.toLocaleDateString("fr-FR", {
            day: "numeric",
            month: "long",
            year: "numeric",
          });
      const titre = "Brouillard caisse";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(15);
      doc.text(105, 60, titre, { align: "center" });
      doc.text(60, 70, filterText);
      doc.setFontSize(10);
      try {
        doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      } catch (error) {
        console.log(error);
      }
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];
      let solde = 0;
      const avoirs = this.reglementAnnuler.filter((elem) => elem.avoir);
      const depenseAvoir = JSON.parse(JSON.stringify(avoirs)).map((elem) => {
        elem.depense = true;
        elem.date = elem.updatedAt;
        return elem;
      });

      let BCdata = this.rowData
        .concat(this.depenseData)
        .concat(
          this.dataEmp.map((elem) => {
            elem.name =
              "Salaire " +
              elem.employee.firstName +
              " " +
              elem.employee.lastName;
            elem.money = Number(elem.salary);
            elem.date = elem.month;
            elem.userName = "--";
            return elem;
          })
        )
        .concat(
          avoirs.map((elem) => {
            elem.recette = true;
            elem.date = moment(elem.date, "DD/MM/YYYY");
            return elem;
          })
        )
        .concat(depenseAvoir)
        .sort(function (a, b) {
          return new Date(a.date) - new Date(b.date);
        });

      //if month range filter
      if (this.monthRangeData[0]) {
        BCdata = BCdata.filter((elem) => {
          const start = moment(this.monthRangeData[0]).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          });
          const end = moment(this.monthRangeData[1]).set({
            hour: 24,
            minute: 59,
            second: 59,
            millisecond: 99,
          });
          return moment(elem.date).isBetween(start, end, undefined, "[]");
        });
      }
      let totalD = 0;
      let totalR = 0;
      let depensesss = 0;
      BCdata = BCdata.filter((element) => element.status !== "cancelled").map(
        (element) => {
          let temp = {};
          temp.date = element.date;
          if (
            element.emp ||
            element.depenseType ||
            (element.avoir && element.depense)
          ) {
            if (element.avoir) {
              temp.libelle = element.type;
            } else if (element.emp) {
              temp.libelle = element.name;
            } else {
              temp.libelle = "dépense :" + element.depenseType.name;
              depensesss += parseFloat(element.money);
              console.log(element.money);
            }

            temp.recette = "";
            temp.depense = element.money;
            totalD += parseFloat(element.money);
            solde -= parseFloat(element.money);
          } else {
            if (!element.avoir) {
              temp.libelle = element.typeReglement;
              temp.recette = element.montantTTC;
              totalR += parseFloat(element.montantTTC);
              solde += parseFloat(element.montantTTC);
            } else {
              temp.libelle = element.type;
              temp.recette = element.money;
              totalR += parseFloat(element.money);
              solde += parseFloat(element.money);
            }
            temp.depense = "";
          }
          temp.userName = element.userName;
          temp.solde = solde;
          return temp;
        }
      );
      console.log("depense", depensesss);
      for (let i = 0; i < BCdata.length; i++) {
        arr.push(new Date(BCdata[i].date).toLocaleDateString("fr-FR"));
        arr.push(BCdata[i].libelle);
        arr.push(BCdata[i].userName);
        arr.push(BCdata[i].recette);

        arr.push(BCdata[i].depense);
        arr.push(BCdata[i].solde.toFixed(2));
        array.push(arr);
        arr = [];
      }
      doc.autoTable({
        styles: {
          lineWidth: 0.2,
          valign: "middle",
          halign: "center",
          fontStyle: "bold",
          font: "Amiri",
        },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        head: [
          ["Date", "Libelle", "Effectué par", "Recette", "Depense", "Solde"],
        ],
        body: array,
        foot: [
          [
            {
              colSpan: 3,
              content: "Solde final",
              styles: { halign: "center" },
            },
            totalR.toFixed(2),
            totalD.toFixed(2),
            parseFloat(
              BCdata.length > 0 ? BCdata[BCdata.length - 1].solde : 0
            ).toFixed(2),
          ],
        ],
        showFoot: "lastPage",
      });
      //effectué par calcule
      let doneByGroupped = _.mapValues(_.groupBy(BCdata, "userName"), (clist) =>
        clist.map((elem) => _.omit(elem, "userName"))
      );
      let doneByTable = [];
      for (let key in doneByGroupped) {
        let arr = [];
        const result = doneByGroupped[key].reduce(
          (acc, obj) => {
            acc.depense += obj.depense ? parseFloat(obj.depense) : 0;
            acc.recette += obj.recette ? parseFloat(obj.recette) : 0;
            return acc;
          },
          { depense: 0, recette: 0 }
        );
        arr.push(key);
        arr.push(result.recette.toFixed(2));
        arr.push(result.depense.toFixed(2));
        arr.push((result.recette - result.depense).toFixed(2));
        doneByTable.push(arr);
      }
      doc.autoTable({
        styles: {
          lineWidth: 0.2,
          valign: "middle",
          halign: "center",
          fontStyle: "bold",
          font: "Amiri",
        },
        head: [["Effectué par", "Total recette", "Total depense", "Balance"]],
        body: doneByTable,
        headStyles: {
          fillColor: [0, 102, 204], // Set the header fill color to blue
          textColor: 255, // Set the header text color to white
        },

        showFoot: "lastPage",
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Bouillard caisse.pdf");
      this.btnLoading = false;
    },
    handleChange(on, value) {
      const other = on == "date" ? "tags" : "date";
      let newData = [];
      this.filters[on] = true;
      this.filters[`${on}_val`] = value;
      if (on == "tags")
        newData = this.handleChangeTypeJournal(this.rowData, value);
      else newData = this.handlePanelChange(this.rowData, value);
      if (this.filters[other]) {
        if (other == "tags")
          newData = this.handleChangeTypeJournal(
            newData,
            this.filters[`${other}_val`]
          );
        else
          newData = this.handlePanelChange(
            newData,
            this.filters[`${other}_val`]
          );
      }
      this.data = newData;
      this.filtredTable = newData;
    },
    handleChangeTypeJournal(list, tags) {
      let checker = (big, small) => small.every((v) => big.includes(v));
      return list.filter((elem) => checker(elem.tags, tags));
    },
    handlePanelChange(list, value) {
      if (value.length > 1) {
        this.monthRangeData = value;
        return list.filter((elem) => {
          const start = moment(value[0]).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          });
          const end = moment(value[1]).set({
            hour: 24,
            minute: 59,
            second: 59,
            millisecond: 99,
          });
          return moment(elem.date).isBetween(start, end, undefined, "[]");
        });
      }
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    frMonths(s) {
      const months = {
        1: "Janvier",
        2: "Février",
        3: "Mars",
        4: "Avril",
        5: "Mai",
        6: "Juin",
        7: "Juillet",
        8: "Août",
        9: "Septembre",
        10: "Octobre",
        11: "November",
        12: "Décembre",
      };
      return months[parseInt(s.split("-")[1])];
    },

    transactionFormater(transaction, deleted = false) {
      const onTransReduction = transaction.onTransReduction
        ? transaction.onTransReduction
        : 0;

      let data = { payment: null };

      // for students who haven't deleted their payment
      if (!deleted) {
        const dataType =
          transaction.payment.typeAS == "A"
            ? transaction.activity
            : transaction.classroom;

        const name =
          transaction.typeAS == "A" ? dataType.designation : dataType.name;
        const typeName = transaction.typeAS == "A" ? "Activité" : "Scolarité";

        const parent = transaction.parent;

        data = {
          nomClient: `${parent.mother.firstName} ${parent.mother.lastName} ET ${parent.father.firstName} ${parent.father.lastName}`,
          payment: transaction.payment,
          typeReglement: `${typeName}/${name}`,
          student: transaction.student,
        };
      }

      data = {
        ...data,
        _id: transaction._id,
        onTransReduction,
        modePaiement: transaction.receiptType.name,
        montantHT: transaction.montantHT,
        montantTTC: transaction.montantTotal,
        montantCheque: transaction.montantCheque,
        montantEspece: transaction.montantEspece,
        typeFR: transaction.typeFR,
        typeAS: transaction.typeAS,
        date: transaction.date,
        cheque: transaction.cheque ? transaction.cheque : null,
        status: transaction.status,
        serial: transaction.serial,
        userName: transaction.userName ? transaction.userName : "--",
        info: {
          onInscription: Boolean(transaction.onInscription),
          months: transaction.months,
        },
        tags: [
          "all",
          transaction.typeAS == "A" ? "activity" : "scolarity",
          transaction.typeFR == "F" ? "bill" : "receipt",
          transaction.receiptType._id,
        ],
      };
      return data;
    },

    async getTransactions() {
      await apiClient
        .post("/transaction/filter", {
          query: {},
          aggregation: [
            {
              $lookup: {
                from: "payments",
                localField: "payment",
                foreignField: "_id",
                as: "payment",
              },
            },
            {
              $lookup: {
                from: "students",
                localField: "payment.student",
                foreignField: "_id",
                as: "student",
              },
            },
            {
              $lookup: {
                from: "parents",
                localField: "student.parent",
                foreignField: "_id",
                as: "parent",
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "payment.classroom",
                foreignField: "_id",
                as: "classroom",
              },
            },
            {
              $lookup: {
                from: "activities",
                localField: "payment.activity",
                foreignField: "_id",
                as: "activity",
              },
            },
            {
              $lookup: {
                from: "receipttypes",
                localField: "receiptType",
                foreignField: "_id",
                as: "receiptType",
              },
            },
            {
              $lookup: {
                from: "cheques",
                localField: "cheque",
                foreignField: "_id",
                as: "cheque",
              },
            },
            {
              $project: {
                _id: 1,
                date: 1,
                months: 1,
                onTransReduction: 1,
                onInscription: 1,
                student: {
                  firstName: 1,
                  lastName: 1,
                  parent: 1,
                },
                payment: {
                  _id: 1,
                  inscription: 1,
                  months: 1,
                  typeAS: 1,
                },
                activity: {
                  _id: 1,
                  designation: 1,
                  monthly: 1,
                  inscription: 1,
                },
                classroom: {
                  _id: 1,
                  name: 1,
                  monthly: 1,
                  inscription: 1,
                },
                parent: {
                  father: 1,
                  mother: 1,
                },
                receiptType: { name: 1, _id: 1 },
                cheque: { $arrayElemAt: ["$cheque", 0] },
                status: 1,
                serial: 1,
                montantCheque: 1,
                montantEspece: 1,
                montantTotal: 1,
                montantHT: 1,
                userName: 1,
                typeFR: 1,
                typeAS: 1,
              },
            },
          ],
        })
        .then((res) => {
          res.data.map((elem) => {
            try {
              if (elem.payment[0]) {
                this.data.push(
                  this.transactionFormater(
                    {
                      ...elem,
                      classroom: elem.typeAS == "S" ? elem.classroom[0] : null,
                      activity: elem.typeAS == "A" ? elem.activity[0] : null,
                      payment: elem.payment[0],
                      receiptType: elem.receiptType[0],
                      student: elem.student[0],
                      parent: elem.parent[0],
                    },
                    false
                  )
                );
              } else {
                this.data.push(
                  this.transactionFormater(
                    {
                      ...elem,
                      receiptType: elem.receiptType[0],
                    },
                    true
                  )
                );
              }
            } catch (e) {
              return console.error(e);
            }
            this.rowData = this.data;
            this.tableLoading = false;
          });
        })
        .catch((e) => {
          console.log(e);
          this.$message.warning(this.$t("warning.connectionProblem"));
        });
    },

    async getCancellations() {
      await apiClient
        .post("/cancellations/filter", { query: {} })
        .then((res) => {
          this.data = this.data.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.serial === value.serial)
          );
          this.data = this.data.map((elem) => {
            if (elem.status === "cancelled") {
              let tempReglementAnnuler = res.data.find(
                (e) => e.serial === elem.serial
              );
              elem.montantTTC = tempReglementAnnuler.money;
              elem.typeReglement = tempReglementAnnuler.name;
              elem.nomClient = tempReglementAnnuler.clientName;
              elem.type = tempReglementAnnuler.type;
            }
            return elem;
          });
          this.rowData = this.data;
          this.filtredTable = this.data;
          this.reglementAnnuler = res.data.map((elem) => ({
            ...elem,
            avoir: elem.type == "Avoir",
            type: (elem.type == "Avoir" ? "Avoir: " : "") + elem.name,
            bill: elem.bill ? "Oui" : "Non",
            date: moment(elem.date).format("DD/MM/YYYY"),
          }));
        });
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    async getRecipetsType() {
      await apiClient
        .post("/receipt_types/filter", {
          query: { status: "active" },
        })
        .then((res) => {
          res.data.map(
            (recipe) => (this.recipetTypes[recipe._id] = recipe.name)
          );
        })
        .catch();
    },
  },
};
</script>
