<template>
  <div v-if="visible">
    <a-modal
      :visible="visible"
      @cancel="handleCancel"
      width="95%"
      :title="$t('all.enterInformationBelow') + ' ' + certif.name"
      :footer="false"
    >
      <a-form
        :form="formEdit"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 15 }"
      >
        <div class="row">
          <div class="col-8" style="height: 70vh" :loading="true">
            <!--<div
              v-if="loadingPdfPreview"
              style="
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <a-spin size="large" />
            </div>-->
            <iframe
              :src="pdfBlobUrl"
              style="background-color: #eee; height: 100%; width: 100%"
            >
            </iframe>
          </div>
          <div class="col-4">
            <a-divider class="my-0">Configuration du PDF</a-divider>
            <a-row :gutter="16">
              <!-- Full Name X -->
              <a-col :span="24">
                <a-form-item label="Orientation" class="mb-0">
                  <a-select
                    placeholder="orientation"
                    v-model="configPrint.orientation"
                    style="width: 100%"
                  >
                    <a-select-option value="p">Vertical</a-select-option>
                    <a-select-option value="l">Horizontal</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="Position X" class="mb-0">
                  <a-select
                    placeholder="Position X"
                    v-model="configPrint.xAlign"
                    style="width: 100%"
                  >
                    <a-select-option value="center">Centré</a-select-option>
                    <a-select-option value="left">Au début</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="Font" class="mb-0">
                  <a-select
                    placeholder="Select a font"
                    v-model="configPrint.font"
                    style="width: 100%"
                  >
                    <template v-for="(group, index) in fontGroups">
                      <a-select-opt-group :label="group.label" :key="index">
                        <a-select-option
                          v-for="font in group.options"
                          :key="font.value"
                          :value="font.value"
                        >
                          {{ font.label }}
                        </a-select-option>
                      </a-select-opt-group>
                    </template>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-divider class="mb-0">Placer nom de l'élève</a-divider>
            <a-row :gutter="16">
              <!-- Full Name X -->
              <a-col :span="12">
                <a-form-item label="X" class="mb-0">
                  <a-input-number
                    placeholder="x"
                    class="w-100"
                    v-model="configPrint.fullName.x"
                  />
                </a-form-item>
              </a-col>

              <!-- Full Name Y -->
              <a-col :span="12">
                <a-form-item label="Y" class="mb-0">
                  <a-input-number
                    placeholder="y"
                    class="w-100"
                    v-model="configPrint.fullName.y"
                  />
                </a-form-item>
              </a-col>
              <!-- font size name -->
              <a-col :span="12">
                <a-form-item label="Taille :" class="mb-0">
                  <a-input-number
                    placeholder="Taille du text"
                    class="w-100"
                    v-model="configPrint.fullName.fontSize"
                  />
                </a-form-item>
              </a-col>
              <!-- Full Name colors -->
              <a-col :span="12">
                <a-form-item label="Couleur :" class="mb-0">
                  <input type="color" v-model="configPrint.fullName.color" />
                </a-form-item>
              </a-col>
            </a-row>

            <a-row :gutter="16">
              <a-divider class="mb-0">Placer nom du classe</a-divider>

              <!-- Class Name X -->
              <a-col :span="12">
                <a-form-item label="X" class="mb-0">
                  <a-input-number
                    placeholder="x"
                    class="w-100"
                    v-model="configPrint.className.x"
                  />
                </a-form-item>
              </a-col>

              <!-- Class Name Y -->
              <a-col :span="12">
                <a-form-item label="Y" class="mb-0">
                  <a-input-number
                    placeholder="y"
                    class="w-100"
                    v-model="configPrint.className.y"
                  />
                </a-form-item>
              </a-col>
              <!-- font size Class -->
              <a-col :span="12">
                <a-form-item label="Taille :" class="mb-0">
                  <a-input-number
                    placeholder="Taille du text"
                    class="w-100"
                    v-model="configPrint.className.fontSize"
                  />
                </a-form-item>
              </a-col>
              <!-- Class color -->
              <a-col :span="12">
                <a-form-item label="Couleur :" class="mb-0">
                  <input type="color" v-model="configPrint.className.color" />
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-button type="primary" class="w-100 mb-0" @click="showPreview"
                  ><a-icon type="eye" />{{ $t("action.preview") }}
                </a-button>
              </a-col>
              <a-col :span="12">
                <a-button
                  type="primary"
                  class="w-100"
                  :disable="saveLoading"
                  :loading="saveLoading"
                  @click="handelEdit"
                  ><a-icon type="save" />{{ $t("all.save") }}
                </a-button>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import { mapState } from "vuex";
import { drawCertifcate } from "@/utilities/pdfCertificate";

export default {
  name: "certifications",
  props: ["certif", "visible", "selectedCertType"],
  beforeMount() {
    this.formEdit = this.$form.createForm(this, {
      name: "editCertificationForm",
    });
  },
  mounted() {
    if (this.certif.configPrint) {
      this.configPrint = this.certif.configPrint;
      this.showPreview();
    } else {
      this.configPrint = this.defaultConfigPrint;
      this.showPreview();
    }
  },
  data() {
    return {
      loadingPdfPreview: true,

      form: this.$form.createForm(this),
      data: null,
      cacheData: null,
      currentCertif: undefined,
      pdfBlobUrl: "",
      fontGroups: [
        {
          label: "Arabe et français",
          options: [
            { value: "Amiri", label: "Amiri (Normal)" },
            { value: "Amiri-Bold", label: "Amiri (Bold)" },
            { value: "NotoNaskhArabic", label: "Noto Naskh Arabic (Normal)" },
          ],
        },
        {
          label: "Arabe seulement",
          options: [
            {
              value: "Noto Sans Arabic-Bold",
              label: "Noto Sans Arabic (Bold)",
            },
            {
              value: "Al Jazeera Arabic-Bold",
              label: "Al Jazeera Arabic (Bold)",
            },
          ],
        },
      ],
      configPrint: {
        orientation: "l", // portrait or portrait (l or p)
        font: "Amiri",
        xAlign: "left",
        fullName: {
          x: 70,
          y: 105,
          fontSize: 30,
          color: "#000000",
        },
        className: {
          x: 180,
          y: 127,
          fontSize: 19,
          color: "#000000",
        },
      },
      saveLoading: false,
      defaultConfigPrint: {
        orientation: "l", // portrait or portrait (l or p)
        font: "Amiri",
        xAlign: "left",
        fullName: {
          x: 70,
          y: 105,
          fontSize: 30,
          color: "#000000",
        },
        className: {
          x: 180,
          y: 127,
          fontSize: 19,
          color: "#000000",
        },
      },
    };
  },
  computed: {
    ...mapState(["settings", "user"]),
  },
  methods: {
    handleCancel() {
      this.$emit("close");
    },
    async showPreview() {
      try {
        this.loadingPdfPreview = true;
        let sampleDate = {
          fullName: "الإسم و اللقب",
          classroomName: "اسم القسم",
        };
        let certifImgUrl = this.settings.base_url + "/" + this.certif.fileM;
        const doc = await drawCertifcate(
          sampleDate,
          this.configPrint,
          certifImgUrl,
          true
        );
        this.pdfBlobUrl = doc.output("bloburl");
        this.loadingPdfPreview = false;
      } catch (error) {
        console.error(error);
      }
    },
    handelEdit(e) {
      e.preventDefault();
      this.formEdit.validateFieldsAndScroll((err, values) => {
        if (err) return;
        this.saveLoading = true;
        const id = this.certif.id;
        // data: values,
        const formData = new FormData();
        formData.append("configPrint", this.configPrint);
        let data = {
          configPrint: this.configPrint,
        };
        apiClient
          .patch("/certification/" + id + "/" + this.selectedCertType, data)
          .then(async (res) => {
            this.$emit("save");
            this.$message.success(this.$t("success.certificationMSA"));
            this.handleCancel();
          })
          .catch(() => {
            this.$message.error(this.$t("error.modif"));
          })
          .finally(() => (this.saveLoading = false));
      });
    },
  },
};
</script>
