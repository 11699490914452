import apiClient from "@/services/axios";
import moment from "moment";
import JsPDF from "jspdf";
function isNumber(value) {
  return !isNaN(Number(value));
}
const getCertificationNameByMoyenne = function (moyenne, certifs) {
  for (const cert of certifs)
    if (
      Number(moyenne) >= Number(cert.min) &&
      Number(moyenne) <= Number(cert.max)
    ) {
      return cert.name;
    }

  return "";
};
const getModules = async (level, type, trimester) => {
  let listModules = [];
  await apiClient
    .post("/bulletin/v2/module/filter", {
      query: {
        status: "active",
        level: level,
        trimester: trimester,
        type: type,
      },
    })
    .then((res) => {
      listModules = res.data;
    })
    .catch((e) => {
      console.log(e);
    });
  return listModules;
};
const printPVBulletin = async (config) => {
  const {
    selectedLevel,
    schoolDetails,
    selectedStudent,
    selectedStudentName,
    settings,
    type,
    selectedTrimester,
    bulletinType,
    certifs,
    selectedClasse,
    selectedClassName,
    usePersonalizedAverage,
  } = config;

  const doc = new JsPDF({
    orientation: "l",
    unit: "mm",
    format: "a3",
  });
  let bulletinData;

  await apiClient
    .get(
      "/bulletin/v2/pv/classroom/" +
        bulletinType +
        "/" +
        selectedClasse +
        "/" +
        selectedTrimester
    )
    .then(({ data }) => {
      bulletinData = data;
    });

  const cellToDelete = {};
  const head = [];
  const modulesHead = [];

  const defaultMax = [cellToDelete];
  const defaultMin = [cellToDelete];
  defaultMax.unshift({ content: "اعلى عدد بالقسم", colSpan: 2 }, cellToDelete);
  defaultMin.unshift({ content: "ادنى عدد بالقسم", colSpan: 2 }, cellToDelete);
  let listModules = await getModules(
    selectedLevel,
    bulletinType,
    selectedTrimester
  );

  listModules.forEach((m) => {
    m.subjects.forEach((s) => {
      head.unshift(s.name);
      defaultMax.unshift({ content: "--" });
      defaultMin.unshift({ content: "--" });
    });
    head.unshift("المجموع");
    head.unshift("معدل المجال");
    defaultMax.unshift("", "");
    defaultMin.unshift("", "");

    modulesHead.unshift({
      content: m.name,
      colSpan: m.subjects.length + 2,
    });
    for (let i = 0; i < m.subjects.length + 1; i++)
      modulesHead.unshift(cellToDelete);
  });
  head.unshift("مجموع المجالات");
  head.unshift("الرتبة");
  head.unshift("المعدل");
  head.unshift("الشهادة");
  defaultMax.unshift("", "--", "", "");
  defaultMin.unshift("", "--", "", "");

  modulesHead.unshift({ content: "النتيجة", colSpan: 4 });
  for (let i = 0; i < 3; i++) modulesHead.unshift(cellToDelete);

  const sMax = [cellToDelete];
  const sMin = [cellToDelete];
  sMax.unshift({ content: "اعلى عدد بالقسم", colSpan: 2 }, cellToDelete);
  sMin.unshift({ content: "ادنى عدد بالقسم", colSpan: 2 }, cellToDelete);

  const data = [];
  let studentID = 1;
  bulletinData.students.forEach((student) => {
    const studentData = [];
    studentData.unshift(studentID++);
    studentData.unshift(student.fullName);
    let mSum = 0;
    student.modules.forEach((m) => {
      try {
        let sum = 0;
        m.subjects.forEach((s) => {
          studentData.unshift(
            isNumber(s.mark) ? Number(s.mark).toFixed(2) : "--"
          );
          if (isNumber(s.mark)) sum += Number(s.mark);
          if (studentID == 2) {
            sMax.unshift({
              content: isNumber(s.max) ? Number(s.max).toFixed(2) : "--",
            });
            sMin.unshift({
              content: isNumber(s.min) ? Number(s.min).toFixed(2) : "--",
            });
          }
        });
        studentData.unshift(sum.toFixed(2));
        studentData.unshift(m.moyenne);
        if (studentID == 2) {
          sMax.unshift(m.maxMoyenne || "--", "");
          sMin.unshift(m.minMoyenne || "--", "");
        }
        if (m.moyenne != "--") mSum += Number(m.moyenne);
      } catch (error) {
        console.log(student, m, error);
      }
    });
    studentData.unshift(mSum.toFixed(2));
    const rank =
      usePersonalizedAverage &&
      bulletinType === "MS" &&
      student.personalizedRank
        ? student.personalizedRank
        : student.rank;
    studentData.unshift(rank);
    const moyenne =
      usePersonalizedAverage && bulletinType === "MS"
        ? student.personalizedMoyenne
        : student.moyenne;

    studentData.unshift(moyenne);
    studentData.unshift(getCertificationNameByMoyenne(moyenne, certifs));

    data.push(studentData);
  });

  const max =
    usePersonalizedAverage &&
    bulletinType === "MS" &&
    bulletinData.students[0]?.personalizedMoyenneMax
      ? bulletinData.students[0].personalizedMoyenneMax
      : bulletinData.maxMoyenne;

  const min =
    usePersonalizedAverage &&
    bulletinType === "MS" &&
    bulletinData.students[0]?.personalizedMoyenneMin
      ? bulletinData.students[0].personalizedMoyenneMin
      : bulletinData.minMoyenne;

  sMax.unshift("", max, "", "");
  sMin.unshift("", min, "", "");

  if (bulletinData.students.length === 0) {
    data.push(defaultMax);
    data.push(defaultMin);
  } else {
    data.push(sMax);
    data.push(sMin);
  }
  const array = [
    [...modulesHead, { content: "", colSpan: 2 }, cellToDelete],
    [
      { content: "الشهادة" },
      ...Array(head.length - 1).fill(""),
      { content: bulletinData.classroomName, colSpan: 2 },
      cellToDelete,
    ],
    ...data,
  ];

  // fix table
  for (let row = 0; row < array.length; row++) {
    array[row] = array[row].filter((cell) => cell !== cellToDelete);
  }

  const className = selectedClassName;

  doc.autoTable({
    startY: 5,
    body: array,
    theme: "striped",
    styles: {},
    bodyStyles: {
      lineWidth: 0.2,
      lineColor: [73, 138, 159],
      halign: "center",
      valign: "middle",
      fontSize: 7,
      cellPadding: 1.2,
    },
    rowPageBreak: "avoid",
    columnStyles: {
      0: {
        columnWidth: 20,
      },
    },

    didDrawCell: function (data) {
      const length = data.row.raw.length;
      if (
        data.row.index === 1 &&
        data.column.index < length - 1 &&
        data.column.index !== 0
      ) {
        const width = doc.getTextWidth(head[data.column.index]);
        doc.text(
          head[data.column.index],
          data.cell.x + data.cell.width / 2 - 0.3,
          data.cell.y + 20 + width / 2,
          { angle: 90 }
        );
      }
    },

    didParseCell: function (data) {
      const length = data.row.raw.length;

      if (data.column.index === length - 1) data.cell.styles.fontSize = 11;

      if (data.row.index == 1) {
        data.cell.styles.minCellHeight = 36;
        data.cell.styles.minCellWidth = 5;
        data.cell.styles.halign = "center";
        data.cell.styles.valign = "center";

        if (data.column.index !== length - 1) data.cell.styles.fontSize = 8;
        else data.cell.styles.fontSize = 11;

        if (
          [
            "معدل المجال",
            "المعدل",
            "المجموع",
            "مجموع المجالات",
            "الرتبة",
          ].includes(head[data.column.index])
        ) {
          data.cell.styles.fontStyle = "Bold";
          data.cell.styles.fontSize = 8.5;
        } else data.cell.styles.fontStyle = "normal";

        if ("معدل المجال" == head[data.column.index])
          data.cell.styles.fillColor = "#8bc34a";
        else if ("المعدل" == head[data.column.index])
          data.cell.styles.fillColor = "#ffca62";
      } else {
        if (data.column.index == length - 1) data.cell.styles.fontSize = 8;
      }

      if (data.row.index == 0) {
        data.cell.styles.fontSize = 8;
        data.cell.styles.fillColor = "#ace36d";
      }

      if (/[a-zA-Z]/.test(data.cell.text[0]))
        data.cell.styles.font = "helvetica";
      else if (
        data.row.index !== 1 &&
        (!isNaN(Number(data.cell.text[0])) || data.cell.text[0] == "--")
      ) {
        data.cell.styles.font = "helvetica";
        data.cell.styles.fontStyle = "bold";
      } else data.cell.styles.font = "Amiri";

      if (data.row.index == array.length - 2) {
        if (data.column.index !== length - 1)
          data.cell.styles.textColor = "#8bc34a";
        else data.cell.styles.fillColor = "#8bc34a";
        if (data.cell.styles.font == "Amiri")
          data.cell.styles.fontStyle = "Bold";
      }

      if (data.row.index == array.length - 1) {
        if (data.column.index !== length - 1)
          data.cell.styles.textColor = "#f44336";
        else data.cell.styles.fillColor = "#ffaea8";
        if (data.cell.styles.font == "Amiri")
          data.cell.styles.fontStyle = "Bold";
      }
    },
  });

  doc.save(`PV Bulletin ${className}.pdf`);
};

const printPVBulletinLevel = async (config) => {
  const {
    selectedLevel,
    schoolDetails,
    selectedStudent,
    selectedStudentName,
    settings,
    type,
    selectedTrimester,
    bulletinType,
    certifs,
    selectedClasse,
    selectedClassName,
    usePersonalizedAverage,
  } = config;

  const doc = new JsPDF({
    orientation: "l",
    unit: "mm",
    format: "a3",
  });
  let bulletinData;

  await apiClient
    .get(
      "/bulletin/v2/pv/level/" +
        bulletinType +
        "/" +
        selectedLevel +
        "/" +
        selectedTrimester
    )
    .then(({ data }) => {
      bulletinData = data;
    });

  const cellToDelete = {};
  const head = [];
  const modulesHead = [];

  const defaultMax = [cellToDelete];
  const defaultMin = [cellToDelete];
  defaultMax.unshift(
    { content: "اعلى عدد بالمستوى", colSpan: 2 },
    cellToDelete
  );
  defaultMin.unshift(
    { content: "ادنى عدد بالمستوى", colSpan: 2 },
    cellToDelete
  );
  let listModules = await getModules(
    selectedLevel,
    bulletinType,
    selectedTrimester
  );

  listModules.forEach((m) => {
    m.subjects.forEach((s) => {
      head.unshift(s.name);
      defaultMax.unshift({ content: "--" });
      defaultMin.unshift({ content: "--" });
    });
    head.unshift("المجموع");
    head.unshift("معدل المجال");
    defaultMax.unshift("", "");
    defaultMin.unshift("", "");

    modulesHead.unshift({
      content: m.name,
      colSpan: m.subjects.length + 2,
    });
    for (let i = 0; i < m.subjects.length + 1; i++)
      modulesHead.unshift(cellToDelete);
  });
  head.unshift("مجموع المجالات");
  head.unshift("الرتبة");
  head.unshift("المعدل");
  head.unshift("الشهادة");
  defaultMax.unshift("", "--", "", "");
  defaultMin.unshift("", "--", "", "");

  modulesHead.unshift({ content: "النتيجة", colSpan: 4 });
  for (let i = 0; i < 3; i++) modulesHead.unshift(cellToDelete);

  const sMax = [cellToDelete];
  const sMin = [cellToDelete];
  sMax.unshift({ content: "اعلى عدد بالمستوى", colSpan: 2 }, cellToDelete);
  sMin.unshift({ content: "ادنى عدد بالمستوى", colSpan: 2 }, cellToDelete);

  const data = [];
  let studentID = 1;
  bulletinData.students.forEach((student) => {
    const studentData = [];
    studentData.unshift(student.fullName);
    studentData.unshift(student.classroomName);
    let mSum = 0;
    studentID++;
    student.modules.forEach((m) => {
      let sum = 0;
      m.subjects.forEach((s) => {
        studentData.unshift(
          isNumber(s.mark) ? Number(s.mark).toFixed(2) : s.mark
        );
        if (isNumber(s.mark)) sum += Number(s.mark);

        if (studentID == 2) {
          sMax.unshift({
            content: isNumber(s.max) ? Number(s.max).toFixed(2) : "--",
          });
          sMin.unshift({
            content: isNumber(s.min) ? Number(s.min).toFixed(2) : "--",
          });
        }
      });
      studentData.unshift(sum.toFixed(2));
      studentData.unshift(m.moyenne);
      if (studentID == 2) {
        sMax.unshift(m.maxMoyenne || "--", "");
        sMin.unshift(m.minMoyenne || "--", "");
      }
      if (m.moyenne != "--") mSum += Number(m.moyenne);
    });
    studentData.unshift(mSum.toFixed(2));
    const rank =
      usePersonalizedAverage &&
      bulletinType === "MS" &&
      student.personalizedRank
        ? student.personalizedRank
        : student.rank;
    studentData.unshift(rank);

    const moyenne =
      usePersonalizedAverage && bulletinType === "MS"
        ? student.personalizedMoyenne
        : student.moyenne;
    studentData.unshift(moyenne);
    studentData.unshift(getCertificationNameByMoyenne(moyenne, certifs));

    data.push(studentData);
  });

  const max =
    usePersonalizedAverage &&
    bulletinType === "MS" &&
    bulletinData.students[0]?.personalizedMoyenneMax
      ? bulletinData.students[0].personalizedMoyenneMax
      : bulletinData.maxMoyenne;

  const min =
    usePersonalizedAverage &&
    bulletinType === "MS" &&
    bulletinData.students[0]?.personalizedMoyenneMin
      ? bulletinData.students[0].personalizedMoyenneMin
      : bulletinData.minMoyenne;

  sMax.unshift("", max, "", "");
  sMin.unshift("", min, "", "");

  if (bulletinData.students.length === 0) {
    data.push(defaultMax);
    data.push(defaultMin);
  } else {
    data.push(sMax);
    data.push(sMin);
  }
  const array = [
    [...modulesHead, { content: "", colSpan: 2 }, cellToDelete],
    [
      { content: "الشهادة" },
      ...Array(head.length - 1).fill(""),
      { content: selectedLevel + " المستوى", colSpan: 2 },
      cellToDelete,
    ],
    ...data,
  ];

  // fix table
  for (let row = 0; row < array.length; row++) {
    array[row] = array[row].filter((cell) => cell !== cellToDelete);
  }

  const className = selectedClassName;

  doc.autoTable({
    startY: 5,
    body: array,
    theme: "striped",
    styles: {},
    bodyStyles: {
      lineWidth: 0.2,
      lineColor: [73, 138, 159],
      halign: "center",
      valign: "middle",
      fontSize: 7,
      cellPadding: 1.2,
    },
    rowPageBreak: "avoid",
    columnStyles: {
      0: {
        columnWidth: 20,
      },
    },

    didDrawCell: function (data) {
      const length = data.row.raw.length;
      if (
        data.row.index === 1 &&
        data.column.index < length - 1 &&
        data.column.index !== 0
      ) {
        const width = doc.getTextWidth(head[data.column.index]);
        doc.text(
          head[data.column.index],
          data.cell.x + data.cell.width / 2 - 0.3,
          data.cell.y + 20 + width / 2,
          { angle: 90 }
        );
      }
    },

    didParseCell: function (data) {
      const length = data.row.raw.length;

      if (data.column.index === length - 1) data.cell.styles.fontSize = 11;

      if (data.row.index == 1) {
        data.cell.styles.minCellHeight = 36;
        data.cell.styles.minCellWidth = 5;
        data.cell.styles.halign = "center";
        data.cell.styles.valign = "center";

        if (data.column.index !== length - 1) data.cell.styles.fontSize = 8;
        else data.cell.styles.fontSize = 11;

        if (
          [
            "معدل المجال",
            "المعدل",
            "المجموع",
            "مجموع المجالات",
            "الرتبة",
          ].includes(head[data.column.index])
        ) {
          data.cell.styles.fontStyle = "Bold";
          data.cell.styles.fontSize = 8.5;
        } else data.cell.styles.fontStyle = "normal";

        if ("معدل المجال" == head[data.column.index])
          data.cell.styles.fillColor = "#8bc34a";
        else if ("المعدل" == head[data.column.index])
          data.cell.styles.fillColor = "#ffca62";
      } else {
        if (data.column.index == length - 1) data.cell.styles.fontSize = 8;
      }

      if (data.row.index == 0) {
        data.cell.styles.fontSize = 8;
        data.cell.styles.fillColor = "#ace36d";
      }

      if (/[a-zA-Z]/.test(data.cell.text[0]))
        data.cell.styles.font = "helvetica";
      else if (
        data.row.index !== 1 &&
        (!isNaN(Number(data.cell.text[0])) || data.cell.text[0] == "--")
      ) {
        data.cell.styles.font = "helvetica";
        data.cell.styles.fontStyle = "bold";
      } else data.cell.styles.font = "Amiri";

      if (data.row.index == array.length - 2) {
        if (data.column.index !== length - 1)
          data.cell.styles.textColor = "#8bc34a";
        else data.cell.styles.fillColor = "#8bc34a";
        if (data.cell.styles.font == "Amiri")
          data.cell.styles.fontStyle = "Bold";
      }

      if (data.row.index == array.length - 1) {
        if (data.column.index !== length - 1)
          data.cell.styles.textColor = "#f44336";
        else data.cell.styles.fillColor = "#ffaea8";
        if (data.cell.styles.font == "Amiri")
          data.cell.styles.fontStyle = "Bold";
      }
    },
  });

  doc.save(`PV Bulletin par niveau ${selectedLevel}.pdf`);
};

const printPVAnnuel = async (config) => {
  this.$gtag.event("Imp PV Annuel", {
    event_category: "Impression PDF",
    event_label: "Bulletin peda-NotesEleves section",
    value: 1,
  });

  const cellToDelete = {};

  const doc = new JsPDF({
    orientation: "m",
    unit: "mm",
    format: "a3",
  });

  doc.setFont("Amiri");

  const pdf_width = doc.internal.pageSize.getWidth();

  let arrayOfImages = this.settings.image;
  for (var i = 0; i < arrayOfImages.length; i++) {
    if (this.settings.activeBuilding === arrayOfImages[i].db) {
      var imgData = new Image();
      imgData.src = arrayOfImages[i].logo;
    }
  }

  doc.addImage(imgData, "JPEG", 10, 8, 20, 20);

  const className = selectedClassName;

  doc.text("PV Annuel - " + className, pdf_width / 2, 20, {
    align: "center",
  });

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  doc.setFontSize(9);

  doc.text(new Date().toLocaleDateString("fr-FR", options), pdf_width - 17, 8, {
    align: "right",
  });

  let bulletinData;

  await apiClient
    .get("/bulletin/final/all/" + selectedClasse)
    .then((res) => (bulletinData = res.data));

  let array = [
    [
      { content: "الرتبة", styles: { fontStyle: "Bold" } },
      { content: "معدل العام", styles: { fontStyle: "Bold" } },
      { content: "معدل الثلاثي الثالث", styles: { fontStyle: "Bold" } },
      { content: "معدل الثلاثي الثاني", styles: { fontStyle: "Bold" } },
      { content: "معدل الثلاثي الأول", styles: { fontStyle: "Bold" } },
      { content: "الأسم", styles: { fontStyle: "Bold" } },
      { content: "#", styles: { fontStyle: "Bold" } },
    ],
  ];

  let id = 1;
  bulletinData.students.forEach((student) => {
    const studentData = [];
    studentData.push(student.rank);
    studentData.push(student.finalMoyenne);
    studentData.push(student.moyenneThree);
    studentData.push(student.moyenneTwo);
    studentData.push(student.moyenneOne);
    studentData.push(student.fullName);
    studentData.push(id++);
    array.push(studentData);
  });

  // fix table
  for (let row = 0; row < array.length; row++) {
    array[row] = array[row].filter((cell) => cell !== cellToDelete);
  }

  doc.autoTable({
    startY: 35,
    body: array,
    theme: "striped",
    styles: {},
    bodyStyles: {
      lineWidth: 0.2,
      lineColor: [73, 138, 159],
      halign: "center",
      fontSize: 10,
      font: "Amiri",
      // cellPadding: 1.2,
    },
    columnStyles: {
      0: { cellWidth: 20 },
    },
    rowPageBreak: "avoid",
  });

  //page numbering
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  doc.setFontSize(9); //Optional

  doc.setTextColor(0, 0, 0);

  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2;
    let verticalPos = pageHeight - 10;
    doc.setPage(j);
    doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }

  //end page numbering

  doc.save(`PV Annuel ${className}.pdf`);
};

const printPVAnnuelByLevel = async (config) => {
  this.$gtag.event("Imp PV Annuel by level", {
    event_category: "Impression PDF",
    event_label: "Bulletin peda-NotesEleves section",
    value: 1,
  });

  const cellToDelete = {};

  const doc = new JsPDF({
    orientation: "m",
    unit: "mm",
    format: "a3",
  });

  doc.setFont("Amiri");

  const pdf_width = doc.internal.pageSize.getWidth();

  let arrayOfImages = this.settings.image;
  for (var i = 0; i < arrayOfImages.length; i++) {
    if (this.settings.activeBuilding === arrayOfImages[i].db) {
      var imgData = new Image();
      imgData.src = arrayOfImages[i].logo;
    }
  }

  doc.addImage(imgData, "JPEG", 10, 8, 20, 20);

  const levels = {
    "-2": "Préscolaire",
    "-1": "تحضيري",
    1: "سنة أولى",
    2: "سنة ثانية",
    3: "سنة ثالثة",
    4: "سنة رابعة",
    5: "سنة خامسة",
    6: "سنة سادسة",
    7: "سنة سابعة",
    8: "سنة ثامنة",
    9: "سنة تاسعة",
    10: "أولى ثانوي",
    11: "ثانية ثانوي",
    12: "ثالثة ثانوي",
    13: "رابعة ثاتوي",
  };

  doc.text("PV Annuel - " + levels[selectedLevel], pdf_width / 2, 20, {
    align: "center",
  });

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  doc.setFontSize(9);

  doc.text(new Date().toLocaleDateString("fr-FR", options), pdf_width - 17, 8, {
    align: "right",
  });

  let bulletinData;

  await apiClient
    .get("/bulletin/pv/final/" + selectedLevel)
    .then((res) => (bulletinData = res.data));

  let array = [
    [
      { content: "الرتبة", styles: { fontStyle: "Bold" } },
      { content: "معدل العام", styles: { fontStyle: "Bold" } },
      { content: "معدل الثلاثي الثالث", styles: { fontStyle: "Bold" } },
      { content: "معدل الثلاثي الثاني", styles: { fontStyle: "Bold" } },
      { content: "معدل الثلاثي الأول", styles: { fontStyle: "Bold" } },
      { content: "القسم", styles: { fontStyle: "Bold" } },
      { content: "الأسم", styles: { fontStyle: "Bold" } },
    ],
  ];

  bulletinData.students.forEach((student) => {
    const studentData = [];
    studentData.push(student.rank);
    studentData.push(student.finalMoyenne);
    studentData.push(student.moyenneThree);
    studentData.push(student.moyenneTwo);
    studentData.push(student.moyenneOne);
    studentData.push(student.classroomName);
    studentData.push(student.fullName);
    array.push(studentData);
  });

  // fix table
  for (let row = 0; row < array.length; row++) {
    array[row] = array[row].filter((cell) => cell !== cellToDelete);
  }

  doc.autoTable({
    startY: 35,
    body: array,
    theme: "striped",
    styles: {},
    bodyStyles: {
      lineWidth: 0.2,
      lineColor: [73, 138, 159],
      halign: "center",
      fontSize: 9,
      font: "Amiri",
      // cellPadding: 1.2,
    },
    columnStyles: {
      0: { cellWidth: 20 },
    },
    rowPageBreak: "avoid",
  });

  //page numbering
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  doc.setFontSize(9); //Optional

  doc.setTextColor(0, 0, 0);

  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2;
    let verticalPos = pageHeight - 10;
    doc.setPage(j);
    doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }

  //end page numbering

  doc.save(`PV Annuel ${levels[selectedLevel]}.pdf`);
};

export default {
  printPVBulletin,
  printPVBulletinLevel,
  // printPVAnnuel,
  // printPVAnnuelByLevel,
};
