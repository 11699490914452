<template>
  <div>
    <a-skeleton v-if="leading" :active="true" avatar :paragraph="{ rows: 4 }" />
    <div v-else>
      <div class="card card-body" v-if="status != 'active'">
        <div class="row justify-content-xl-center">
          <div class="col-xl-12 col-lg-12">
            <a-page-header
              :title="info.firstName + ' ' + info.lastName"
              :sub-title="info.classRoom ? info.classRoom.name : 'NON INSCRIT'"
              @back="() => $router.go(-1)"
            >
              <template slot="tags">
                <a-tag color="green">
                  {{ calculateAge(info.BirthDate) }} ANS</a-tag
                >
              </template>
              <template slot="extra">
                <PaymentStatus
                  :currentParent="info.parent._id"
                  :selectedStudent="studentId"
                  :profile="true"
                />
              </template>
              <div class="row">
                <div class="col-md-1" v-if="info.photo">
                  <div class="kit__utils__avatar kit__utils__avatar--size64">
                    <img
                      :src="settings.base_url + '/' + info.photo"
                      :alt="info.firstName + ' ' + info.lastName"
                    />
                  </div>
                </div>
                <a-descriptions size="small" :column="2" class="col">
                  <a-descriptions-item :label="$t('sms.pere')">
                    <i :class="['fa', 'fa-male']" />
                    {{ info.parent.father.firstName }}
                    {{ info.parent.father.lastName }}
                  </a-descriptions-item>
                  <a-descriptions-item :label="$t('sms.telephone')">
                    <a-icon type="phone" /> {{ info.parent.father.phone }}
                  </a-descriptions-item>
                  <a-descriptions-item :label="$t('sms.mere')">
                    <i :class="['fa', 'fa-female']" />
                    {{ info.parent.mother.firstName }}
                    {{ info.parent.mother.lastName }}
                  </a-descriptions-item>

                  <a-descriptions-item :label="$t('sms.telephone')">
                    <a-icon type="phone" /> {{ info.parent.mother.phone }}
                  </a-descriptions-item>
                </a-descriptions>
              </div>
            </a-page-header>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row justify-content-xl-center" v-if="status == 'active'">
          <div class="col-xl-3 col-lg-12">
            <div class="card">
              <div class="card-body">
                <profile-card
                  :id="info._id"
                  :fullName="info.firstName + ' ' + info.lastName"
                  :classRoom="info.classRoom ? info.classRoom.name : ''"
                  :classRoomId="info.classRoom ? info.classRoom._id : ''"
                  :profileImage="info.photo"
                  :birthDay="info.BirthDate"
                />

                <MultiPaymentValidater
                  class="mt-2"
                  :idParent="info.parent._id"
                  :student="info"
                  @reload="reload"
                  :classroomName="info.classRoom ? info.classRoom.name : ''"
                />
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-12">
            <div class="card">
              <div class="card-body">
                <a-divider class="text-uppercase font-size-13"
                  ><i :class="['fa', 'fa-male']" />
                  {{ info.parent.father.firstName }}
                  {{ info.parent.father.lastName }}</a-divider
                >

                <div class="alert alert-light font-size-11 p-2">
                  <a-icon type="phone" /> {{ info.parent.father.phone }}
                </div>
                <a-divider class="text-uppercase font-size-13"
                  ><i :class="['fa', 'fa-female']" />
                  {{ info.parent.mother.firstName }}
                  {{ info.parent.mother.lastName }}</a-divider
                >
                <div class="alert alert-light font-size-11 p-2">
                  <a-icon type="phone" /> {{ info.parent.mother.phone }}
                </div>

                <div class="text-center">
                  <a-button-group class="d-flex justify-content-center">
                    <a-button :disabled="status != 'active'" @click="link">{{
                      $t("etatPaiement.avance")
                    }}</a-button>
                    <PaymentStatus
                      :currentParent="info.parent._id"
                      :selectedStudent="studentId"
                      :profile="true"
                    />
                  </a-button-group>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-12">
            <control-parentale
              :idParent="info.parent._id"
              :userName="info.parent.userName"
              :pwd="info.parent.pp"
              :status="info.parent.status || 'active'"
              :phones="{
                father: info.parent.father.phone,
                mother: info.parent.mother.phone,
              }"
            />
          </div>
          <div class="col-xl-3 col-lg-12">
            <student-credentials
              :idStudent="info._id"
              :userName="info.userName"
              :pwd="info.ps"
            />
          </div>
        </div>
        <div class="row" v-if="verifyTabsAccess()">
          <div class="card col-xl-12 col-lg-12">
            <div class="card-header card-header-flex flex-column">
              <a-tabs
                class="kit-tabs kit-tabs-bold"
                :default-active-key="activeKey"
                pagination="bottom"
                @change="callback"
              >
                <a-tab-pane
                  v-if="verifyAccess(['all', 'profil_information'])"
                  :tab="$t('profil.informations')"
                  key="1"
                />
                <a-tab-pane
                  v-if="
                    verifyAccess([
                      'all',
                      'paiement_scolarite',
                      'paiement_activite',
                    ])
                  "
                  :tab="$t('paiement.reductionBase')"
                  key="9"
                />
                <a-tab-pane
                  v-if="verifyAccess(['all', 'paiement_scolarite'])"
                  :tab="$t('profil.paiementScol')"
                  key="2"
                />
                <a-tab-pane
                  v-if="verifyAccess(['all', 'paiement_activite'])"
                  :tab="$t('profil.paiementAct')"
                  key="3"
                />
                <a-tab-pane
                  v-if="verifyAccess(['all', 'liste_reglement'])"
                  :tab="$t('profil.listeReglements')"
                  key="4"
                />
                <a-tab-pane
                  v-if="verifyAccess(['all', 'qr_code']) && status == 'active'"
                  key="6"
                  tab="Qr code"
                >
                  <div>
                    <vue-qr
                      :text="qrData"
                      logoSrc="/favicon.png"
                      :callback="getQrImage"
                      qid="student"
                    ></vue-qr>
                    <a-button
                      type="button"
                      class="btn btn-primary"
                      @click="generatePdf"
                      :loading="loadingBadge"
                      :disabled="loadingBadge"
                      style="position: relative; z-index: 99"
                      ><i
                        class="fa fa-address-card-o mr-2"
                        aria-hidden="true"
                      />

                      {{ $t("action.simpleBadge") }}
                    </a-button>
                    <a-button
                      type="button"
                      class="btn btn-primary ml-2"
                      @click="generateNewBadge"
                      :loading="loadingBadge"
                      :disabled="loadingBadge"
                      style="position: relative; z-index: 99"
                      ><i
                        class="fa fa-address-card-o mr-2"
                        aria-hidden="true"
                      />

                      {{ $t("action.badgeAvecPhoto") }}
                    </a-button>
                  </div>
                </a-tab-pane>
                <a-tab-pane
                  v-if="verifyAccess(['all', 'bulletin'])"
                  :tab="$t('bulletin.notes')"
                  key="7"
                />
                <a-tab-pane
                  v-if="verifyAccess(['all', 'assiduite'])"
                  :tab="$t('assiduite.assiduite')"
                  key="8"
                />
              </a-tabs>
            </div>
            <div class="card-body">
              <!--Informations tab 1-->
              <a-collapse
                v-model="activeCollapseKey"
                expand-icon-position="right"
                v-if="activeKey == '1'"
              >
                <a-collapse-panel key="1" :header="$t('profil.historique')">
                  <a-icon slot="extra" type="hourglass" />
                  <div class="row">
                    <div class="col-lg-6 d-inline-block">
                      <label>{{ $t("profil.ajouteLe") }} : </label>
                      <span class="text-muted">
                        {{ moment(info.createdAt).format(" DD/MM/YYYY hh:mm") }}
                      </span>
                    </div>
                    <div class="col-lg-6">
                      <label>{{ $t("profil.lastModifi") }} : </label>
                      <span class="text-muted">{{
                        moment(info.updatedAt).format(" DD/MM/YYYY hh:mm")
                      }}</span>
                    </div>
                  </div>
                  <a-divider></a-divider>
                  <div class="row">
                    <div class="col-lg-6">
                      <p class="mt-2 mb-3">{{ $t("profil.historique") }} :</p>
                      <div class="card" style="height: auto; min-height: 145px">
                        <div class="card-body">
                          <div
                            v-for="year in schoolHistory"
                            :key="year._id"
                            class="mt-1"
                          >
                            {{ year.year }} :
                            <a-tag color="blue"
                              >{{ year.classroomName }}
                            </a-tag>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6" v-if="status != 'active'">
                      <a-alert
                        :message="`${$t('liste.reasonArchive')} :  ${
                          info.reasonArchive || '--'
                        }`"
                        type="warning"
                      />
                    </div>
                  </div>
                </a-collapse-panel>
                <a-collapse-panel key="2" :header="$t('menu.generale')">
                  <a-icon slot="extra" type="setting" />
                  <a-alert
                    v-if="info.needValidation"
                    :message="
                      $t('profil.nameChangeRequest') +
                      ' ' +
                      moment(info.needValidation.date).format('LL')
                    "
                    type="warning"
                    class="mb-3"
                    :class="{ 'rtl-direction': $i18n.locale === 'ar-AR' }"
                    :show-icon="$i18n.locale !== 'ar-AR'"
                  >
                    <div
                      slot="description"
                      class="name-changes-alert"
                      :class="{ 'rtl-direction': $i18n.locale === 'ar-AR' }"
                    >
                      <template v-if="info.needValidation.firstName">
                        <div>
                          <a-icon
                            :type="
                              $i18n.locale === 'ar-AR'
                                ? 'arrow-left'
                                : 'arrow-right'
                            "
                            class="mx-2"
                          />
                          <span>{{ $t("profil.firstNameChangeRequest") }}</span>
                          <strong
                            :class="$i18n.locale === 'ar-AR' ? 'mr-1' : 'ml-1'"
                            >{{ info.firstName }}</strong
                          >
                          <span class="mx-2">{{ $t("profil.to") }}</span>
                          <strong>{{ info.needValidation.firstName }}</strong>
                        </div>
                      </template>
                      <template v-if="info.needValidation.lastName">
                        <div class="mt-1">
                          <a-icon
                            :type="
                              $i18n.locale === 'ar-AR'
                                ? 'arrow-left'
                                : 'arrow-right'
                            "
                            class="mx-2"
                          />
                          <span>{{ $t("profil.lastNameChangeRequest") }}</span>
                          <strong
                            :class="$i18n.locale === 'ar-AR' ? 'mr-1' : 'ml-1'"
                            >{{ info.lastName }}</strong
                          >
                          <span class="mx-2">{{ $t("profil.to") }}</span>
                          <strong>{{ info.needValidation.lastName }}</strong>
                        </div>
                      </template>
                    </div>
                  </a-alert>
                  <a-form
                    :form="form"
                    @submit="updateStudent"
                    v-if="activeKey === '1'"
                  >
                    <div class="row">
                      <div class="col-lg-4">
                        <a-form-item :label="$t('liste.identifiantUnique')">
                          <a-input
                            :placeholder="$t('liste.identifiantUnique')"
                            v-decorator="[
                              'uniqueId',
                              {
                                initialValue: info.uniqueId,
                              },
                            ]"
                          />
                        </a-form-item>
                      </div>
                      <div class="col-lg-4">
                        <a-form-item :label="$t('profil.prenom')">
                          <a-input
                            :placeholder="$t('profil.prenom')"
                            :readOnly="
                              !!(
                                info.needValidation &&
                                info.needValidation.firstName
                              )
                            "
                            v-decorator="[
                              'firstName',
                              {
                                initialValue:
                                  info.needValidation &&
                                  info.needValidation.firstName
                                    ? info.needValidation.firstName
                                    : info.firstName,
                                rules: [
                                  {
                                    required: true,
                                    message: $t('requis.prenom'),
                                  },
                                ],
                              },
                            ]"
                          >
                            <template
                              v-if="
                                info.needValidation &&
                                info.needValidation.firstName
                              "
                              slot="suffix"
                            >
                              <a-tooltip :title="$t('profil.approuver')">
                                <a-icon
                                  type="check"
                                  class="text-success mr-1 clickable-icon"
                                  @click="
                                    requestNameChange('firstName', 'approve')
                                  "
                                />
                              </a-tooltip>
                              <a-tooltip :title="$t('profil.rejeter')">
                                <a-icon
                                  type="close"
                                  class="text-danger clickable-icon"
                                  @click="
                                    requestNameChange('firstName', 'reject')
                                  "
                                />
                              </a-tooltip>
                            </template>
                          </a-input>
                        </a-form-item>
                      </div>
                      <div class="col-lg-4">
                        <a-form-item :label="$t('profil.nom')">
                          <a-input
                            :placeholder="$t('profil.nom')"
                            :readOnly="
                              !!(
                                info.needValidation &&
                                info.needValidation.lastName
                              )
                            "
                            v-decorator="[
                              'lastName',
                              {
                                initialValue:
                                  info.needValidation &&
                                  info.needValidation.lastName
                                    ? info.needValidation.lastName
                                    : info.lastName,
                                rules: [
                                  {
                                    required: true,
                                    message: $t('requis.nom'),
                                  },
                                ],
                              },
                            ]"
                          >
                            <template
                              v-if="
                                info.needValidation &&
                                info.needValidation.lastName
                              "
                              slot="suffix"
                            >
                              <a-tooltip :title="$t('profil.approuver')">
                                <a-icon
                                  type="check"
                                  class="text-success mr-1 clickable-icon"
                                  @click="
                                    requestNameChange('lastName', 'approve')
                                  "
                                />
                              </a-tooltip>
                              <a-tooltip :title="$t('profil.rejeter')">
                                <a-icon
                                  type="close"
                                  class="text-danger clickable-icon"
                                  @click="
                                    requestNameChange('lastName', 'reject')
                                  "
                                />
                              </a-tooltip>
                            </template>
                          </a-input>
                        </a-form-item>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4">
                        <a-form-item :label="$t('profil.dateNaissance')">
                          <a-date-picker
                            style="width: 100%"
                            format="DD/MM/YYYY"
                            v-decorator="[
                              'birthDay',
                              {
                                initialValue: moment(info.BirthDate),
                                rules: [
                                  {
                                    required: true,
                                    message: $t('requis.dateNaissance'),
                                  },
                                ],
                              },
                            ]"
                          />
                        </a-form-item>
                      </div>
                      <div class="col-lg-4">
                        <a-form-item :label="$t('profil.sexe')">
                          <a-radio-group
                            style="width: 100%"
                            button-style="solid"
                            v-decorator="[
                              'gender',
                              {
                                initialValue: info.gender,
                                rules: [
                                  {
                                    required: true,
                                    message: $t('requis.genre'),
                                  },
                                ],
                              },
                            ]"
                          >
                            <a-radio-button value="male" style="width: 50%">
                              {{ $t("profil.garcon") }}</a-radio-button
                            >
                            <a-radio-button value="female" style="width: 50%">
                              {{ $t("profil.fille") }}
                            </a-radio-button>
                          </a-radio-group>
                        </a-form-item>
                      </div>
                      <div class="col-lg-4">
                        <a-form-item :label="$t('inscription.parent')">
                          <a-select
                            show-search
                            :loading="loadingParentChange"
                            option-filter-prop="children"
                            :disabled="status != 'active'"
                            :filter-option="filterOption"
                            :placeholder="$t('sms.listeParents')"
                            @change="handleParentChange"
                            :value="info.parent ? info.parent._id : undefined"
                          >
                            <a-select-option
                              :value="item.id"
                              v-for="item in parentsList"
                              :key="item.id"
                            >
                              {{ item.name }}
                            </a-select-option>
                          </a-select>
                        </a-form-item>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <a-form-item :label="$t('profil.adresse')">
                          <a-input
                            v-decorator="[
                              'adress',
                              {
                                initialValue: info.adress,
                                rules: [
                                  {
                                    required: false,
                                  },
                                ],
                              },
                            ]"
                            :placeholder="$t('profil.adresse')"
                          />
                        </a-form-item>
                      </div>
                      <div class="col-lg-6">
                        <a-form-item :label="$t('profil.tel')">
                          <a-input
                            type="number"
                            v-decorator="[
                              'phone',
                              {
                                initialValue: info.phone,
                                rules: [
                                  {
                                    required: false,
                                  },
                                ],
                              },
                            ]"
                            placeholder="XX XXX XXX"
                          />
                        </a-form-item>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <a-form-item :label="$t('profil.classe')">
                          <a-select
                            show-search
                            option-filter-prop="children"
                            :disabled="
                              changeClassRoomDisable || status != 'active'
                            "
                            :filter-option="filterOption"
                            :placeholder="$t('liste.classes')"
                            @change="showRepReductionModal"
                            v-decorator="[
                              'classRoom',
                              {
                                initialValue: info.classRoom
                                  ? info.classRoom._id
                                  : '',
                              },
                            ]"
                          >
                            <a-select-option
                              :value="item.id"
                              v-for="item in classRoomList.filter(
                                (e) => e.status == 'active'
                              )"
                              :key="item.id"
                            >
                              {{ item.name }}
                            </a-select-option>
                          </a-select>
                        </a-form-item>
                      </div>

                      <div class="col-lg-6">
                        <a-form-item :label="$t('liste.studentPhoto')">
                          <a-upload-dragger
                            v-decorator="[
                              'photo',
                              {
                                rules: [
                                  {
                                    required: false,
                                  },
                                ],
                              },
                            ]"
                            :multiple="false"
                            :before-upload="beforeUpload"
                            :file-list="fileList"
                            :customRequest="addTofileList"
                          >
                            <div>
                              <p class="ant-upload-drag-icon">
                                <a-icon :type="'user'" />
                              </p>
                              <div
                                class="ant-upload-text"
                                style="font-size: 12px"
                              >
                                {{ $t("etablissement.clickOrDragToUpload") }}
                              </div>
                            </div>
                          </a-upload-dragger>
                        </a-form-item>
                      </div>
                    </div>
                    <div class="row mt-3 mb-3">
                      <div class="col-lg-6">
                        <a-form-item :label="$t('liste.addFiles')">
                          <a-upload-dragger
                            multiple
                            :before-upload="beforeUploadStudentFiles"
                            :remove="removeFile"
                            :fileList="studentFiles"
                            :customRequest="addToStudentfileList"
                          >
                            <p class="ant-upload-drag-icon">
                              <a-icon type="inbox" />
                            </p>
                            <p class="ant-upload-text">
                              {{ $t("etablissement.clickOrDragToUpload") }}
                            </p>
                          </a-upload-dragger>
                        </a-form-item>
                      </div>
                      <div class="col-lg-6">
                        <a-form-item :label="$t('liste.studentFiles')">
                          <div class="card p-2" style="min-height: 130px">
                            <div v-if="info.files && info.files.length > 0">
                              <template v-for="file in info.files">
                                <div :key="file" style="width: 100%">
                                  <a-popconfirm
                                    :title="$t('requis.supp')"
                                    @confirm="() => removeStudentFile(file)"
                                  >
                                    <a-button
                                      type="dashed"
                                      shape="circle"
                                      icon="delete"
                                    ></a-button>
                                  </a-popconfirm>
                                  <a-button
                                    :href="settings.base_url + '/' + file"
                                    target="_blank"
                                    style="text-align: left; width: 80%"
                                    class="mb-1 ml-2"
                                    icon="file"
                                  >
                                    {{ getFileName(file) }}
                                  </a-button>
                                </div>
                              </template>
                            </div>
                            <div v-else>
                              <a-empty />
                            </div>
                          </div>
                        </a-form-item>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <a-form-item :label="$t('profil.notes')">
                          <a-input
                            type="textarea"
                            v-decorator="[
                              'notes',
                              {
                                initialValue: info.notes,
                                rules: [
                                  {
                                    required: false,
                                  },
                                ],
                              },
                            ]"
                            :placeholder="$t('profil.notes')"
                          />
                        </a-form-item>
                      </div>

                      <div class="col-lg-12">
                        <a-form-item
                          :label="$t('autorisation.medicalNote')"
                          :help="$t('autorisation.medicalNoteContext')"
                        >
                          <a-input
                            type="textarea"
                            v-decorator="[
                              'medicalNotes',
                              {
                                initialValue: info.medicalNotes,
                                rules: [
                                  {
                                    required: false,
                                  },
                                ],
                              },
                            ]"
                            :placeholder="$t('autorisation.medicalNote')"
                          />
                        </a-form-item>
                      </div>
                    </div>
                    <div class="form-actions mt-9">
                      <a-progress
                        class="mb-4"
                        :stroke-color="{
                          from: '#108ee9',
                          to: '#87d068',
                        }"
                        v-show="uploadPercentage > 0"
                        :percent="uploadPercentage"
                        status="active"
                      />
                      <a-button
                        style="width: 150px"
                        type="primary"
                        htmlType="submit"
                        class="mr-3 mt-2"
                        :disabled="status != 'active'"
                      >
                        {{ $t("action.enregistrer") }}
                      </a-button>
                      <a-popconfirm
                        v-if="
                          verifyAccess(['all', 'annulation_eleve']) &&
                          info.classRoom &&
                          status == 'active'
                        "
                        :title="$t('requis.annu')"
                        @confirm="() => onCancelInscription()"
                      >
                        <a-button type="danger" icon="user-delete">
                          {{ $t("profil.annulerInscri") }}
                        </a-button>
                      </a-popconfirm>
                      <a-button
                        v-else
                        type="danger"
                        icon="user-delete"
                        :disabled="true"
                      >
                        {{ $t("profil.annulerInscri") }}
                      </a-button>
                    </div>
                  </a-form>
                </a-collapse-panel>
              </a-collapse>
              <!--Paiement scolarité tab-->

              <div v-if="activeKey === '2' && info.classRoom">
                <scolartiePayments :studentId="studentId" />
              </div>
              <!--Paiements activités tab-->

              <div v-if="activeKey === '3'">
                <activityPayments :studentId="studentId" />
              </div>
              <!--Liste des réglements tab-->
              <div v-if="activeKey === '4'">
                <div class="row p-0">
                  <div class="col-md-12 p-0">
                    <h4>Mode de présentation</h4>

                    <a-tabs default-active-key="1">
                      <a-tab-pane
                        key="1"
                        tab="Paiements multiples"
                        force-render
                      >
                        <multi-liste-reglement
                          :parentId="info.parent._id"
                          :studentId="studentId"
                        ></multi-liste-reglement>
                      </a-tab-pane>
                      <a-tab-pane key="2" tab="Factures et reçus" force-render>
                        <list-reglement
                          :parentId="info.parent._id"
                          :studentId="studentId"
                        ></list-reglement>
                      </a-tab-pane>
                    </a-tabs>
                  </div>
                </div>
              </div>
              <!--notes tab-->
              <div v-if="activeKey === '7'">
                <div class="row p-0">
                  <div class="col-md-12 p-0">
                    <bulletin
                      :studentId="studentId"
                      :classRoomId="info.classRoom ? info.classRoom._id : ''"
                      :classRoomLevel="
                        info.classRoom ? info.classRoom.level : ''
                      "
                      :studentDetails="{
                        fullName: info.firstName + ' ' + info.lastName,
                        gender: info.gender,
                      }"
                    >
                    </bulletin>
                  </div>
                </div>
              </div>
              <!--assiduité tab-->

              <div v-if="activeKey === '8'">
                <assiduite
                  :studentName="this.info.firstName + ' ' + this.info.lastName"
                  :studentId="studentId"
                  :classRoomId="info.classRoom ? info.classRoom._id : ''"
                ></assiduite>
              </div>
              <!--reduction-de-base tab-->

              <div v-if="activeKey === '9'">
                <reduction-de-base :studentId="studentId"></reduction-de-base>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a-modal
      v-model="visibleRepReductionModal"
      @cancel="closeRepReductionModal"
      :width="700"
      :footer="false"
    >
      <div class="row">
        <div class="col-lg-3">
          <h6 for="">{{ $t("inscription.fraisInscri") + " (dt)" }}</h6>
          <p>{{ selectedClassRoom.inscription }}</p>
        </div>
        <div
          @click="changeModeP('M')"
          :class="[
            'col-lg-4',
            'mr-2',
            'pt-2',
            modePaiement === 'M' ? 'custom-border-active' : 'custom-border',
          ]"
        >
          <h6 for="">{{ $t("inscription.fraisMensuel") + " (dt)" }}</h6>
          <p>{{ selectedClassRoom.monthly }}</p>
        </div>
        <div
          @click="changeModeP('T')"
          :class="[
            'col-lg-4',
            'pt-2',
            modePaiement === 'T' ? 'custom-border-active' : 'custom-border',
          ]"
          v-if="selectedClassRoom.tranches"
        >
          <h6 for="">Frais des tranches (dt)</h6>
          <li v-for="t in selectedClassRoom.tranches" :key="t.trancheNumber">
            {{ "Tranche " + t.trancheNumber + " : " + t.amount }}
          </li>
        </div>
      </div>
      <a-divider />
      <a-alert
        class="mt-2"
        :message="
          'Le mode de paiment selectionné est ' +
          (modePaiement === 'T' ? 'par tranches' : 'mensuelle')
        "
        type="info"
        show-icon
      />

      <div class="form-actions mt-0">
        <a-button
          style="width: 150px"
          type="primary"
          @click="initNewInscription"
          :loading="loadingClassroomChange"
          class="mr-3"
        >
          {{ $t("action.enregistrer") }}
        </a-button>
        <a-button
          key="back"
          @click="closeRepReductionModal"
          :disabled="loadingClassroomChange"
        >
          {{ $t("action.annuler") }}
        </a-button>
      </div>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable */
import moment from "moment";
import apiClient from "@/services/axios";
import ProfileCard from "./components/profileCard/index";
import controlParentale from "./components/controlParental/index";
import studentCredentials from "./components/studentCrendentials/index";
import assiduite from "./components/assiduite/index";

import paymentValidator from "./components/paymentValidator";
import { mapState } from "vuex";
import ListReglement from "./components/listeReglement/listReglement.vue";
import MultiListeReglement from "./components/listeReglement/MultiListeReglement.vue";

import reductionDeBase from "./components/reductionDeBase";
import KitList5 from "@/components/kit/widgets/Lists/5/index";
import bulletin from "./components/bulletin";
import PaymentStatus from "@/components/status/paymentStatus.vue";
const { AwesomeQR } = require("awesome-qr");
import axios from "axios";
import MultiPaymentValidater from "./components/multiPaymentValidater";
import scolartiePayments from "./components/scolartiePayments";
import activityPayments from "./components/activityPayments";

import JsPDF from "jspdf";
import VueQr from "vue-qr";
import { getRangeMonthsSY } from "@/utilities/settings";

const frMonths = {};
const months = moment.months();
for (let i = 0; i < months.length; i++) frMonths[i + 1] = months[i];

function parentFormater(parent) {
  return {
    name:
      parent.father.firstName +
      " " +
      parent.father.lastName +
      " et " +
      parent.mother.firstName +
      " " +
      parent.mother.lastName,
    id: parent._id,
  };
}

const columnsAvance = [
  {
    title: "Date d'ajout",
    dataIndex: "createdAt",
    key: "date",
    scopedSlots: {
      customRender: "date",
    },
  },
  {
    title: "Montant espéce",
    dataIndex: "montantEspece",
    key: "montantEspece",
    sorter: (a, b) => a.montantEspece - b.montantEspece,
  },
  {
    title: "Montant chèque",
    dataIndex: "montantCheque",
    key: "montantCheque",
    sorter: (a, b) => a.montantCheque - b.montantCheque,
  },
  {
    title: "Montant avance",
    dataIndex: "totalAvance",
    key: "totalAvance",
    sorter: (a, b) => a.totalAvance - b.totalAvance,
  },
];

const activityFormater = (payment) => {
  return {
    _id: payment._id,
    name: payment.activity[0].designation,
    once: payment.activity[0].once,
    inscription: payment.inscription,
    months: payment.months,
    repReduction: payment.repReduction,
    activity: payment.activity[0],
    service: payment.service[0],
    typeAS: payment.typeAS,
  };
};

export default {
  computed: {
    ...mapState(["settings", "user"]),
  },
  components: {
    ProfileCard,
    controlParentale,
    studentCredentials,
    paymentValidator,
    ListReglement,
    MultiListeReglement,
    KitList5,
    VueQr,
    bulletin,
    assiduite,
    PaymentStatus,
    reductionDeBase,
    MultiPaymentValidater,
    scolartiePayments,
    activityPayments,
  },
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "studentForm" });
    let qrDataDetail =
      this.studentId +
      "." +
      this.settings.activeBuilding +
      "." +
      this.settings.activeSchoolarYear +
      "." +
      this.settings.base_url;
    this.qrData = btoa(qrDataDetail);
  },
  async created() {
    this.gtagPageView("Informations");

    const navigate = this.$route.query.navigate;
    if (navigate && ["1", "2", "3"].includes(navigate))
      this.activeKey = navigate;
    else this.activeKey = "1";
    await apiClient
      .post("/classrooms/filter", {
        query: {},
        aggregation: [
          {
            $addFields: {
              id: "$_id",
            },
          },
          {
            $project: {
              teachers: 0,
            },
          },
        ],
      })
      .then((res) => {
        this.classRoomList = res.data;
      });
    await apiClient
      .post(`/students/filter`, {
        query: {
          _id: this.studentId,
        },
        aggregation: [
          {
            $set: {
              classRoom: {
                $toObjectId: `$schoolarYearsHistory.${this.settings.activeSchoolarYear}`,
              },
            },
          },
          {
            $lookup: {
              from: "parents",
              localField: "parent",
              foreignField: "_id",
              as: "parent",
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoom",
              foreignField: "_id",
              as: "classRoom",
            },
          },
          {
            $project: {
              _id: 1,
              parent: { $arrayElemAt: ["$parent", 0] },
              classRoom: { $arrayElemAt: ["$classRoom", 0] },
              adress: 1,
              BirthDate: 1,
              firstName: 1,
              lastName: 1,
              gender: 1,
              phone: 1,
              medicalNotes: 1,
              photo: 1,
              ps: 1,
              userName: 1,
              uniqueId: 1,
              schoolarYearsHistory: 1,
              createdAt: 1,
              updatedAt: 1,
              notes: 1,
              files: 1,
              status: 1,
              reasonArchive: 1,
              needValidation: 1,
            },
          },
        ],
      })
      .then((res) => {
        this.info = res.data[0];
        this.status = this.info.status;
        if (!this.info.files) this.$set(this.info, "files", []);
        this.info.parent.pp = this.info.parent.pp
          ? atob(this.info.parent.pp)
          : "";
        this.info.ps = this.info.ps ? atob(this.info.ps) : "";
        const classIds = [];
        this.classRoomList.forEach((e) => classIds.push(e._id));
        //this.info.classRoom = this.info.schoolarYearsHistory[this.settings.activeSchoolarYear]
        if (!this.info.classRoom || !classIds.includes(this.info.classRoom._id))
          this.info.classRoom = null;
        this.leading = false;
      })
      .catch((e) => {
        console.log(e);
      });

    apiClient
      .post("/parents/filter", {
        query: { status: "active" },
        aggregation: {
          $project: {
            _id: 1,
            father: { firstName: 1, lastName: 1 },
            mother: { firstName: 1, lastName: 1 },
            status: 1,
          },
        },
      })
      .then((res) => {
        this.parentsList = res.data.map((item) => parentFormater(item));
      });

    const schoolarYearsHistory = this.info.schoolarYearsHistory;

    const classes = Object.values(schoolarYearsHistory);

    // for histories
    apiClient
      .post("/classrooms/classnames", {
        classrooms: classes,
      })
      .then((res) => {
        const names = res.data;
        for (const schoolarId of Object.keys(schoolarYearsHistory)) {
          let { start, end } = this.settings.schoolarYears.find(
            (el) => el._id == schoolarId
          );

          start = moment(start).format("MM-YYYY");
          end = moment(end).format("MM-YYYY");

          const classroom = names.find(
            (c) => c._id == schoolarYearsHistory[schoolarId]
          );
          this.schoolHistory.push({
            _id: schoolarId,
            classroomName: classroom.name,
            year: start + " / " + end,
          });
        }
      });
  },
  props: {
    studentId: {
      required: true,
    },
  },
  data: function () {
    return {
      loadingClassroomChange: false,
      selectedClassRoom: {},
      visibleRepReductionModal: false,
      loadingBadge: false,
      loadingParentChange: false,
      parentsList: [],
      allDataPayement: [],
      uploadPercentage: 0,
      activeCollapseKey: ["2"],
      status: "",
      schoolHistory: [],
      columnsAvance,
      currentMonthName: moment().format("MMMM"),
      qrData: "",
      visiblePaymentStatus: false,
      loadingPaymentStatus: false,
      loadingPaymentStatusPdf: false,
      paymentStatus: [],
      loadingActPay: true,
      qrDataImage: "",
      tempBulletinFile: null,
      fileList2: [],
      fileList1: [],
      fileList3: [],
      studentFiles: [],
      updateDrawer: false,
      changeClassRoomDisable: false,
      ActivityPaymentModal: {},
      ClassRoomPaymentModal: {},
      frMonths,
      visibleModal: false,
      visibleClassModal: false,
      classRoomPayment: [],
      fileList: [],
      studentFileList: [],
      studentFileListToDelete: [],
      leading: true,
      info: {},
      activeKey: null,
      form: this.$form.createForm(this),
      classRoomList: [],
      studentPhoto: null,
      previewImage: { file: null, visible: false },
      moment,
      data: [],
      columns: [
        {
          title: this.$t("etablissement.designation"),
          dataIndex: "name",
          key: "name",
          scopedSlots: {
            customRender: "name",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          sorter: (a, b) => a.name.length - b.name.length,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("paiement.mois"),
          dataIndex: "months",
          key: "months",
          scopedSlots: {
            customRender: "months",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: this.$t("profil.etat"),
          dataIndex: "paymentStatus",
          key: "paymentStatus",
          scopedSlots: {
            customRender: "paymentStatus",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          scopedSlots: { customRender: "paymentStatus" },

          sorter: (a, b) => a.status.length - b.status.length,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("profil.consulterPaiement"),

          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
        },
      ],
      modePaiement: "M",
    };
  },
  methods: {
    changeModeP(v) {
      this.modePaiement = v;
    },
    gtagPageView(name) {
      this.$gtag.pageview({
        page_path: this.$route.path,
        page_location: window.location.href,
        page_title: document.title + " - " + name,
      });
    },

    link() {
      this.$router.push({
        path: "/addAvance",
        query: { parent: this.info.parent._id },
      });
    },
    verifyAccess: function (accessRoles) {
      const existing = this.user.access;
      if (existing.all) return true;
      if (
        existing.liste_eleves === true ||
        (existing.liste_eleves &&
          existing.liste_eleves.length > 0 &&
          existing.liste_eleves.find((e) => accessRoles.includes(e)))
      )
        return true;
      else return false;
    },
    verifyTabsAccess: function () {
      const existing = this.user.access;
      if (existing.all) return true;
      if (
        existing.liste_eleves === true ||
        (existing.liste_eleves &&
          existing.liste_eleves.length > 0 &&
          existing.liste_eleves.filter(
            (e) =>
              ![
                "liste_eleves",
                "annulation_eleve",
                "suppression_eleve",
              ].includes(e)
          ).length > 0)
      )
        return true;
      else return false;
    },

    generatePdf() {
      this.$gtag.event("Imp eleve badge", {
        event_category: "Impression PDF",
        event_label: "ProfileEleve:qr_code section",
        value: 1,
      });

      this.loadingBadge = true;

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }

      const schoolDetails = this.user.building.filter(
        (el) => el.dbName == this.settings.activeBuilding
      )[0];

      const schoolName = schoolDetails.ArabicName || schoolDetails.name;

      var doc = new JsPDF("l", "mm", [55, 90]);
      doc.setFont("Amiri");
      var today = new Date();
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      doc.setFont("Amiri");
      doc.setFontSize(10);
      let birthDay = new Date(this.info.BirthDate);
      doc.setFont(undefined, "bold");
      doc.text(47, 21, "Nom et prénom: ");
      doc.setFont("Amiri", "normal");
      doc.setFontSize(9);
      doc.text(
        47,
        27,
        this.capitalizeFirstLetter(
          this.info.firstName + " " + this.info.lastName
        )
      );
      doc.setFontSize(10);
      doc.setFont(undefined, "bold");
      doc.text(47, 33, "Date de naissance:");
      doc.setFont("Amiri", "normal");
      doc.text(47, 39, birthDay.toLocaleDateString("fr-FR", options));
      doc.setFontSize(5);
      doc.text(
        52,
        49,
        "Géneré le : " + " " + today.toLocaleDateString("fr-FR", options)
      );

      doc.addImage(this.qrDataImage, "JPEG", 5, 12, 40, 40);
      doc.addImage(imgData, "JPEG", 75, 4, 10, 10);

      doc.setFontSize(13);
      doc.text(schoolName, 46, 10, "center");

      this.loadingBadge = false;

      doc.save(
        "badge_" + this.info.lastName + "_" + this.info.firstName + ".pdf"
      );
    },
    async generateNewBadge() {
      this.$gtag.event("Imp eleve badge with photo", {
        event_category: "Impression PDF",
        event_label: "ProfileEleve:qr_code section",
        value: 1,
      });

      this.loadingBadge = true;

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }

      const schoolDetails = this.user.building.filter(
        (el) => el.dbName == this.settings.activeBuilding
      )[0];

      const schoolName = schoolDetails.ArabicName || schoolDetails.name;

      const doc = new JsPDF("p", "mm", "a6");

      const backgroundColor = "#81b6f7";
      const lineColor = "#61a6fa";
      const photoColor = "#ffffff";

      const page_width = doc.internal.pageSize.getWidth();
      const page_height = doc.internal.pageSize.getHeight();
      const ctx = doc.context2d;

      doc.setFillColor(backgroundColor);
      doc.setDrawColor(lineColor);

      doc.rect(0, 50, page_width, page_height, "FD");

      doc.setFillColor(photoColor);
      doc.setDrawColor(lineColor);
      doc.setLineWidth(0.6);
      doc.circle(page_width / 2, 50, 20, "FD");

      // draw student photo
      // -------------------------------------------------------------
      const studentPhoto = new Image();
      if (this.info.photo)
        studentPhoto.src = this.settings.base_url + "/" + this.info.photo;
      else studentPhoto.src = "/resources/images/blank.png";

      let imageSize = 39; // Adjust this to the desired image size
      let centerX = page_width / 2; // Adjust this to the desired X coordinate
      let centerY = 50; // Adjust this to the desired Y coordinate

      // Create a circular clipping path using context2d
      ctx.save();
      ctx.beginPath();
      ctx.arc(centerX, centerY, imageSize / 2, 0, Math.PI * 2);
      ctx.clip();

      // Draw the original image within the circular clipping path
      ctx.drawImage(
        studentPhoto,
        centerX - imageSize / 2,
        centerY - imageSize / 2,
        imageSize,
        imageSize
      );

      // Reset the clipping path
      ctx.restore();
      ctx.closePath();
      // -------------------------------------------------------------

      doc.setFont("Amiri", "Bold");
      doc.setFontSize(22);
      doc.setTextColor("#ffffff");
      doc.text(
        page_width / 2,
        78,
        this.capitalizeFirstLetter(
          this.info.firstName + " " + this.info.lastName
        ),
        { align: "center" }
      );
      doc.setFont("Amiri", "normal");
      doc.setFontSize(15);
      doc.text(
        page_width / 2,
        88,
        this.info.classRoom ? this.info.classRoom.name : "NON INSCRIT",
        {
          align: "center",
        }
      );

      let logo = "data:image/jpeg;base64, ";

      await axios
        .get("favicon.png", {
          responseType: "arraybuffer",
        })
        .then((response) => {
          logo += Buffer.from(response.data, "binary").toString("base64");
        });

      await new AwesomeQR({
        text: this.qrData,
        logoImage: logo,
        size: 200,
        correctLevel: 1,
        logoScale: 0.2,
        logoMargin: 0,
        logoCornerRadius: 8,
        whiteMargin: false,
        dotScale: 1,
        autoColor: true,
        binarize: false,
        binarizeThreshold: 128,
        bindElement: true,
        backgroundColor: "#FFFFFF",
        margin: 3,
      })
        .draw()
        .then((dataURL) => {
          doc.addImage(
            dataURL,
            "PNG",
            page_width / 2 - 17.5,
            page_height / 1.5,
            35,
            35
          );
        });

      // logo and school name
      // -------------------------------------------------------------
      imageSize = 15;
      const text = schoolName;
      centerX = page_width / 2;
      centerY = 15;
      const padding = 5;
      doc.setFontSize(13);

      const totalWidth = imageSize + padding + doc.getTextWidth(text);
      const startX = centerX - totalWidth / 2;

      doc.addImage(
        imgData,
        "JPEG",
        startX,
        centerY - imageSize / 2,
        imageSize,
        imageSize
      );

      doc.setTextColor("#000000");

      doc.text(startX + imageSize + padding, centerY, text);
      // -------------------------------------------------------------

      this.loadingBadge = false;

      doc.save(
        "badge_" + this.info.lastName + "_" + this.info.firstName + ".pdf"
      );
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getQrImage(dataUrl) {
      this.qrDataImage = dataUrl;
    },

    reload(data) {
      this.$emit("reload");
    },
    onCancelInscription() {
      apiClient
        .post(`/payment/scolarite/cancel/${this.studentId}`)
        .then((res) => {
          this.$message.success(this.$t("success.InscriptionAnnulee"));
          this.$emit("reload");
        })
        .catch((e) => {
          console.log(e);
          this.$message.warning(e.response.data.error.message);
        });
    },
    showRepReductionModal(changeClassRoom) {
      this.selectedClassRoom =
        this.classRoomList.find((c) => c._id == changeClassRoom) || {};

      const oldClassRoom = this.info.classRoom;
      this.changeClassRoomDisable = true;

      if (oldClassRoom == undefined) {
        this.visibleRepReductionModal = true;
      } else this.changeClassRoom();
    },
    closeRepReductionModal() {
      this.visibleRepReductionModal = false;
      this.loadingClassroomChange = false;
      this.changeClassRoomDisable = false;

      this.form.setFieldsValue({
        classRoom: this.info.classRoom ? this.info.classRoom._id : "",
      });
    },
    initNewInscription() {
      const selectedClassroomId = this.selectedClassRoom._id;

      this.loadingClassroomChange = true;
      let newSchoolarYearHistory = this.info.schoolarYearsHistory
        ? this.info.schoolarYearsHistory
        : {};

      newSchoolarYearHistory[this.settings.activeSchoolarYear] =
        selectedClassroomId;
      const newClassRoom = this.classRoomList.filter(
        (c) => c.id == selectedClassroomId
      )[0];

      const student = this.info;
      const activeSchoolarYear = this.settings.activeSchoolarYear;
      const schoolarYears = this.settings.schoolarYears;
      let newInscription = {};
      schoolarYears.forEach((elem) => {
        if (elem._id == activeSchoolarYear) {
          newInscription.end = elem.end;
          newInscription.start = elem.start;
        }
      });
      newInscription.student = student._id;
      newInscription.classroom = selectedClassroomId;
      newInscription.typeAS = "S";
      newInscription.mode = this.modePaiement;
      let repReduction = {};

      const range = getRangeMonthsSY(activeSchoolarYear, schoolarYears);
      if (newInscription.mode == "M")
        for (const key of range) repReduction[key] = 0;
      else if (this.selectedClassRoom.tranches)
        for (const e of this.selectedClassRoom.tranches)
          repReduction[e.trancheNumber] = 0;

      if (newClassRoom.inscription) repReduction["inscription"] = 0;

      newInscription.repReduction = repReduction;

      apiClient
        .put("/payment", newInscription)
        .then(async () => {
          await apiClient.patch("/students/" + student._id, {
            data: {
              classRoom: selectedClassroomId,
              schoolarYearsHistory: newSchoolarYearHistory,
            },
          });
          this.info.clasclassRoomsRoom = {
            ...newClassRoom,
            id: newClassRoom._id,
            _id: newClassRoom._id,
          };
          this.$message.success(this.$t("success.eleveAjoutAuClasse"));
          this.$emit("reload");
        })
        .catch((e) => {
          console.error(e);
          this.$message.error(this.$t("error.eleveNonAjoutAuClasse"));
        })
        .finally(() => {
          this.changeClassRoomDisable = false;
          this.loadingClassroomChange = false;

          this.afterChangeClassRoom();
        });
    },
    async changeClassRoom() {
      const selectedClassroomId = this.selectedClassRoom._id;
      const student = this.info;
      const oldClassRoom = student.classRoom;

      await apiClient
        .patch("/payment/changeclass/" + student._id, {
          oldClassroomId: oldClassRoom,
          newClassroomId: selectedClassroomId,
        })
        .then((res) => {
          const newClassRoom = this.classRoomList.filter(
            (c) => c.id == selectedClassroomId
          )[0];
          this.$message.success(
            this.$t("success.eleveDemeangeAuClasse", {
              name: newClassRoom.name,
            })
          );

          this.$emit("reload");
        })
        .catch((error) => {
          console.log(error);
          let errorMessage = "Une erreur inattendue s'est produite.";

          if (error.response && error.response.status === 409) {
            errorMessage = error.response.data.message;
          }
          this.$message.error(errorMessage);
        })
        .finally(() => {
          this.loadingClassroomChange = false;
          this.closeRepReductionModal();
          this.afterChangeClassRoom();
        });
      //////
    },
    afterChangeClassRoom() {
      apiClient
        .post("/payment/filter", {
          query: {
            student: this.studentId,
            schoolarYear: this.settings.activeSchoolarYear,
            typeAS: "S",
          },
          aggregation: [{ $sort: { updatedAt: 1 } }],
        })
        .then((res) => {
          if (res.data.length > 1) {
            const to_delete = res.data[0];
            apiClient.delete(`/payment/${to_delete._id}`);
            this.$emit("reload");
          }
        });
    },

    reverseStatModal() {
      this.visibleModal = !this.visibleModal;
    },
    addTofileList() {
      this.fileList = [this.studentPhoto];
    },
    addToStudentfileList(options) {
      const { onSuccess, onError, file, onProgress } = options;
      this.studentFiles.push(file);
      setTimeout(() => {
        onSuccess(null, file);
      }, 200);
    },
    beforeUploadStudentFiles(file) {
      this.studentFileList.push(file);
    },
    removeFile(file) {
      this.studentFileList = this.studentFileList.filter(
        (f) => f.uid != file.uid
      );
      this.studentFiles = this.studentFiles.filter((f) => f.uid != file.uid);
    },
    getFileName(path) {
      return path.match(/\d+\-\-(.*?)$/)[1];
    },
    removeStudentFile(file) {
      this.info.files = this.info.files.filter(
        (f) => String(f) != String(file)
      );
      this.studentFileListToDelete.push(file);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    callback: function (key) {
      this.activeKey = key;
      if (key == 1) this.gtagPageView("Informations");
      else if (key == 2) this.gtagPageView("Paiement scolarité");
      else if (key == 3) this.gtagPageView("Paiements activités");
      else if (key == 6) this.gtagPageView("Qr code");
      // the others is in the components
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error(this.$t("error.fichiers"));
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error(this.$t("error.image"));
      }
      if (isJpgOrPng && isLt2M) {
        this.$message.success(
          this.$t("success.imageTelecharge", { name: file.name })
        );

        this.studentPhoto = file;
      }
      return isJpgOrPng && isLt2M;
    },
    updateStudent(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          let data = {
            ...values,
            BirthDate: moment(values.birthDay).valueOf(),
          };
          delete data.birthDay;
          delete data.photo;
          this.info.BirthDate = data.BirthDate;
          apiClient
            .patch("/students/" + this.info._id, { data: data })
            .then((res) => {
              this.info = {
                ...this.info,
                adress: values.adress,
                firstName: values.firstName,
                lastName: values.lastName,
                medicalNotes: values.medicalNotes,
                gender: values.gender,
                notes: values.notes,
                phone: values.phone,
              };
              this.$message.success(this.$t("success.eleveMSA"));
            })
            .catch(() => this.$message.error(this.$t("error.impoModifiEleve")));
          this.uploadPercentage = 0;
          if (this.studentPhoto) {
            let formData = new FormData();
            formData.append("photo", this.studentPhoto);
            await apiClient
              .patch("/students/" + this.info._id + "/photo", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                  this.uploadPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                },
              })
              .then((res) => (this.info.photo = res.data.imageURL))
              .catch(() =>
                this.$message.error(this.$t("error.impoTelecharger"))
              )
              .finally(() => {
                this.uploadPercentage = 0;
              });
          }

          if (this.studentFileList.length > 0) {
            const formData = new FormData();
            for (const file of this.studentFileList) {
              formData.append("files", file);
            }
            await apiClient
              .put("/students/" + this.info._id + "/files", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                  this.uploadPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                },
              })
              .then((res) => {
                this.studentFiles = [];
                this.studentFileList = [];
                if (!this.info.files)
                  this.info.files = res.data.map((f) => f.imageURL);
                else this.info.files.push(...res.data.map((f) => f.imageURL));
              })
              .catch((e) => {
                this.$message.error(this.$t("error.invalidFormat"));
              })
              .finally(() => {
                this.uploadPercentage = 0;
                this.$message.success(this.$t("success.eleveMSA"));
              });
          }

          if (this.studentFileListToDelete.length > 0) {
            apiClient
              .post("/students/" + this.info._id + "/deleteFiles", {
                files: this.studentFileListToDelete,
              })
              .then((res) => {
                this.studentFileListToDelete = [];
              })
              .catch((e) => {
                console.error(e);

                this.$message.error(this.$t("error.erreur"));
              });
          }
        }
      });
    },
    calculateAge: function (date) {
      const birthday = new Date(date);
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    handleParentChange(value) {
      this.loadingParentChange = true;
      apiClient
        .post(`/students/${this.info._id}/changeParent`, {
          oldParent: this.info.parent._id,
          newParent: value,
        })
        .then((res) => {
          this.info.parent._id = value;
          this.$message.success(this.$t("success.eleveMSA"));
          this.loadingParentChange = false;
          this.$emit("reload");
        })
        .catch((e) => {
          console.error(e);

          this.$message.error(this.$t("error.erreur"));
        });
    },
    requestNameChange(field, action) {
      apiClient
        .patch(`/lms/student/${this.info._id}/validateName`, {
          field,
          action,
        })
        .then((res) => {
          if (res.data) {
            if (action === "approve") {
              this.info[field] = this.info.needValidation[field];
            }
            if (this.info.needValidation) {
              this.$set(this.info.needValidation, field, false);
              const noNameValidationsLeft =
                !this.info.needValidation.firstName &&
                !this.info.needValidation.lastName;
              if (noNameValidationsLeft) {
                this.$set(this.info, "needValidation", null);
              }
            }
            this.$message.success(
              action === "approve"
                ? this.$t("profil.approuver")
                : this.$t("profil.rejeter")
            );
          }
        })
        .catch((e) => {
          console.error(e);
          this.$message.error(this.$t("error.erreur"));
        });
    },
  },
};
</script>
<style scoped>
.card {
  height: 90%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.custom-tag .ant-tag {
  padding: 4px 7px 4px 7px;
  font-size: 13px;
  margin-left: 3px;
}

.custom-tag .ant-tag:hover {
  cursor: pointer;
}
.custom-border {
  border: 1px solid #6c757d;
  border-radius: 0.25rem;
  cursor: pointer;
}
.custom-border-active {
  border: 3px solid #22bb33;
  border-radius: 0.25rem;
  cursor: pointer;
}
.name-changes-alert {
  padding: 8px 0;
}
.clickable-icon {
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  transition: background-color 0.2s;
}
.clickable-icon:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.rtl-direction {
  direction: rtl;
  text-align: right;
}
</style>
